import {
  POST_BANNER_ALL,
  POST_BANNER_ADD,
  POST_BANNER_EDIT,
  POST_BANNER_EDIT_ORDER,
  POST_BANNER_DELETE_IMAGE,
  POST_BANNER_SINGLE
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postBannersAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`banner/all`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_BANNER_ALL, payload: response.data});
};

    
export const postBannersAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`banner/add`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_BANNER_ADD, payload: response.data});
};

export const postBannersEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`banner/edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_BANNER_EDIT, payload: response.data});
};


export const postBannerChangeImagesOrder = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`banner/change-image-order`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_BANNER_EDIT_ORDER, payload: response.data});
};


export const postBannerImagesDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`banner/delete-image`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_BANNER_DELETE_IMAGE, payload: response.data});
};

export const postBannerSingle = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`banner/single`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_BANNER_SINGLE, payload: response.data});
};