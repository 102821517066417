import './sources.css';
import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { POST_SOURCES_ADD, POST_SOURCES_DELETE, POST_SOURCES_EDIT, POST_SOURCES_REMOVE_ICON, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import { postSourceRemoveIcon, postSourcesAdd, postSourcesEdit } from '../../redux/sources/action';
import slugify from  'slugify';
import { SLUG_OPTIONS,PRIMARY_COLOR } from '../../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import SweetAlert from 'sweetalert2';

const AddSource =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const {editItem} = props;
    const addSourceData = useSelector(({sources}) => sources.addSourceData);
    const editSourceData = useSelector(({sources}) => sources.editSourceData);
    const removeIconSourceData = useSelector(({sources}) => sources.removeIconSourceData);
    const uploadImageData = useSelector(({upload}) => upload.uploadImageData);
    
    //HOOKS VARIABLES
    const [name,setName] = useState("");
    const [web_url,setWebUrl] = useState("");
    const [visible,setVisible] = useState(true);
    const [currentImage, setCurrentImage] = useState({url: "", file: null});
    const [slug,setSlug] = useState("");
    const [twitterId,setTwitterId] = useState("");
    const [instagramId,setInstagramId] = useState("");

    //VARIABLES
    const trans = settingsData.trans;
    const fileInputRef=useRef();
    const dispatch = useDispatch();

    const [addItemId,setAddItemId] = useState("");
 
    //HOOKS
    useEffect(() => { //ADD DATA
      if (addSourceData != null){
        if (addSourceData.status) {
          toast.dismiss();
          setAddItemId(addSourceData.id);
          if (currentImage.file != null){ 
            uploadImageFile();
          } else {
            clearData();
            props.onSubmitData();
          }
          
        } else {
          toast.dismiss();
          toast.error(addSourceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              props.onErrorData();
        }
        dispatch({type: POST_SOURCES_ADD, payload: null});
      }
    }, [addSourceData]);
  
    useEffect(() => { //EDIT DATA
      if (editSourceData != null){
        if (editSourceData.status) {
          toast.dismiss();
          toast.success(editSourceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
           
            if (editItem != null){
              clearData();
              props.onEditData();
            } else {
              clearData();
              props.onSubmitData();
            }
            
        } else {
          toast.dismiss();
          toast.error(editSourceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              props.onErrorData();
        }
        dispatch({type: POST_SOURCES_EDIT, payload: null});
      }
    }, [editSourceData]);
  
   

    useEffect(() => { //DELETE DATA
      if (uploadImageData != null){
        if (uploadImageData.status) {
          toast.dismiss();
          toast.success(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              let postData = {name: name, web_url: web_url,icon_url: uploadImageData.urlPath,itemId: addItemId,visible,slug: slug,twitterId,instagramId}
          dispatch(postSourcesEdit(postData));
        } else {
          toast.dismiss();
          toast.error(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              props.onErrorData();
        }
        dispatch({type: POST_UPLOAD_IMAGE, payload: null});
      }
    }, [uploadImageData]);

    useEffect(() => { //ADD DATA
      if (removeIconSourceData != null){
        if (removeIconSourceData.status) {
          let postData = {name,web_url,visible,slug:slug,twitterId,instagramId};
          postData.itemId = editItem.id;
          postData.icon_url = "";
          dispatch(postSourcesEdit(postData));
        } else {
          toast.dismiss();
          toast.error(addSourceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              props.onErrorData();
        }
        dispatch({type: POST_SOURCES_REMOVE_ICON, payload: null});
      }
    }, [removeIconSourceData]);

    useEffect(() => {
      if (editItem != null){
          setName(editItem.name);
          setSlug(editItem.singleSlug);
          setVisible(editItem.visible);
          setWebUrl(editItem.web_url);
          setCurrentImage({file: null,url: editItem.icon_url});
          setTwitterId(editItem.twitterId);
          setInstagramId(editItem.instagramId);
      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
        if (props.toGetData){
            let postData = {name,web_url,visible,slug:slug,twitterId,instagramId};
            if (editItem != null){
              postData.itemId = editItem.id;
              postData.icon_url = editItem.icon_path;
              
              if (currentImage.file != null){
                setAddItemId(editItem.id);
                uploadImageFile();
              } else {
                if (currentImage.url == "") {
                  dispatch(postSourceRemoveIcon(postData));
                } else {
                  dispatch(postSourcesEdit(postData));
                }
                
              }
          
            } else {
              dispatch(postSourcesAdd(postData));
            }
        }
      }, [props.toGetData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    const uploadImageFile = () => {
      if (currentImage.file != null){
        var formData = new FormData();
        formData.append("file", currentImage.file);
        formData.append('name', name);
        formData.append('folder', "icons");
        if (editItem != null){
          formData.append('oldPath', editItem.icon_path);
        } else {
          formData.append('oldPath', null);
        }
     

        dispatch(postUploadImage(formData))
      }
    }

    //FUNCTIONS
    const clearData = () => {
        setName("");
        setVisible(true);
        setWebUrl("");
        setCurrentImage({url:"",file:null})
        setSlug("");
        setTwitterId("");
        setInstagramId("");
    }

    const updateIconImageFile = (event) => {      
      if (event.target.files.length != 0 ){
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
        let file = event.target.files[0];
  
        let reader = new FileReader();
        reader.onloadend = () => {
          setCurrentImage({url:reader.result, file: file});
        }
        reader.readAsDataURL(file);
      }
    }

    const onNameChange = (e) => {
      const newNameValue = e.target.value;
      setName(newNameValue);
      const newSlug = slugify(newNameValue,SLUG_OPTIONS)
      setSlug(newSlug);
    }

    const removeSourceIconAsk = (e) =>  {
      e.preventDefault();
      SweetAlert.fire({
        customClass: {
          container: 'my-swal'
        },
        theme: "dark",
        title:trans.remove_icon,
        text:trans.remove_icon_desc,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.btn_delete,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            //REMOVE
            setCurrentImage({url:"",file:null});
        }
      })
    }

  //UI
  return (
    <div>
      <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="name"
              label={trans.name_field}
              value={name}
              type="text"
              onChange={(e)=> {onNameChange(e)}}
              fullWidth
              variant="standard" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="name"
              label={trans.web_site_field}
              value={web_url}
              type="text"
              onChange={(e)=> {setWebUrl(e.target.value)}}
              fullWidth
              variant="standard" />
          </Grid>

          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="slug"
              label={trans.slug_field}
              value={slug}
              type="text"
              onChange={(e)=> {setSlug(e.target.value)}}
              fullWidth
              variant="standard" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="twitterId"
              label={trans.twitter_field}
              value={twitterId}
              type="text"
              onChange={(e)=> {setTwitterId(e.target.value)}}
              fullWidth
              variant="standard" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="instagramId"
              label={trans.instagram_field}
              value={instagramId}
              type="text"
              onChange={(e)=> {setInstagramId(e.target.value)}}
              fullWidth
              variant="standard" />
          </Grid>
         
      </Grid>
      <br/>
      <Grid container spacing={1}>
        <Grid item xs={10} >
            <span>{trans.upload_source_icon_field}</span>
            <div style={{marginTop:'5px'}}>
            <Button color="success" variant="outlined" onClick={()=>fileInputRef.current.click()} >{trans.choose_file}</Button>
            <input ref={fileInputRef} id="fileupload" type="file" name="file" onChange={(e) => updateIconImageFile(e)}  multiple style={{display:'none'}}/>
            </div>
            
        </Grid>
       
        <Grid item xs={2} >
            <img style={{width:'50px'}} src={currentImage.url}></img>
        </Grid>
      </Grid>       
      {editItem != null ?
      <Grid item xs={12} >
        <br/>
        <Button color="error" startIcon={<DeleteIcon />} variant="outlined" onClick={(e)=>removeSourceIconAsk(e)} >{trans.remove_icon}</Button>
            
        </Grid>
         : null}
      <br/>
      <br/>
      <Grid container spacing={1}>
        <Grid item xs={12} >
           <FormControl fullWidth>
              <InputLabel id="visible_state">{trans.visible_field}</InputLabel>
              <Select
                      labelId="visible_state"
                      id="visible"
                      value={visible}
                      label={trans.visible_field}
                      onChange={(e) => setVisible(e.target.value)}>
                      <MenuItem value={true}>{trans.table_head_visible}</MenuItem>
                      <MenuItem value={false}>{trans.table_head_hidden}</MenuItem>
              </Select>
           </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddSource;
