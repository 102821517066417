import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS, editorConfig } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getLocalDate, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannersEdit } from '../../redux/banners/action';
import { POST_BANNER_EDIT, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import JoditEditor from "jodit-react";
import { postUsersAll } from '../../redux/users/action';
import { postInsuranceCompaniesAll } from '../../redux/insuranceCompany/action';
import { postInsuranceTypesAll } from '../../redux/insuranceType/action';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditInsuranceContractItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const uploadImageData = useSelector(({upload}) => upload.uploadImageData);
    const usersData = useSelector(({users}) => users.usersData);
    const insuranceCompaniesData = useSelector(({insuranceCompanies}) => insuranceCompanies.insuranceCompaniesData);
    const insuranceTypesData = useSelector(({insuranceTypes}) => insuranceTypes.insuranceTypesData);

 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [contractNum, setContractNum] = useState("");
    const [userId, setUserId] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [durationType, setDurationType] = useState("");
    const [insuranceType, setInsuranceType] = useState("");
    const [insuranceTypeCategory, setInsuranceTypeCategory] = useState("");
    const [insuranceCompany, setInsuranceCompany] = useState("");
    const [insuranceValue, setInsuranceValue] = useState("");
    const [insuranceDesc, setInsuranceDesc] = useState("");
    
    const [insuranceCovers, setInsuranceCovers] = useState("");
    const [cost, setCost] = useState(0);
    const [paidCost, setPaidCost] = useState(0);
    const [usersArr,setUsersArr] = useState([]);
    const [usersFilter, setUsersFilter] = useState(null);
    const [searchUser,setSearchUser] = useState(null);
    const [remainingCost, setRemainingCost] = useState(0);
    const [paidUntilDate, setPaidUntilDate] = useState(null);
    const [paymentWay, setPaymentWay] = useState("");
    const [receiptNum, setReceiptNum] = useState("");
    const [status, setStatus] = useState(true);
    const [companiesArr,setCompaniesArr] = useState([]);
    const [insuranceTypesArr,setInsuranceTypesArr] = useState([]);
    const [insuranceTypeCategoriesArr,setInsuranceTypeCategoriesArr] = useState([]);

    const [accidentCarePhone, setAccidentCarePhone] = useState("");
    const [roadsideAssistancePhone, setRoadsideAssistancePhone] = useState("");
    const [serviceLinePhone, setServiceLinePhone] = useState("");
    const [emergencyPhone, setEmergencyPhone] = useState("");

    //REFS
    const fileInputRef=useRef();
    const editorDescEl = useRef(null);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;

    useEffect(() => { //ONLOAD
      getUserData();
      getCompanyData();
      getInsuranceTypesData();
    }, []);
  
    
    const getUserData = () => {
      let postData = {
        hasPaginate: false,
        searchKeyword: searchUser,
      };
  
      dispatch(postUsersAll(postData)); 
    }

    const getCompanyData = () => {
      let postData = {
        fromApp: false
      };
  
      dispatch(postInsuranceCompaniesAll(postData)); 
    }

    const getInsuranceTypesData = () => {
      let postData = {
        fromApp: true
      };
  
      dispatch(postInsuranceTypesAll(postData)); 
    }
  

    useEffect(() => {
      if (usersData != null){
         setUsersArr(usersData.users);
      } 
    },[usersData]);
  
 

    useEffect(() => { 
      if (insuranceCompaniesData != null){
        setCompaniesArr(insuranceCompaniesData.insurance_companies);
      }
    }, [insuranceCompaniesData]);

    useEffect(() => { //GOT DATA
      if (insuranceTypesData != null){
        setInsuranceTypesArr(insuranceTypesData.insurance_types);
      }
    }, [insuranceTypesData]);

    
    useEffect(() => { //GOT DATA
      if (editItem != null &&
         editItem.insuranceType != null && insuranceTypesArr != null && insuranceTypesArr.length > 0 && insuranceTypesData != null && insuranceTypeCategoriesArr.length == 0){
        setInsuranceType(editItem.insuranceType.id);
        hangleInsuranceTypeChange(editItem.insuranceType.id,editItem.insuranceTypeCategory.id)
      }
    }, [insuranceTypesArr]);


    //HOOKS
    useEffect(() => {
      console.log(props.toGetData);
      if (props.toGetData){

          if (contractNum == "" || insuranceCompany == "" || insuranceType == "" || startDate == null || endDate == null || userId == ""){
            props.onErrorData();
            return;
          }
        
          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

          props.onSubmitData(postData);
          
      }
    }, [props.toGetData]);

    const makePostItem = () => {

      const postData = {
        "contractNum": contractNum,
        "userId": userId,
        "startDate": startDate,
        "endDate": endDate,
        "durationType": durationType,
        "insuranceType": insuranceType,
        "insuranceTypeCategory": insuranceTypeCategory,
        "insuranceCompany": insuranceCompany,
        "insuranceValue": insuranceValue,
        "insuranceDesc": insuranceDesc,
        "insuranceCovers": insuranceCovers,
        "cost": cost,
        "paidCost": paidCost,
        "remainingCost": remainingCost,
        "paidUntilDate": paidUntilDate,
        "paymentWay": paymentWay,
        "receiptNum": receiptNum,
        accidentCarePhone,
        roadsideAssistancePhone,
        serviceLinePhone,
        emergencyPhone,
        "status": status
      };
    
      return postData;
    }


    useEffect(() => {
      if (editItem != null){

        setContractNum(editItem.contractNum);
        setUserId(editItem.userId.id);
        
        if (usersArr.length == 0){
          let newUserData = [];
          newUserData.push(editItem.userId);
          setUsersArr(newUserData);
          setUsersFilter(editItem.userId);
        }

        setStartDate(editItem.startDate);
        setEndDate(editItem.endDate);
        setDurationType(editItem.durationType);

        if (editItem.insuranceType != null){
          setInsuranceType(editItem.insuranceType.id);
          hangleInsuranceTypeChange(editItem.insuranceType.id,editItem.insuranceTypeCategory.id)
        }
       
        setInsuranceCompany(editItem.insuranceCompany.id);
        setInsuranceValue(editItem.insuranceValue);
        setInsuranceDesc(editItem.insuranceDesc);
        setInsuranceCovers(editItem.insuranceCovers);
        setCost(editItem.cost);
        setPaidCost(editItem.paidCost);
        setRemainingCost(editItem.remainingCost);
        setPaidUntilDate(editItem.paidUntilDate);
        setPaymentWay(editItem.paymentWay);
        setReceiptNum(editItem.receiptNum);
        setStatus(editItem.status);

        setAccidentCarePhone(editItem.accidentCarePhone);
        setRoadsideAssistancePhone(editItem.roadsideAssistancePhone);
        setServiceLinePhone(editItem.serviceLinePhone);
        setEmergencyPhone(editItem.emergencyPhone);
  
      } else {
        clearData();
      }
    },[editItem]);


    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    //FUNCTIONS
    const clearData = () => {
      setUserId("");
      setStartDate(null);
      setEndDate(null);
      setDurationType("");
      setInsuranceType("");
      setInsuranceTypeCategory("");
      setInsuranceCompany("");
      setInsuranceValue("");
      setInsuranceDesc("");
      setInsuranceCovers("");
      setCost(0);
      setPaidCost(0);
      setRemainingCost(0);
      setPaidUntilDate(null);
      setPaymentWay("");
      setReceiptNum("");
      setStatus(false);
      setAccidentCarePhone("");
      setRoadsideAssistancePhone("");
      setServiceLinePhone("");
      setEmergencyPhone("");
    }

    const handleUsersChange = (e,selectedValues) => {
      setUsersFilter(selectedValues);
      setUserId(selectedValues != null ? selectedValues.id : "")
      setSearchUser("");
    }
  
    const handleSearchInput = (value) => {
      setSearchUser(value);
    }
  
  
    useEffect(() => {
      if (searchUser != null){
        getUserData();
      } 
    },[searchUser]);
  


    const hangleInsuranceTypeChange = (value,category) => {
      let id = value;
      var newInsuranceCategoriesArr = [];
      insuranceTypesArr.forEach(insuranceType => {
        if (insuranceType.id == value){
          newInsuranceCategoriesArr = insuranceType.categories;
        }
      });
      setInsuranceType(id);
      setInsuranceTypeCategory(category);
      setInsuranceTypeCategoriesArr(newInsuranceCategoriesArr);
    }

    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

      
        <Grid item xs={12}>
          <h4 className='_split_title'>Συμβόλαιο</h4>
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="dense"
            id="contractNum"
            label={trans.table_head_insurance_contract_num}
            value={contractNum}
            type="text"
            onChange={(e) => setContractNum(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_company">{trans.table_head_insurance_company}</InputLabel>
                  <Select
                          labelId="table_head_insurance_company"
                          id="table_head_insurance_company"
                          value={insuranceCompany}
                          label={trans.insurance_contract_state}
                          onChange={(e) => setInsuranceCompany(e.target.value)}>
                           {companiesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            margin="dense"
            id="durationType"
            label={trans.table_head_insurance_duration_type}
            value={durationType}
            type="text"
            onChange={(e) => setDurationType(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            margin="dense"
            id="insuranceValue"
            label={trans.table_head_insurance_insurance_value}
            value={insuranceValue}
            type="text"
            onChange={(e) => setInsuranceValue(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="insuranceValue"
            label={trans.table_head_insurance_insurance_desc}
            value={insuranceDesc}
            type="text"
            onChange={(e) => setInsuranceDesc(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_type">{trans.table_head_insurance_type}</InputLabel>
                  <Select
                          labelId="table_head_insurance_type"
                          id="table_head_insurance_type"
                          value={insuranceType}
                          label={trans.table_head_insurance_type}
                          onChange={(e) => hangleInsuranceTypeChange(e.target.value,null)}>
                           {insuranceTypesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>

        <Grid item xs={6}>
            <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_category">{trans.table_head_insurance_category}</InputLabel>
                  <Select
                          labelId="table_head_insurance_category"
                          id="table_head_insurance_category"
                          value={insuranceTypeCategory}
                          label={trans.table_head_insurance_category}
                          onChange={(e) => setInsuranceTypeCategory(e.target.value)}>
                           {insuranceTypeCategoriesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>


        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="insuranceCovers"
            label={trans.table_head_insurance_covers}
            value={insuranceCovers}
            type="text"
            onChange={(e) => setInsuranceCovers(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={6}>
          <DesktopDatePicker
                fullWidth
                label={trans.table_head_insurance_start_date}
                value={startDate}
                onChange={(newValue)=> {
                     // Get the user's timezone offset in minutes
                     const timezoneOffset = newValue.getTimezoneOffset();
                
                     // Convert the offset to milliseconds and adjust the date
                     const adjustedDate = new Date(newValue.getTime() - timezoneOffset * 60000);
                   
                     // Set the adjusted date
                     setStartDate(adjustedDate);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
        </Grid>

        <Grid item xs={6}>
          <DesktopDatePicker
                fullWidth
                label={trans.table_head_insurance_end_date}
                value={endDate}
                onChange={(newValue)=> {
                  // Get the user's timezone offset in minutes
                  const timezoneOffset = newValue.getTimezoneOffset();
             
                  // Convert the offset to milliseconds and adjust the date
                  const adjustedDate = new Date(newValue.getTime() - timezoneOffset * 60000);
                
                  // Set the adjusted date
                  setEndDate(adjustedDate);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
        </Grid>

        <Grid item xs={12}>
          {/* <TextField
            margin="dense"
            id="userId"
            label={trans.table_head_user}
            value={userId}
            type="text"
            onChange={(e) => setUserId(e.target.value)}
            fullWidth
            variant="standard"
          /> */}
           <Autocomplete
                    fullWidth
                    id="checkboxes-users"
                    options={usersArr}
                    disableCloseOnSelect
                    value={usersFilter}
                    onChange={(e,value) => handleUsersChange(e,value)}
                    getOptionLabel={(option) => option.fullname}
                    onInputChange={(event, newInputValue) => {
                      handleSearchInput(newInputValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderOption={(props, option, { selected }) => (
                      <li  {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.fullname}
                      </li>
                    )}
                    
                    renderInput={(params) => (
                      <TextField {...params} label={trans.users_label} placeholder={trans.add_user}  />
                    )}
              />
        </Grid>

        <Grid item xs={6}>
          <TextField
              margin="dense"
              id="accidentCarePhone"
              label={trans.table_head_accidentCarePhone}
              value={accidentCarePhone}
              type="text"
              onChange={(e) => setAccidentCarePhone(e.target.value)}
              fullWidth
              variant="standard"
            />
        </Grid>

        <Grid item xs={6}>
          <TextField
              margin="dense"
              id="roadsideAssistancePhone"
              label={trans.table_head_roadsideAssistancePhone}
              value={roadsideAssistancePhone}
              type="text"
              onChange={(e) => setRoadsideAssistancePhone(e.target.value)}
              fullWidth
              variant="standard"
            />
        </Grid>

        <Grid item xs={6}>
          <TextField
              margin="dense"
              id="serviceLinePhone"
              label={trans.table_head_serviceLinePhone}
              value={serviceLinePhone}
              type="text"
              onChange={(e) => setServiceLinePhone(e.target.value)}
              fullWidth
              variant="standard"
            />
        </Grid>

        <Grid item xs={6}>
          <TextField
              margin="dense"
              id="emergencyPhone"
              label={trans.table_head_emergencyPhone}
              value={emergencyPhone}
              type="text"
              onChange={(e) => setEmergencyPhone(e.target.value)}
              fullWidth
              variant="standard"
            />
        </Grid>
             
        <Grid item xs={12}>
          <h4 className='_split_title'>Πληρωμές</h4>
        </Grid>
       

        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="cost"
            label={trans.table_head_insurance_cost}
            value={cost === 0 ? '' : cost} // Display an empty string if cost is 0
            type="number"
            onChange={(e) => {
              const inputValue = e.target.value;
              const newCost = inputValue === '' ? 0 : Number(inputValue);
              setCost(newCost);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="paidCost"
            label={trans.table_head_insurance_paid_cost}
            value={paidCost === 0 ? '' : paidCost} // Display an empty string if cost is 0
            type="number"
            onChange={(e) => {
              const inputValue = e.target.value;
              const newCost = inputValue === '' ? 0 : Number(inputValue);
              setPaidCost(newCost);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="remainingCost"
            label={trans.table_head_insurance_remaining_cost}
            value={remainingCost === 0 ? '' : remainingCost} // Display an empty string if cost is 0
            type="number"
            onChange={(e) => {
              const inputValue = e.target.value;
              const newCost = inputValue === '' ? 0 : Number(inputValue);
              setRemainingCost(newCost);
            }}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={4}>
           <DesktopDatePicker
                fullWidth
                label={trans.table_head_insurance_paid_until_date}
                value={paidUntilDate}
                onChange={(newValue)=> {
                  // Get the user's timezone offset in minutes
                  const timezoneOffset = newValue.getTimezoneOffset();
             
                  // Convert the offset to milliseconds and adjust the date
                  const adjustedDate = new Date(newValue.getTime() - timezoneOffset * 60000);
                
                  // Set the adjusted date
                  setPaidUntilDate(adjustedDate);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
        </Grid>

        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="paymentWay"
            label={trans.table_head_insurance_payment_way}
            value={paymentWay}
            type="text"
            onChange={(e) => setPaymentWay(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            margin="dense"
            id="receiptNum"
            label={trans.table_head_insurance_receipt_num}
            value={receiptNum}
            type="text"
            onChange={(e) => setReceiptNum(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>

         
         
            <Grid item xs={12} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.insurance_contract_state}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.insurance_contract_state}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`insurance_contract_state_active`} value={true}>{trans.insurance_contract_state_active}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.insurance_contract_state_inactive}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

    
            
        </Grid>
      </div>
    );
}

export default EditInsuranceContractItem;
