import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS, editorConfig } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getLocalDate, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannersEdit } from '../../redux/banners/action';
import { POST_BANNER_EDIT, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import JoditEditor from "jodit-react";
import getUserLocale from 'get-user-locale';
import LangPicker from '../global/LangPicker';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditInsuranceTypeItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const uploadImageData = useSelector(({upload}) => upload.uploadImageData);

 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [title,setTitle] = useState("");
    const [titles,setTitles] = useState([]);
    const [desc,setDesc] = useState("");
    const [descriptions,setDescriptions] = useState([]);
   
    const [value_types,setValueTypes] = useState([]);
    const [value_type,setValueType] = useState("");
    const [desc_types,setDescTypes] = useState([]);
    const [desc_type,setDescType] = useState("");


    const [img,setImg] = useState("");
    const [img_thumb,setImgThumb] = useState("");
    const [site_link, setSiteLink] = useState("");
    const [status,setStatus] = useState(true);

    const [currentImage, setCurrentImage] = useState({url: "", file: null});

    const [selectedLang,setSelectedLang] = useState(settingsData.settings.langs[0]);
 

    //REFS
    const fileInputRef=useRef();
    const editorDescEl = useRef(null);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

          if (currentImage.file != null){
            uploadImageFile();
          } else {
            props.onSubmitData(postData);
          }
        
          
      }
    }, [props.toGetData]);

    const makePostItem = () => {

      let postData = {
        "titles": titles,
        "site_link": site_link,
        "status": status,
        "descriptions": descriptions,
        "value_types": value_types,
        "desc_types": desc_types
      };

      if (uploadImageData != null){
        postData.img = uploadImageData.img;
        postData.img_thumb = uploadImageData.img_thumb;
      }

      return postData;
    }

    const uploadImageFile = () => {
      if (currentImage.file != null){
        var formData = new FormData();
        formData.append('path', "insurnacetypecategories");
        formData.append("image_file", currentImage.file);
        dispatch(postUploadImage(formData))
      }
    }


    useEffect(() => {
      if (editItem != null){

          setTitles(editItem.titles);
          setDescriptions(editItem.descriptions);

          setValueTypes(editItem.value_types);
          setDescTypes(editItem.desc_types);

          setImg(editItem.img_url);
          setImgThumb(editItem.img_thumb_url);
          setSiteLink(editItem.site_link);
          setStatus(editItem.status);

          setLanguageItems();

      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => { 
     // console.log("uploadImageData",uploadImageData);
      if (uploadImageData != null){
        if (uploadImageData.status) {
          toast.dismiss();
          toast.success(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
             
              let postData = makePostItem();

              if (editItem != null){
                postData.itemId = editItem.id;
              }

              props.onSubmitData(postData);
            
        } else {
          toast.dismiss();
          toast.error(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_UPLOAD_IMAGE, payload: null});
      }
    }, [uploadImageData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    useEffect(() => {
      if (selectedLang){
        setLanguageItems();
      }
  }, [selectedLang]);

    //FUNCTIONS
    const clearData = () => {
        setTitle("");
        setStatus(true);
        setDesc("");
        setSiteLink("");
        setTitles([]);
        setDescriptions([]);
        setImg("");
        setImgThumb("");
    }

    const setLanguageItems = () => {
      if (editItem != null){
        if (editItem.titles.find(element => element.lang == selectedLang.value) != null){
          setTitle(editItem.titles.find(element => element.lang == selectedLang.value).value);
        } else {
          setTitle("");
        }
      
        if (editItem.descriptions.find(element => element.lang == selectedLang.value) != null){
          setDesc(editItem.descriptions.find(element => element.lang == selectedLang.value).value);
        } else {
          setDesc("");
        }

        if (editItem.value_types.find(element => element.lang == selectedLang.value) != null){
          setValueType(editItem.value_types.find(element => element.lang == selectedLang.value).value);
        } else {
          setValueType("");
        }

        if (editItem.desc_types.find(element => element.lang == selectedLang.value) != null){
          setDescType(editItem.desc_types.find(element => element.lang == selectedLang.value).value);
        } else {
          setDescType("");
        }
      }
    }

    const updateIconImageFile = (event) => {
      if (event.target.files.length != 0 ){
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
        let file = event.target.files[0];
  
        let reader = new FileReader();
        reader.onloadend = () => {
          setCurrentImage({url:reader.result, file: file});
        }
        reader.readAsDataURL(file);
      }
    }

    const updateTitle = (text) => {
      setTitle(text);
      if (titles.find(element => element.lang == selectedLang.value) != null ){
        var newData = [];
        titles.forEach((element) => {
          var newElement = {value: element.value, lang: element.lang};
          if (element.lang == selectedLang.value){
            newElement.value = text
          }
          newData.push(newElement);
        });
        setTitles(newData);
      } else {
        var newData = titles;
        newData.push({value: text, lang: selectedLang.value});
        setTitles(newData);
      }
    }

    const updateDesc = (text) => {
      setDesc(text);
      if (descriptions.find(element => element.lang == selectedLang.value) != null){
        var newData = [];
        descriptions.forEach((element) => {
          var newElement = {value: element.value, lang: element.lang};
          if (element.lang == selectedLang.value){
            newElement.value = text
          }
          newData.push(newElement);
        });
        setDescriptions(newData);
      } else {
        var newData = descriptions;
        newData.push({value: text, lang: selectedLang.value});
        setDescriptions(newData);
      }
    }

    const updateValueType = (text) => {
      setValueType(text);
      if (value_types.find(element => element.lang == selectedLang.value) != null){
        var newData = [];
        value_types.forEach((element) => {
          var newElement = {value: element.value, lang: element.lang};
          if (element.lang == selectedLang.value){
            newElement.value = text
          }
          newData.push(newElement);
        });
        setValueTypes(newData);
      } else {
        var newData = value_types;
        newData.push({value: text, lang: selectedLang.value});
        setValueTypes(newData);
      }
    }

    const updateDescType = (text) => {
      setDescType(text);
      if (desc_types.find(element => element.lang == selectedLang.value) != null){
        var newData = [];
        desc_types.forEach((element) => {
          var newElement = {value: element.value, lang: element.lang};
          if (element.lang == selectedLang.value){
            newElement.value = text
          }
          newData.push(newElement);
        });
        setDescTypes(newData);
      } else {
        var newData = desc_types;
        newData.push({value: text, lang: selectedLang.value});
        setDescTypes(newData);
      }
    }

    const changeLanguage = (lang) => {
      setSelectedLang(lang);
    }

    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

          <Grid item xs={12}>

            <LangPicker
                onLangSelectedChanged={changeLanguage}
            />

          </Grid>
      

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="title"
                    label={trans.table_head_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {updateTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
                <JoditEditor
                          ref={editorDescEl}
                           value={desc}
                          config={editorConfig}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => updateDesc(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
            </Grid>


            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="value_type"
                    label={trans.table_head_insurance_insurance_value}
                    value={value_type}
                    type="text"
                    onChange={(e)=> {updateValueType(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="desc_type"
                    label={trans.table_head_insurance_insurance_desc}
                    value={desc_type}
                    type="text"
                    onChange={(e)=> {updateDescType(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>



            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="site_link"
                    label={trans.table_head_site_link}
                    value={site_link}
                    type="text"
                    onChange={(e)=> {setSiteLink(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>
         
         
            <Grid item xs={12} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_visible}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.table_head_visible}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`menu_item_visible`} value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
          

            <Grid item xs={12}>
                  <span>{trans.upload_insurance_type_icon_field}</span>
                  <div style={{marginTop:'15px'}}>
                    <Button color="success" variant="outlined" onClick={()=>fileInputRef.current.click()} >{trans.choose_file}</Button>
                    <input ref={fileInputRef} id="fileupload" type="file" name="file" onChange={(e) => updateIconImageFile(e)}  multiple style={{display:'none'}}/>
                  </div>
            </Grid>

            
            <Grid item xs={12} >
              {currentImage.url == "" ? 
              <img style={{maxHeight:'80px',maxWidth:'100%'}} src={img}></img>
              : <img style={{maxHeight:'80px',maxWidth:'100%'}} src={currentImage.url}></img>}
                  
              </Grid>

        </Grid>
      </div>
    );
}

export default EditInsuranceTypeItem;
