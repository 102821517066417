import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PRIMARY_COLOR, editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import EuroIcon from '@mui/icons-material/Euro';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage, postUploadSingleFile } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { postInsuranceContractAddFile, postInsuranceContractDeleteFile, postInsuranceContractEditFile, postInsuranceContractReorderFiles, postInsuranceContractSingle } from '../../redux/insuranceContract/action';
import FormDialog from '../global/formDialog';
import { POST_USER_CHANGE_PASSWORD, POST_USER_SINGLE } from '../../redux/actionTypes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SweetAlert from 'sweetalert2';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';
import {  arrayMove } from  'react-sortable-hoc'
import { postAddContactOption, postChangeUserPassword, postDeleteContactOption, postUserSingle } from '../../redux/auth/action';
import { Checkbox } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filter = createFilterOptions();



const EditUserPassword =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);

    const changePasswordData  = useSelector(({auth}) => auth.changePasswordData);
    const userSingleData = useSelector(({auth}) => auth.userSingleData);
   
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [password,setPassword] = useState("");
    const [repassword,setRePassword] = useState("");
    const [toSendEmail,setToSendEmail] = useState(true);
    
    const [isRequesting,setIsRequesting] = useState(false);
    const [userItem,setUserItem] = useState(null);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
    const makePostItem = () => {

      let postData = {
        "verifyPassword": password,
        "newPassword": repassword,
        "userId": editItem.id,
        toSendEmail
      };

      return postData;
    }

    useEffect(() => {
      if (changePasswordData != null){
        if (changePasswordData.status) {
          toast.dismiss();
          toast.success(changePasswordData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onPasswordUpdated();

          updateTypeData()

          setIsRequesting(false);

        } else {
          toast.dismiss();
          toast.error(changePasswordData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_USER_CHANGE_PASSWORD, payload: null});

      } 
    },[changePasswordData]);


    useEffect(() => {
      if (userSingleData != null){
    
        setUserItem(userSingleData)

        dispatch({type: POST_USER_SINGLE, payload: null});
      } 
    },[userSingleData]);


    useEffect(() => {
      if (editItem != null){
    
        setUserItem(editItem)

      } 
    },[editItem]);
  

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);


    //FUNCTIONS
    const clearData = () => {
        setPassword("");
        setRePassword('');
    }




    const onSubmit = (e) => {
      e.preventDefault();

      if (isRequesting){
        return;
      }

      setIsRequesting(true);

      let postData = makePostItem();

      dispatch(postChangeUserPassword(postData));
    }


    const updateTypeData = () => {
      dispatch(postUserSingle({
        "userId": editItem.id,
        "langLoc":  "el"
      }));
      clearData();
    }

   
    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

        <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="value"
                    label={trans.user_new_password}
                    value={password}
                    type="text"
                    onChange={(e)=> {setPassword(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

          

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="value"
                    label={trans.user_renew_password}
                    value={repassword}
                    type="text"
                    onChange={(e)=> {setRePassword(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={ toSendEmail}
                  onChange={(e,value) => setToSendEmail(value)}
                />
                <span>{trans.to_send_email}</span>
              </div>
             
            </Grid>
            
        

            <Grid container justifyContent="center" >
              <Grid item>
               <br/>
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={(password == null || password == "" || password != repassword) || isRequesting == true}  onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
             
            </Grid>
         

        </Grid>

        

      </div>
    );
}

export default EditUserPassword;
