import {
  POST_NOTIFICATION_ALL,
  POST_NOTIFICATION_ADD
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  notificationsData: null,
  addNotificationData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_NOTIFICATION_ALL:
               return {
                ...state,
               notificationsData: action.payload
          }
          case POST_NOTIFICATION_ADD:
            return {
              ...state,
              addNotificationData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;