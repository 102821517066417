import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PRIMARY_COLOR, editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EuroIcon from '@mui/icons-material/Euro';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { POST_INSURANCE_TYPE_ADD_CATEGORY, POST_INSURANCE_TYPE_ADD_CATEGORY_PRICE, POST_INSURANCE_TYPE_CHANGE_PRICE_ORDER, POST_INSURANCE_TYPE_DELETE_CATEGORY_PRICE, POST_INSURANCE_TYPE_EDIT_CATEGORY, POST_INSURANCE_TYPE_EDIT_CATEGORY_PRICE, POST_INSURANCE_TYPE_SINGLE, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SweetAlert from 'sweetalert2';
import { SortableContainer , SortableHandle , SortableElement , arrayMove } from  'react-sortable-hoc'

import { postInsuranceTypeAddCategory, postInsuranceTypeAddCategoryPrice, postInsuranceTypeChangePriceOrder, postInsuranceTypeDeleteCategoryPrice, postInsuranceTypeEditCategory, postInsuranceTypeEditCategoryPrice, postInsuranceTypeSingle } from '../../redux/insuranceType/action';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const EditInsuranceCategoryPricesItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const addInsuranceTypeCategoryPriceData = useSelector(({insuranceTypes}) => insuranceTypes.addInsuranceTypeCategoryPriceData);
    const editInsuranceTypeCategoryPriceData = useSelector(({insuranceTypes}) => insuranceTypes.editInsuranceTypeCategoryPriceData);
    const deleteInsuranceTypeCategoryPriceData = useSelector(({insuranceTypes}) => insuranceTypes.deleteInsuranceTypeCategoryPriceData);
    const changeInsuranceTypeCategoryPriceOrderData = useSelector(({insuranceTypes}) => insuranceTypes.changeInsuranceTypeCategoryPriceOrderData);
    const singleInsuranceTypeData = useSelector(({insuranceTypes}) => insuranceTypes.singleInsuranceTypeData);

 
    const {selectedCategoryEditItem,selectedInsuranceTypeItem} = props;
    
    //HOOKS VARIABLES
    const [insuranceTypeCategoryItem,setInsuranceTypeCategoryItem] = useState(null);
    const [selectedPrice,setSelectedPrice] = useState(null);
    const [price,setPrice] = useState("");
    const [city,setCity] = useState("");
    const [per,setPer] = useState("");
    const [categoryPrices, setCategoryPrices] = useState([]);
    
    const [selectedLang,setSelectedLang] = useState(settingsData.settings.langs[0]);
 



    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
  
    const makePostItem = () => {

      let postData = {
        "price": price,
        "city": city,
        "per": per,
        "lang": selectedLang.value,
        "categoryId": selectedCategoryEditItem.id,
        "insuranceTypeId": selectedInsuranceTypeItem.id
      };

      return postData;
    }

    const saveCategoryData = (postData) => {
      if (selectedPrice == null){
        console.log(postData);
        dispatch(postInsuranceTypeAddCategoryPrice(postData))
      } else {
        postData.priceId = selectedPrice._id;
        console.log(postData);
        dispatch(postInsuranceTypeEditCategoryPrice(postData))
      }

    }

    useEffect(() => {
      if (addInsuranceTypeCategoryPriceData != null){
        if (addInsuranceTypeCategoryPriceData.status) {
          toast.dismiss();
          toast.success(addInsuranceTypeCategoryPriceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onCategoriesUpdated();

          updateTypeData()

        } else {
          toast.dismiss();
          toast.error(addInsuranceTypeCategoryPriceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_ADD_CATEGORY_PRICE, payload: null});

      } 
    },[addInsuranceTypeCategoryPriceData]);

    useEffect(() => {
      if (editInsuranceTypeCategoryPriceData != null){
        if (editInsuranceTypeCategoryPriceData.status) {
          toast.dismiss();
          toast.success(editInsuranceTypeCategoryPriceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onCategoriesUpdated();

              updateTypeData()
        } else {
          toast.dismiss();
          toast.error(editInsuranceTypeCategoryPriceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_EDIT_CATEGORY_PRICE, payload: null});

      } 
    },[editInsuranceTypeCategoryPriceData]);

    useEffect(() => {
      if (deleteInsuranceTypeCategoryPriceData != null){
        if (deleteInsuranceTypeCategoryPriceData.status) {
          toast.dismiss();
          toast.success(deleteInsuranceTypeCategoryPriceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onCategoriesUpdated();

              updateTypeData()
        } else {
          toast.dismiss();
          toast.error(deleteInsuranceTypeCategoryPriceData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_DELETE_CATEGORY_PRICE, payload: null});

      } 
    },[deleteInsuranceTypeCategoryPriceData]);

    useEffect(() => {
      if (changeInsuranceTypeCategoryPriceOrderData != null){
        if (changeInsuranceTypeCategoryPriceOrderData.status) {
              props.onCategoriesUpdated();
              updateTypeData()
        } 
        dispatch({type: POST_INSURANCE_TYPE_CHANGE_PRICE_ORDER, payload: null});
      } 
    },[changeInsuranceTypeCategoryPriceOrderData]);

    useEffect(() => {
      if (singleInsuranceTypeData != null){
    
        singleInsuranceTypeData.categories.forEach(insuranceTypeCategory => {
                if (insuranceTypeCategory.id == selectedCategoryEditItem.id){
                  setInsuranceTypeCategoryItem(insuranceTypeCategory);
                  setCategoryPrices(insuranceTypeCategory.prices);
                }
        });

        dispatch({type: POST_INSURANCE_TYPE_SINGLE, payload: null});
      } 
    },[singleInsuranceTypeData]);




    useEffect(() => {
      if (selectedCategoryEditItem != null){

          setInsuranceTypeCategoryItem(selectedCategoryEditItem);
          setCategoryPrices(selectedCategoryEditItem.prices)

      } else {
        clearData();
      }
    },[selectedCategoryEditItem]);

    useEffect(() => {
      if (selectedPrice != null){

          setPrice(selectedPrice.price);
          setCity(selectedPrice.city);
          setPer(selectedPrice.per);

      } else {
        clearData();
      }
    },[selectedPrice]);


    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

  //   useEffect(() => {
  //     if (selectedLang){
  //       setLanguageItems();
  //     }
  // }, [selectedLang]);

    //FUNCTIONS
    const clearData = () => {
        setPrice("");
        setCity("");
        setPer("");
    }
   
    const changeLanguage = (lang) => {
      setSelectedLang(lang);
    }

    const columnData = [
      {id: "price", align: true, disablePadding: false, label:  trans.table_head_price},
      {id: "city", align: true, disablePadding: false, label: trans.table_head_city},
      {id: "per", align: true, disablePadding: false, label: trans.table_head_per}
    ];
  

    const onEditRow = (e,item) => {
      e.preventDefault();
      setSelectedPrice(item);
    }

    const onClean = (e) => {
      e.preventDefault();
      setSelectedPrice(null);
    }

    const onSubmit = (e) => {
      e.preventDefault();

      if (price != "" && city != ""){
        let postData = makePostItem();

        saveCategoryData(postData);
      }
    }


    const updateTypeData = () => {
      dispatch(postInsuranceTypeSingle({
        "itemId": selectedInsuranceTypeItem.id,
        "langLoc": selectedLang.value
      }));
      clearData();
      setSelectedPrice(null);
    }

    const onDeleteRow = (e,item) => {
      e.preventDefault();
      SweetAlert.fire({
        theme: "dark",
        title:trans.delete_title,
        text:trans.delete_descr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.delete_title,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            deleteItem(item);
        }
      })
    }


    const deleteItem = (item) => {
      dispatch(postInsuranceTypeDeleteCategoryPrice({
        "insuranceTypeId": selectedInsuranceTypeItem.id,
        "categoryId": selectedCategoryEditItem.id,
        "priceId": item._id,
        "langLoc": "el"
      }));
    }
 

  // Handler for traversing completion, helper arrayMove is used
  const onSortEnd  = ({oldIndex, newIndex}) => {
    const updatedItems = arrayMove(categoryPrices, oldIndex, newIndex);
    const updatedItemsWithOrder = updatedItems.map((item, index) => {
      return { ...item, order: index + 1 };
    });
    setCategoryPrices(updatedItemsWithOrder);
    dispatch(postInsuranceTypeChangePriceOrder({      "insuranceTypeId": selectedInsuranceTypeItem.id,
    "categoryId": selectedCategoryEditItem.id,orderedItems: updatedItemsWithOrder}))
  };

    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

    {settingsData.settings.langs.length > 1 ? 
          <Grid item xs={12}>

            <LangPicker
                onLangSelectedChanged={changeLanguage}
            />

          </Grid> : null }
      

            <Grid item xs={4}>
              <TextField
                    margin="dense"
                    id="table_head_price"
                    label={trans.table_head_price}
                    value={price}
                    type="text"
                    onChange={(e)=> {setPrice(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={4}>
              <TextField
                    margin="dense"
                    id="table_head_city"
                    label={trans.table_head_city}
                    value={city}
                    type="text"
                    onChange={(e)=> {setCity(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={4}>
              <TextField
                    margin="dense"
                    id="table_head_per"
                    label={trans.table_head_per}
                    value={per}
                    type="text"
                    onChange={(e)=> {setPer(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>
   
            <Grid container justifyContent="center" >
              <Grid item>
                {selectedPrice != null ?  
                <Button style={{marginTop:'10px', marginBottom:'10px',backgroundColor: 'green'}}  variant="contained"  disabled={props.onSubmitLoading} onClick={onClean}>{trans.cancel_btn}</Button>
                : null }
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={price == "" || city == ""} onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
            </Grid>
            
            <Grid item xs={12} >

              <div className='_insurance_categories_container'>
                  {insuranceTypeCategoryItem != null ? 
                  <Table size="small" style={{overflowX:'auto'}}>
                    <TableHead>
                        <TableRow>
                      
                        {columnData.map(column => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric}
                              padding={column.disablePadding ? "none" : "normal"}
                            >
                                {column.label}
                            </TableCell>
                          );
                        })}
                        <TableCell/>
                        <TableCell/>
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBodySortable onSortEnd={(sortEnd) => onSortEnd(sortEnd)} useDragHandle>
                        {categoryPrices.map((row,index) => (
                        <SortableRow index={index} key={row._id}>
                             <TableCell><DragHandler /></TableCell> 
                            <TableCell>{row.price}</TableCell>
                            <TableCell>{row.city}</TableCell>
                            <TableCell>{row.per}</TableCell>
                            <TableCell>
                              <Button
                                startIcon={<HowToRegIcon />}
                                size="small"
                                color="secondary"
                                onClick={(e) => onEditRow(e, row)}
                                variant="outlined"
                              >
                                {trans.edit_item_title}
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                startIcon={<DeleteOutlineIcon />}
                                size="small"
                                onClick={(e) => onDeleteRow(e, row)}
                                variant="outlined"
                              >
                                {trans.table_delete_button}
                              </Button>
                            </TableCell>
                      </SortableRow>
                        ))}
                    </TableBodySortable>
                  
                  </Table> : null }

              </div>

            </Grid>

        </Grid>
      </div>
    );
}

export default EditInsuranceCategoryPricesItem;
