import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import { generatePassword, getBirthdateFormated, getLocalDate, isItemValid } from '../../helpers/General';
import { GoogleComponent } from 'react-google-autocomplete';


import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const AddEditUser =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [fullname,setfullname] = useState("");
    const [address,setAddress] = useState("");
    const [gender,setGender] = useState(3);
    const [birthdate, setBirthdate] = useState(null);
    const [lat,setLat] = useState(0.0);
    const [lng,setLng] = useState(0.0);
    const [postalCode,setPostalCode] = useState("");
    const [mobile,setMobile] = useState("");
    const [mobileCode,setMobileCode] = useState("");
    const [mobileCodeImg,setMobileCodeImg] = useState("");
    const [approved,setApproved] = useState(false);
    const [enabled,setEnabled] = useState(true);

    const [email,setEmail] = useState("");
    const [password,setPassword] = useState(generatePassword());

    const [toSendWelcomeEmail,setToSendWelcomeEmail] = useState(false);
    
    const [phoneType,setPhoneType] = useState("MOBILE");

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;

    const autocomplete = new window
    .google
    .maps
    .places
    .Autocomplete(document.getElementById('address'), {});
const handlePlaceSelect = () => {
    let addressObject = autocomplete.getPlace()
        var lat = addressObject
                .geometry
                .location
                .lat(),
            lng = addressObject
                .geometry
                .location
                .lng();
        let address = addressObject.address_components;
        if (address[0] != undefined && address[1] != undefined) {
            setAddress(`${address[1].long_name} ${address[0].long_name}`);
        }
        if (address[5] != undefined) {
            setPostalCode(address[5].short_name);
        }
        setLat(lat);
        setLng(lng);
    };
    autocomplete.addListener("place_changed", handlePlaceSelect);
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = {
            "fromAdmin": true,
            "userId":editItem != null ? editItem.id : "",
            "fullname": fullname,
            "birthdate":getBirthdateFormated(birthdate),
            "gender": gender,
            "usertype": 1,
            "langLoc": "en",
            "address": address,
            "lat": lat != null ? lat : 0.0,
            "lng": lng != null ? lng : 0.0,
            "postalCode": postalCode
        }

        if (editItem == null){
            postData = {
              "fromAdmin": true,
              "email":  email,
              "password": password,
              "fullname": fullname,
              "birthdate":getBirthdateFormated(birthdate),
              "gender": gender,
              "langLoc": "en",
              "usertype": 1,
              "phone": mobile,
              "phoneType": "MOBILE",
              "toSendWelcomeEmail":toSendWelcomeEmail
          }
        }
        
          props.onSubmitData(postData)
      }
    }, [props.toGetData]);

    useEffect(() => {
      if (editItem != null){
          setfullname(editItem.fullname);
          setAddress(editItem.address);
          setEmail(editItem.email);
          // setMobile(editItem.mobile);
          // setMobileCode(editItem.mobilecode);
          setEnabled(editItem.enabled);
          setGender(editItem.gender);
          setBirthdate(editItem.birthdate);
          setLat(editItem.lat);
          setLng(editItem.lng);
          // countriesData.countries.forEach(country => {
          //   if (country.phone_code == editItem.mobilecode){
          //     setMobileCodeImg(country.icon)
          //   }
          // });

      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
        if (props.toGetData){
            // let tagsIds = selectedTags.map((value) => value.id);

            // let postData = {name,visible,slug,defaultTags:tagsIds,order};
            // props.onSubmitData(postData)
        }
      }, [props.toGetData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);


    //FUNCTIONS
    const clearData = () => {
        setfullname("");
        setEmail("");
        setPassword(generatePassword());
        setEnabled(true);
        setAddress("");
        setGender(3);
        setBirthdate(null);
        setMobile("");
    }

    const openUserDirections = () => {
      var url = "https://maps.google.com/?q=" + editItem.lat + "," + editItem.lng;
      window.open(url);   
    }


    //UI
    return (
      <div>
        <Grid container spacing={3} alignItems="center">

          {editItem != null ? 
            <Grid item xs={12}>
              <div>
                <h4>{trans.photo_profile}</h4>
                {editItem.img_thumb_url == "#" || editItem.img_thumb_url == "" ? <span style={{fontSize:'13px'}}>{trans.no_photo_profile_yet}</span> :
                <a href={editItem.img_url} target="_blank" rel="noreferrer"> <img height={"100px"} src={editItem.img_thumb_url} ></img> </a> }
              </div>
            </Grid> : null}

            <Grid item xs={12}>
                <hr className='_split_line'/>
            </Grid>

            <Grid item xs={12}>
              <h4>{trans.user_details}</h4>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="fullname"
                    label={trans.table_head_fullname}
                    value={fullname}
                    type="text"
                    onChange={(e)=> {setfullname(e.target.value)}}
                    fullWidth
                    variant="standard" />
                </Grid>

                <Grid item xs={editItem == null ? 6 : 12 }>
                  <TextField
                    margin="dense"
                    id="email"
                    label={trans.email_field}
                    value={email}
                    type="text"
                    onChange={(e)=> {setEmail(e.target.value)}}
                    fullWidth
                    variant="standard" />
                </Grid>

                {editItem == null ?
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="pass"
                    label={trans.pass_field}
                    value={password}
                    type="text"
                    onChange={(e)=> {setPassword(e.target.value)}}
                    fullWidth
                    variant="standard" />
                </Grid> : null}
               
              </Grid>
            </Grid>

            

            {editItem != null ? 
            <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Grid xs={9}>  
                <TextField
                    margin="dense"
                    id="address"
                    label={trans.address_field}
                    value={address}
                    type="text"
                    onChange={(e)=> {setAddress(e.target.value)}}
                    fullWidth
                    variant="standard" /> 
                </Grid> 

               
                <Grid item xs={2}>  
                  <Button 
                      startIcon={<DirectionsIcon />} 
                      size="small" 
                      color="secondary" 
                      onClick={(e) => openUserDirections(e)}  
                      variant="outlined">{trans.address_map_btn}
                  </Button> 
                </Grid>
              </Grid>
            </Grid> : null }

            <Grid item xs={6}><div>
            <br/>
                <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.gender_field}</InputLabel>
                  <Select
                      labelId="gender_field"
                      id="enabled"
                      value={gender}
                      label={trans.gender_field}
                      onChange={(e) => setGender(e.target.value)}>
                      <MenuItem key={"gender_mail"} value={1}>{trans.gender_mail}</MenuItem>
                      <MenuItem key={"gender_femail"} value={2}>{trans.gender_femail}</MenuItem>
                      <MenuItem key={"gender_none"} value={3}>{trans.gender_none}</MenuItem>
                      
                  </Select>
                  </FormControl>
                  </div>
            </Grid>
            


            <Grid item xs={6} ><div>
            <br/>
            <DesktopDatePicker
                fullWidth
                label={trans.birthdate_field}
                value={birthdate}
                onChange={(newValue)=> {
                  // Get the user's timezone offset in minutes
                  const timezoneOffset = newValue.getTimezoneOffset();
             
                  // Convert the offset to milliseconds and adjust the date
                  const adjustedDate = new Date(newValue.getTime() - timezoneOffset * 60000);
                
                  // Set the adjusted date
                  setBirthdate(adjustedDate);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </div>
             
            </Grid>

          {editItem == null ?
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="mobile"
                label={trans.mobile_field}
                value={mobile}
                type="text"
                onChange={(e)=> {setMobile(e.target.value)}}
                fullWidth
                variant="standard" />
            </Grid> : null}

            {editItem == null ? 
              <Grid item xs={12}>
                  <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={ toSendWelcomeEmail}
                  onChange={(e,value) => setToSendWelcomeEmail(value)}
                />
                <span>{trans.to_send_welcome_email}</span>
              </div>
              </Grid>  : null
          }
         
            {/* <Grid item xs={1}>
              {mobileCodeImg != "" && mobileCodeImg != "#" ?  <img style={{width: "35px"}} src={mobileCodeImg} alt="Code Img"></img> : null}
            </Grid> 

            <Grid item xs={2}>
              <TextField
                margin="dense"
                id="mobile"
                label={trans.mobile_code_field}
                value={mobileCode}
                type="text"
                onChange={(e)=> {setMobileCode(e.target.value)}}
                fullWidth
                disabled
                variant="standard" />
            </Grid>

            <Grid item xs={9}>
              <TextField
                margin="dense"
                id="mobile"
                label={trans.mobile_field}
                value={mobile}
                type="text"
                onChange={(e)=> {setMobile(e.target.value)}}
                fullWidth
                disabled
                variant="standard" />
            </Grid> */}

            {/* <Grid item xs={12}>
              <hr className='_split_line'/>
            </Grid> */}
{/*            
            <Grid item xs={12}>
              <h4><b>{trans.user_id_card}</b></h4>
              <div className='_flex_row_space'> 
                <span className='_text_info_14'><b>{trans.id_number}</b>: {isItemValid(editItem.id_card_num) ? editItem.id_card_num : trans.detail_not_added}</span>
                <span className='_text_info_14'><b>{trans.expire_date}</b>: {getLocalDate( editItem.id_card_expire * 1000)}</span>
              </div>
            
              <br/>
               <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid xs={6}>  
                    <div >
                      <div className='_text_info_14'><b>{trans.user_id_card_front}</b></div>
                      {isItemValid(editItem.id_card_img_front_url) && isItemValid(editItem.id_card_img_thumb_front_url) ? 
                      <a href={editItem.id_card_img_front_url} target="_blank" rel="noreferrer"> <img height={"140px"} src={editItem.id_card_img_thumb_front_url} ></img> </a> :
                      <div className='_text_info_13'>{trans.detail_not_added}</div> }
                    </div>
                  </Grid>
                  <Grid xs={6}> 
                  <div >
                      <div className='_text_info_14'><b>{trans.user_id_card_back}</b></div>
                      {isItemValid(editItem.id_card_img_back_url) && isItemValid(editItem.id_card_img_back_thumb_url) ? 
                      <a href={editItem.id_card_img_back_url} target="_blank" rel="noreferrer"> <img height={"140px"} src={editItem.id_card_img_back_thumb_url} ></img> </a> :
                      <div className='_text_info_13'>{trans.detail_not_added}</div> }
                    </div> 
                  </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h4><b>{trans.driver_id_card}</b></h4>
              <div className='_flex_row_space'> 
                <span className='_text_info_14'><b>{trans.driver_id_number}</b>: {isItemValid(editItem.driving_license_num) ? editItem.driving_license_num : trans.detail_not_added}</span>
                <span className='_text_info_14'><b>{trans.driver_expire_date}</b>: {getLocalDate( editItem.driving_license_expire * 1000)}</span>
              </div>
            
              <br/>
               <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid xs={6}>  
                    <div >
                      <div className='_text_info_14'><b>{trans.driver_id_card_front}</b></div>
                      {isItemValid(editItem.driving_license_img_front_url) && isItemValid(editItem.driving_license_img_thumb_front_url) ? 
                      <a href={editItem.driving_license_img_front_url} target="_blank" rel="noreferrer"> <img height={"140px"} src={editItem.driving_license_img_thumb_front_url} ></img> </a> :
                      <div className='_text_info_13'>{trans.detail_not_added}</div> }
                    </div>
                  </Grid>
                  <Grid xs={6}> 
                  <div >
                      <div className='_text_info_14'><b>{trans.driver_id_card_back}</b></div>
                      {isItemValid(editItem.driving_license_img_back_url) && isItemValid(editItem.driving_license_img_back_thumb_url) ? 
                      <a href={editItem.driving_license_img_back_url} target="_blank" rel="noreferrer"> <img height={"140px"} src={editItem.driving_license_img_back_thumb_url} ></img> </a> :
                      <div className='_text_info_13'>{trans.detail_not_added}</div> }
                    </div> 
                  </Grid>
              </Grid>
            </Grid> */}

                   {editItem != null ? <Grid item xs={12}>
              <hr className='_split_line'/>
            </Grid>   : <div></div>}

          {editItem != null ? 
            <Grid item xs={12}>
              <h4>{trans.account_status_title}</h4>
                <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_account_status}</InputLabel>
                  <Select
                      labelId="account_status"
                      id="enabled"
                      value={enabled}
                      label={trans.table_head_account_status}
                      onChange={(e) => setEnabled(e.target.value)}>
                      <MenuItem key={"change_account_status_aproved"} value={true}>{trans.account_status_approved}</MenuItem>
                      <MenuItem key={"change_account_status_rejected"} value={false}>{trans.account_status_rejected}</MenuItem>
                  </Select>
                  </FormControl>
            </Grid> : null}
           
           {/* {accountStatus == false ? <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id="rejection_reasons">{trans.rejects_reasons}</InputLabel>
                <Select
                      labelId="rejects_reasons"
                      id="rejects_reasons"
                      value={rejectReason}
                      label={trans.rejects_reasons}
                      onChange={(e) => setRejectReason(e.target.value)}>
                      <MenuItem value={trans.reject_reason_id_card}>{trans.reject_reason_id_card}</MenuItem>
                      <MenuItem value={trans.reject_reason_driving_license}>{trans.reject_reason_driving_license}</MenuItem>
                      <MenuItem value={trans.reject_reason_driving_license_expired}>{trans.reject_reason_driving_license_expired}</MenuItem>
                      <MenuItem value={trans.reject_reason_profile_photo}>{trans.reject_reason_profile_photo}</MenuItem>
                      <MenuItem value={trans.reject_reason_other}>{trans.reject_reason_other}</MenuItem>
                </Select>
              </FormControl>
            </Grid> : null }

            {rejectReason == trans.reject_reason_other ?
              <Grid item xs={12}>  <TextField
                margin="dense"
                id="rejectReason"
                label={trans.reject_reason}
                value={rejectReasonOther}
                type="text"
                onChange={(e)=> {setRejectReasonOther(e.target.value)}}
                fullWidth
                variant="standard" />
                
                </Grid> : null } */}

          <Grid item xs={12}>
            <hr className='_split_line'/>
          </Grid>

        </Grid>
      </div>
    );

}

export default AddEditUser;
