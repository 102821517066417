import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useDispatch, useSelector} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { postCategoriesAll } from '../../redux/categories/action';
import { postSourcesAll } from '../../redux/sources/action';
import { postTagsAll } from '../../redux/tags/action';
import Chip from '@mui/material/Chip';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { SOURCE_TWITTER_ID } from '../../constants';
import { push } from 'connected-react-router';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();

const AddPost =(props)=> {

    const descRef = useRef();

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const sourcesData = useSelector(({sources}) => sources.sourcesData);
    const categoriesData = useSelector(({categories}) => categories.categoriesData);
    const tagsData = useSelector(({tags}) => tags.tagsData);


    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    let date = new Date();
    const {editItem} = props;
     
    
    //HOOKS VARIABLES
    const [title,setTitle] = useState("");
    const [desc,setDesc] = useState("");
    const [web_url,setWebUrl] = useState("");
    const [categories, setCategories] = useState([]);
    const [source, setSource] = useState("");
    const [tags, setTags] = useState([]);
    const [is_published,setIsPublished] = useState(true);

    const [original_date_item_day, setOriginalDateItemDay] = useState(0);
    const [original_date_item_month, setOriginalDateItemMonth] = useState(0);
    const [original_date_item_year, setOriginalDateItemYear] = useState( 0);

    const [last_shared_on_twitter_at, setLastSharedOnTwitterAt] = useState(null);
    const [last_shared_on_instagram_at, setLastSharedOnInstagramAt] = useState(null);
    const [last_shared_on_facebook_at, setLastSharedOnFacebookAt] = useState(null);
    const [last_shared_on_linkedin_at, setLastSharedOnLinkedinAt] = useState(null);
    const [sourcesArr, setSourcesArr] = useState([]);
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [tagsArr, setTagsArr] = useState([]);
    
    const [visible,setVisible] = useState(true);

    const [searchTagKeyword,setSerachTagKeyword] = useState("");
   
    const [social_id,setSocialId] = useState("");
    const [social_id_hint,setSocialIdHint] = useState(trans.add_post_twitter_id);

    


    //HOOKS
    useEffect(() => {
      getData();
    },[]);

    useEffect(() => {
      if (editItem != null){
          setTitle(editItem.title);
          setDesc(editItem.desc);
          setWebUrl(editItem.web_url);
          setSocialId(editItem.social_id);
        
          setOriginalDateItemDay(editItem.original_date_item.day);
          setOriginalDateItemMonth(editItem.original_date_item.month);
          setOriginalDateItemYear(editItem.original_date_item.year);

          setLastSharedOnTwitterAt(editItem.last_shared_on_twitter_at);
          setLastSharedOnInstagramAt(editItem.last_shared_on_instagram_at);
          setLastSharedOnFacebookAt(editItem.last_shared_on_facebook_at);
          setLastSharedOnLinkedinAt(editItem.last_shared_on_linkedin_at);
          setIsPublished(editItem.is_published);
      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
        if (props.toGetData){

            console.log("document.activeElement",document.activeElement);
            console.log("descRef.current", descRef.current);
            if (document.activeElement === descRef.current) {
              props.onSubmitData(null)
              return;
            }

            var postCategories = [];
            categories.map(value => postCategories.push(value.id));
            
           let originalDate = {day: original_date_item_day,month: original_date_item_month,year: original_date_item_year};

           var postSource = "";
           if (source != null){
             postSource = source.id;
           }

            let postData = {
              title,
              desc,
              web_url,
              categories:postCategories,
              source: postSource,
              tags,
              is_published,
              original_date_item: originalDate,
              social_id,
              last_shared_on_twitter_at,
              last_shared_on_instagram_at,
              last_shared_on_facebook_at,
              last_shared_on_linkedin_at
            };
            //console.log(postData);
            props.onSubmitData(postData)
        }
      }, [props.toGetData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    useEffect(() => {
      if (sourcesData != null){
         setSourcesArr(sourcesData.sources);
         if (editItem != null && editItem.source != null){
          setSource(editItem.source);
         }
      } 
    },[sourcesData]);

    useEffect(() => {
      if (categoriesData != null){
         setCategoriesArr(categoriesData.categories);
         if (editItem != null){
          if (categories.length == 0){
            setCategories(editItem.categories)
          }
           
         }
      } 
    },[categoriesData]);

    useEffect(() => {
      if (tagsData != null){
        var arr = [];
        tagsData.tags.forEach(element => {
          arr.push(element);
        });
        setTagsArr(arr);

        if (editItem != null){ 
          if (tags.length == 0){
            setTags(editItem.tags)
          }
          
        }
      } 
    },[tagsData]);

    useEffect(() => {
      if (searchTagKeyword != null){
        getTags();
      } 
    },[searchTagKeyword]);

  //FUNCTIONS
  const clearData = () => {
        setTitle("");
        setVisible(true);
        setDesc("");
        setWebUrl("");
        setSocialId("");
        setCategories([]);
        setTags([]);
        if (editItem == null){
          setOriginalDateItemDay(0);
          setOriginalDateItemMonth(0);
          setOriginalDateItemYear(0);
        }
     
        getData();
  }

  const getData = () => {
    let postData = {hasPaginate: false};
    dispatch(postCategoriesAll(postData)); 
    dispatch(postSourcesAll(postData)); 
    getTags();
  }

  const getTags = () => {
    let postData = {hasPaginate: false,searchKeyword: searchTagKeyword};
    dispatch(postTagsAll(postData)); 
  }

  const handleCategoriesChange = (e,selectedValues) => {
    let oldCategories = categories.map((value) => value);
 
    let selectedCategories = selectedValues.map((value) => value);

    setCategories(selectedCategories);


    var newCategories = []; 

    selectedCategories.forEach(cat => {
      var exists = false;
      oldCategories.forEach(oldCategory => {

        if (cat.id == oldCategory.id){
            exists = true;
        }
     
      });

      if (!exists) {
        newCategories.push(cat)
      }
      
     
    });

    

    var tagsToAdd = [];
    newCategories.forEach(category => {
        category.defaultTags.forEach(element => {
            tagsToAdd.push(element);
        });
    });

    var newTags = tags.map((value)=> value);

    tagsToAdd.forEach(tagToAdd => {
      var exists = false;
      newTags.forEach(newTag => {
  
          if (tagToAdd.id == newTag.id) {
            exists = true;
          }
      });
      if (!exists) {
        newTags.push(tagToAdd)
      }
       
    });

    setTags(newTags);

  }

  const handleTagsChange  = (e,selectedValues) => {
    //console.log(selectedValues);
    // setTags(selectedValues);

    // let oldTags = tags.map((value) => value);
    // var newTags = selectedValues.map((value) => value);

    // selectedValues.forEach(selectedTag => {
    //   selectedTag.defaultTags.forEach(defaultTag => {

    //       var exists = false;

    //       newTags.forEach(newTag => {
    //           if (newTag.id == defaultTag.id) {
    //             exists = true;
    //           }
    //       });

    //       if (!exists) {
    //         newTags.push(defaultTag);
    //       }


    //   });
    // });

    // setTags(newTags);

    let oldTags = tags.map((value) => value);
 
    let selectedTags = selectedValues.map((value) => value);

    var newAddedTags = []; 
    var oldAddedTags = [];

    selectedTags.forEach(tag => {
      var exists = false;
      oldTags.forEach(oldTag => {

        if (tag.id == oldTag.id){
            exists = true;
        }
     
      });

      if (!exists) {
        newAddedTags.push(tag)
      } else {
        oldAddedTags.push(tag);
      }
     
    });

    
    var tagsToAdd = [];
    newAddedTags.forEach(tag => {
      const index = tagsToAdd.findIndex(object => object.id === tag.id);
      if (index === -1) {
        tagsToAdd.push(tag);
      }
    });

    oldAddedTags.forEach(tag => {
      const index = tagsToAdd.findIndex(object => object.id === tag.id);
      if (index === -1) {
        tagsToAdd.push(tag);
      }
      
    });

    tagsToAdd.forEach(tag => {
      if(tag.defaultTags != null){
        const indexNew = newAddedTags.findIndex(object => object.id === tag.id);

   

        tag.defaultTags.forEach(element => {
          const index = tagsToAdd.findIndex(object => object.id === element.id);
          //ADD ONLY WHEN THE TAG IS ADDED FOR FIRST TIME SO THAT IT WILL DELETE THE GENERATED TAGS 
          if (index === -1 && indexNew !== -1) {
            tagsToAdd.push(element);
          }
        });
      }
    });

    // var newTags = tags.map((value)=> value);

    // tagsToAdd.forEach(tagToAdd => {
    //   var exists = false;
    //   newTags.forEach(newTag => {
  
    //       if (tagToAdd.id == newTag.id) {
    //         exists = true;
    //       }
    //   });
    //   if (!exists) {
    //     newTags.push(tagToAdd)
    //   }
       
    // });

     setTags(tagsToAdd);
  }

  const handleTagsInput = (value) => {
    setSerachTagKeyword(value);
  }

  const handleOriginalDateChange = (value,type) => {
    if (type == "day"){
      if (value <= 31){
       setOriginalDateItemDay(parseInt(value,0));
      } else {
        setOriginalDateItemDay(31)
      }
    } 
    if (type == "month"){
      if (value <= 12){
        setOriginalDateItemMonth(parseInt(value,0));
      }else {
        setOriginalDateItemMonth(12)
      }
    } 
    if (type == "year"){
      setOriginalDateItemYear(parseInt(value,0));
    }
  }

  const handleSourceChange = (e,selectedValue) => {
    if (selectedValue == null){
      setSource("");
    } else {
      setSource(selectedValue);
    }
  }

  //UI
  return (
    <div>
      <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="title"
              label={trans.post_title}
              value={title}
              type="text"
              onChange={(e)=> {setTitle(e.target.value)}}
              fullWidth
              variant="standard" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="desc"
              inputRef={descRef}
              label={trans.desc_field}
              value={desc}
              type="text"
              onChange={(e)=> {setDesc(e.target.value)}}
              fullWidth
              multiline
              variant="standard" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="web_url"
              label={trans.web_site_field}
              value={web_url}
              type="text"
              onChange={(e)=> {setWebUrl(e.target.value)}}
              fullWidth
              multiline
              variant="standard" />
          </Grid>

          {source.id == SOURCE_TWITTER_ID ? <Grid item xs={12}>
            <TextField
              margin="dense"
              id="social_id"
              label={social_id_hint}
              value={social_id}
              type="text"
              onChange={(e)=> {setSocialId(e.target.value)}}
              fullWidth
              multiline
              variant="standard" />
          </Grid> : null}

         
      </Grid>
        <br/>
        <br/>
        <Grid container spacing={1}>
            <Grid item xs={12} >
             <Autocomplete
                      fullWidth
                      id="select_sources"
                      options={sourcesArr}
                      value={source}
                      onChange={(e,value) => handleSourceChange(e,value)}
                      getOptionLabel={(option) => option.name || ""}
                    
                      isOptionEqualToValue={(option, value) => option.id == value.id}
                      renderOption={(props, option, { selected }) => (
                        <li  {...props} key={option.id}>
                          <img
                              loading="lazy"
                              width="15"
                              src={`${option.icon_url}`}
                              alt=""
                            />
                            &nbsp;
                          {option.name}
                        </li>
                      )}
                      
                      renderInput={(params) => (
                        <TextField {...params} label={trans.sources_label} placeholder={trans.add_post_source}  />
                      )}
                    />
              {/* <FormControl fullWidth>
                  <InputLabel id="source_id">{trans.add_post_source}</InputLabel>
                  <Select
                          labelId="source_id"
                          id="source"
                          value={source}
                          label={trans.add_post_source}
                          onChange={(e) => setSource(e.target.value)}>
                          {sourcesArr.map((item,index)=> {
                            return ( <MenuItem value={item.id}>{item.name}</MenuItem>)
                          })}  
                  </Select>
              </FormControl> */}
            </Grid>
        </Grid>
        <br/>
        <br/>
        <Grid container spacing={1}>
            <Grid item xs={12} >
            <Autocomplete
                  multiple
                  fullWidth
                  id="checkboxes-categories"
                  options={categoriesArr}
                  disableCloseOnSelect
                  value={categories}
                  onChange={(e,value) => handleCategoriesChange(e,value)}
                  getOptionLabel={(option) => option.name}
                
                  isOptionEqualToValue={(option, value) => option.id == value.id}
                  renderOption={(props, option, { selected }) => (
                    <li  {...props} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  
                  renderInput={(params) => (
                    <TextField {...params} label={trans.categories_label} placeholder={trans.add_post_category}  />
                  )}
                />
            </Grid>
        </Grid>
        <br/>
        <br/>
        <Grid container spacing={1}>
            <Grid item xs={12} >
            <Autocomplete
                  multiple
                  freeSolo
                  fullWidth
                  id="checkboxes-tags"
                  options={tagsArr}
                  disableCloseOnSelect
                  value={tags}
                  onChange={(e,value) => handleTagsChange(e,value)}
                  getOptionLabel={(option) => option.name}
                  onInputChange={(event, newInputValue) => {
                    handleTagsInput(newInputValue);
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
            
                    if (params.inputValue !== "") {
                      filtered.push({
                        freeSolo: true,
                        inputValue: params.inputValue,
                        id: `new_${params.inputValue}`,
                        name: `Add "${params.inputValue}"`
                      });
                    }
            
                    return filtered;
                  }}
                  isOptionEqualToValue={(option, value) => option.id == value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  
                  renderInput={(params) => (
                    <TextField {...params} label={trans.tags_label} placeholder={trans.add_post_tag} />
                  )}
                />
            </Grid>
        </Grid>
        <br/>
        <br/>
        <Grid container spacing={1}>
            <Grid item xs={12} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.visible_field}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={visible}
                          label={trans.visible_field}
                          onChange={(e) => setVisible(e.target.value)}>
                          <MenuItem value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
        </Grid>
        <br/>
        <br/>
        <div>
        <span>{trans.add_post_original_date}</span>
        <Grid container spacing={1}>
          <Grid item xs={4}>
              <TextField
                margin="dense"
                id="day_field"
                label={trans.day_field}
                value={original_date_item_day}
                type="number"
                onChange={(e)=> {handleOriginalDateChange(e.target.value,"day")}}
                fullWidth
                variant="standard" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                id="month_field"
                label={trans.month_field}
                value={original_date_item_month}
                type="number"
                onChange={(e)=> {handleOriginalDateChange(e.target.value,"month")}}
                fullWidth
                variant="standard" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                id="year_field"
                label={trans.year_field}
                value={original_date_item_year}
                type="number"
                onChange={(e)=> {handleOriginalDateChange(e.target.value,"year")}}
                fullWidth
                variant="standard" />
            </Grid>
        </Grid>
        </div>
        <br/>
        <br/>
        <Grid container spacing={1}>
         <Grid item xs={6}>
            <DesktopDatePicker
              fullWidth
              label={trans.add_post_last_shared_on_twitter}
              value={last_shared_on_twitter_at}
              onChange={(newValue)=> {
                // Get the user's timezone offset in minutes
                const timezoneOffset = newValue.getTimezoneOffset();
           
                // Convert the offset to milliseconds and adjust the date
                const adjustedDate = new Date(newValue.getTime() - timezoneOffset * 60000);
              
                // Set the adjusted date
                setLastSharedOnTwitterAt(adjustedDate);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={6}>
            <DesktopDatePicker
              fullWidth
              label={trans.add_post_last_shared_on_instagram}
              value={last_shared_on_instagram_at}
              onChange={(newValue)=> {
                // Get the user's timezone offset in minutes
                const timezoneOffset = newValue.getTimezoneOffset();
           
                // Convert the offset to milliseconds and adjust the date
                const adjustedDate = new Date(newValue.getTime() - timezoneOffset * 60000);
              
                // Set the adjusted date
                setLastSharedOnInstagramAt(adjustedDate);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={6}>
            <DesktopDatePicker
              fullWidth
              label={trans.add_post_last_shared_on_facebook}
             
              value={last_shared_on_facebook_at}
              onChange={(newValue)=> setLastSharedOnFacebookAt(newValue)}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={6}>
            <DesktopDatePicker
              fullWidth
              label={trans.add_post_last_shared_on_linkedin}
             
              value={last_shared_on_linkedin_at}
              onChange={(newValue)=> setLastSharedOnLinkedinAt(newValue)}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
        </Grid>
       
    </div>
  );
}

export default AddPost;
