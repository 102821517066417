import { API_URL } from '../../constants';
import axios from '../../helpers/Api'


export async function getUnreadContacts(postdata) {
  try {
    const response = await axios.post('/contact/unread-contacts', postdata);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function changeAllReadContacts(postdata) {
  try {
    const response = await axios.post('/contact/set-all-read', postdata);
    return response.data;
  } catch (error) {
    throw error;
  }
}