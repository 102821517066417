import './cars.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_COMPANY_ADD, POST_COMPANY_EDIT, POST_VEHICLE_EDIT } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postCompanyAdd, postCompaniesAll, postCompanyEdit, } from '../../redux/insuranceCompany/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import EditUserStatus from './EditCarItem';
import TablePagination from '@mui/material/TablePagination';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import { getLocalDate } from '../../helpers/General';
import { postVehicleEdit, postVehiclesAll } from '../../redux/vehicles/action';

const CarsPage = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const vehiclesData = useSelector(({vehicles}) => vehicles.vehiclesData);
  const editVehicleData = useSelector(({vehicles}) => vehicles.editVehicleData);

  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [carsArr,setCarsArr] = useState([]);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [addModal, setAddModal] = useState(false);
  
  const [toGetData, setToGetData] = useState(false);
 
  const [toClearData, setToClearData] = useState(false);

  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"created_at": -1});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [bannerTypeSelect, setSearchTypeSelect] = useState(0);
  const [accountStatusSelect, setAccountStatusSelect] = useState("none");

  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
  }, []);

  useEffect(() => { //GOT DATA
    if (vehiclesData != null){
      setCarsArr(vehiclesData.userVehicles);
      setTotalData(vehiclesData.total);
      setLoadedFirstTime(true);
    }
  }, [vehiclesData]);

  useEffect(() => { //EDIT DATA
    if (editVehicleData != null){
      if (editVehicleData.status) {
        toast.dismiss();
        toast.success(editVehicleData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(editVehicleData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_VEHICLE_EDIT, payload: null});
    }
  }, [editVehicleData]);

  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchKeyword != null && loadedFirstTime){
      reloadData();
    }
  }, [searchKeyword]);
  
  useEffect(() => { 
    if (bannerTypeSelect != null && bannerTypeSelect && bannerTypeSelect != 0){
      reloadData();
    }
  }, [bannerTypeSelect]);

  useEffect(() => { 
    if (accountStatusSelect != null && accountStatusSelect && accountStatusSelect != "none"){
      reloadData();
    }
  }, [accountStatusSelect]);
  
  

  //FUNCTIONS
  const getData = () => {
    let postData = {
      fromApp: false,
      page:page + 1,
      limit: rowsPerPage,
      sort,
      hasPaginate: true,
    };

    dispatch(postVehiclesAll(postData)); 
  }

  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const onAddNew = (e) => {
    e.preventDefault();
    setDialogTitle(trans.add_banner_title)
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onDialogSubmit = () => {
    setToGetData(true);
  }

  const onSubmitData = (data) => {
    let postData = data;
    console.log(data);
    console.log(editItem);
    if (editItem != null){
      postData.itemId = editItem.id;
      setEditItem(null);
      dispatch(postVehicleEdit(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } 
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, settingsData.settings.page_limit));
  };

  const createSortHandler = (property) => {
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);


    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };

  


  const onViewRow = (e,item) => {
    e.preventDefault();
    setToClearData(false);
    setDialogTitle(trans.edit_banner_title)
    setEditItem(item);
    setAddModal(true);
  }



  const searchChanged = (value) => {
    setSearchKeyword(value);
  }

  //UI
  const columnData = [
    {id: "img", align: true, disablePadding: false, label:  trans.table_head_image},
    {id: "brand", align: true, disablePadding: false, label: trans.table_head_brand},
    {id: "model", align: true, disablePadding: false, label: trans.table_head_model},
    {id: "year", align: true, disablePadding: false, label: trans.table_head_year},
    {id: "plate_num", align: true, disablePadding: false, label: trans.table_head_plate_num},
    {id: "fuel_type", align: true, disablePadding: false, label: trans.table_head_fuel_type},
    {id: "costPerUnit", align: true, disablePadding: false, label: trans.table_head_costPerUnit},
    {id: "ownerId", align: true, disablePadding: false, label: trans.table_head_owner}
  ];

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_cars}</CustomTitle>
          {/* <Button startIcon={<QueueIcon />} size="small" color="secondary" onClick={(e) => onAddNew(e)}  variant="outlined">{trans.button_add_new}</Button> */}
        </div>
        <br/>
      
        <div>
         <br/>
          <Table size="small" style={{display:'block', overflowX:'auto'}}>
            <TableHead>
                <TableRow>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? "none" : "normal"}
                    >
                      <Tooltip
                        title="Sort"
                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={order}
                          onClick={() => createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                })}
                <TableCell/>
                <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {carsArr.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.img_thumb_url == "#" ? null : <img width="70" height="70" style={{borderRadius: '50%',objectFit: "cover"}} className='_companies_img'  src={row.img_thumb_url}></img>}</TableCell>
                    <TableCell>{<div style={{display: 'flex',flexDirection: 'row',justifyItems: 'center',alignItems: 'center'}}><img src={row.brand_logo} height='15'></img>  <div style={{marginLeft:'4px'}}>{row.brand}</div></div>}</TableCell>
                    <TableCell>{row.model}</TableCell>
                    <TableCell>{getLocalDate(row.year * 1000)}</TableCell>
                    <TableCell>{row.plate_num}</TableCell>
                    <TableCell>{row.fuel_type}</TableCell>
                    <TableCell>{row.costPerUnit}</TableCell>
                    <TableCell>{row.ownerId != null ? <a href={`https://adminapp.insurancemotors.eu/app/users/${row.ownerId.id}`}>{row.ownerId.fullName}</a> : "N/A"}</TableCell>
                    <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onViewRow(e,row)}  variant="outlined">{trans.view_item_title}</Button></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          
           </Table>
           <TablePagination
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
         </div>
      </Paper>
    </Grid>
  </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${editItem == null ? trans.add_company_title : trans.edit_company_title }`}
        body={<EditUserStatus toGetData={toGetData} toClearData={toClearData} onSubmitData={onSubmitData} editItem={editItem}/> }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />            

</Container>);
}

export default CarsPage;