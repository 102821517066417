import {
  POST_SIGN_IN,
  POST_SIGN_OUT,
  POST_USER_INFO,
  POST_CREATE_ACCOUNTS,
  POST_ACCOUNT_CONTACT_OTPION_ADD,
  POST_ACCOUNT_CONTACT_OTPION_DELETE,
  POST_USER_SINGLE,
  POST_USER_CHANGE_PASSWORD,
  POST_USER_CHANGE_ACCESS
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  signInData: null,
  logoutData: null,
  userInfoData: null,
  createAccountsData: null,
  addContactOptionData: null,
  deleteContactOptionData: null,
  userSingleData: null,
  changePasswordData: null,
  changeUserAccessData: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_SIGN_IN:
                   return {
                     ...state,
                     signInData: action.payload
          }
          case POST_SIGN_OUT:
            return {
              ...state,
              logoutData: action.payload
          }
          case POST_USER_INFO:
            return {
              ...state,
              userInfoData: action.payload
          }
          case POST_USER_SINGLE:
            return {
              ...state,
              userSingleData: action.payload
          }
          case POST_CREATE_ACCOUNTS:
            return {
              ...state,
              createAccountsData: action.payload
          }
          case POST_ACCOUNT_CONTACT_OTPION_ADD:
            return {
              ...state,
              addContactOptionData: action.payload
          }
          case POST_ACCOUNT_CONTACT_OTPION_DELETE:
            return {
              ...state,
              deleteContactOptionData: action.payload
          }
          case POST_USER_CHANGE_PASSWORD:
            return {
              ...state,
              changePasswordData: action.payload
            }
            case POST_USER_CHANGE_ACCESS:
              return {
                ...state,
                changeUserAccessData: action.payload
              }
            
        default:
              return state;
        }
};
      
export default reducer;