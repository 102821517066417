import {
  POST_INSURANCE_COMPANY_ALL,
  POST_INSURANCE_COMPANY_ADD,
  POST_INSURANCE_COMPANY_EDIT,
  POST_INSURANCE_COMPANY_CHANGE_ORDER
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  insuranceCompaniesData: null,
  addInsuranceCompanyData: null,
  editInsuranceCompanyData: null,
  changeOrderInsuranceCompanyData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_INSURANCE_COMPANY_ALL:
               return {
                ...state,
                insuranceCompaniesData: action.payload
          }
          case POST_INSURANCE_COMPANY_ADD:
            return {
              ...state,
              addInsuranceCompanyData: action.payload
          }
          case POST_INSURANCE_COMPANY_EDIT:
            return {
              ...state,
              editInsuranceCompanyData: action.payload
          }
         case POST_INSURANCE_COMPANY_CHANGE_ORDER:
          return {
            ...state,
            changeOrderInsuranceCompanyData: action.payload
        }
        default:
              return state;
        }
};
      
export default reducer;