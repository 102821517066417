import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS, editorConfig } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getLocalDate, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannersEdit } from '../../redux/banners/action';
import { POST_BANNER_EDIT, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import JoditEditor from "jodit-react";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditInsuranceCompanyItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
    const uploadImageData = useSelector(({upload}) => upload.uploadImageData);

 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [title,setTitle] = useState("");
    const [img,setImg] = useState("");
    const [img_thumb,setImgThumb] = useState("");
    const [web_link,setWebLink] = useState("");
    const [site_link, setSiteLink] = useState("");
    const [status,setStatus] = useState(true);
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [currentImage, setCurrentImage] = useState({url: "", file: null});
    const [descriptions,setDescriptions] = useState([]);
    const [descEl,setDescEl] = useState("");

    //REFS
    const fileInputRef=useRef();
    const editorDescEl = useRef(null);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

          if (currentImage.file != null){
            uploadImageFile();
          } else {
            props.onSubmitData(postData);
          }
        
          
      }
    }, [props.toGetData]);

    const makePostItem = () => {

      var newDescriptions = [];

      newDescriptions.push({value: descEl,lang: "el"});

      let postData = {
        "title": title,
        "web_link": web_link,
        "site_link": site_link,
        "email": email,
        "phone":phone,
        "status": status,
        "descriptions": newDescriptions
      };

      if (uploadImageData != null){
        postData.img = uploadImageData.img;
        postData.img_thumb = uploadImageData.img_thumb;
      }

      return postData;
    }

    const uploadImageFile = () => {
      if (currentImage.file != null){
        var formData = new FormData();
        formData.append('path', "insurancecompanies");
        formData.append("image_file", currentImage.file);
        dispatch(postUploadImage(formData))
      }
    }


    useEffect(() => {
      if (editItem != null){
          setTitle(editItem.title);
          setImg(editItem.img_url);
          setImgThumb(editItem.img_thumb_url);
          setWebLink(editItem.web_link);
          setSiteLink(editItem.site_link);
          setEmail(editItem.email);
          setPhone(editItem.phone);
          setStatus(editItem.status);

          if (editItem.descriptions != null && editItem.descriptions.length > 0){
            const found = editItem.descriptions.find(element => element.lang = "el");
            if (found != null){
              setDescEl(found.value);
            }
          }
      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => { 
     // console.log("uploadImageData",uploadImageData);
      if (uploadImageData != null){
        if (uploadImageData.status) {
          toast.dismiss();
          toast.success(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
             
              let postData = makePostItem();

              if (editItem != null){
                postData.itemId = editItem.id;
              }

              props.onSubmitData(postData);
            
        } else {
          toast.dismiss();
          toast.error(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_UPLOAD_IMAGE, payload: null});
      }
    }, [uploadImageData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    //FUNCTIONS
    const clearData = () => {
        setTitle("");
        setStatus(true);
        setWebLink("");
        setSiteLink("");
        setEmail("");
        setPhone("");
        setImg("");
        setImgThumb("");
        setWebLink("");
        setDescEl("");
        setDescriptions([]);
    }


    const updateIconImageFile = (event) => {
      if (event.target.files.length != 0 ){
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
        let file = event.target.files[0];
  
        let reader = new FileReader();
        reader.onloadend = () => {
          setCurrentImage({url:reader.result, file: file});
        }
        reader.readAsDataURL(file);
      }
    }


    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

      

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="title"
                    label={trans.table_head_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
                <JoditEditor
                          ref={editorDescEl}
                           value={descEl}
                          config={editorConfig}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setDescEl(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="widht"
                    label={trans.table_head_email}
                    value={email}
                    type="text"
                    onChange={(e)=> {setEmail(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="height"
                    label={trans.table_head_phone}
                    value={phone}
                    type="text"
                    onChange={(e)=> {setPhone(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="web_link"
                    label={trans.table_head_web_link}
                    value={web_link}
                    type="text"
                    onChange={(e)=> {setWebLink(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>


            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="site_link"
                    label={trans.table_head_site_link}
                    value={site_link}
                    type="text"
                    onChange={(e)=> {setSiteLink(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>
         
         
            <Grid item xs={12} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_visible}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.table_head_visible}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`menu_item_visible`} value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

      
          

          

          

            <Grid item xs={12}>
                  <span>{trans.upload_company_img_field}</span>
                  <div style={{marginTop:'15px'}}>
                    <Button color="success" variant="outlined" onClick={()=>fileInputRef.current.click()} >{trans.choose_file}</Button>
                    <input ref={fileInputRef} id="fileupload" type="file" name="file" onChange={(e) => updateIconImageFile(e)}  multiple style={{display:'none'}}/>
                  </div>
            </Grid>

            
            <Grid item xs={12} >
              {currentImage.url == "" ? 
              <img style={{maxHeight:'550px',maxWidth:'100%'}} src={img}></img>
              : <img style={{maxHeight:'550px',maxWidth:'100%'}} src={currentImage.url}></img>}
                  
              </Grid>

        </Grid>
      </div>
    );
}

export default EditInsuranceCompanyItem;
