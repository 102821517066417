import './chatRoomsPage.css';
import {useDispatch, useSelector} from "react-redux";
import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, TableSortLabel, TablePagination, Button, CircularProgress, List, ListItem, ListItemText, Avatar } from "@mui/material";
import { getChatRooms } from "./chatApi";
import * as Store from '../../helpers/Store';
import { USER_ID_SET } from "../../redux/actionTypes";
import { useNavigate } from 'react-router-dom';
import PopupChat from './PopupChat';

const ChatRoomsPage = () => {

    const settingsData = useSelector(({settings}) => settings.settingsData);

    const trans = settingsData.trans;

    const currentUserId = Store.getData(USER_ID_SET);
    const [chatRoomsList, setChatRoomsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const navigate = useNavigate();

    const [isPopupChatOpen, setIsPopupChatOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [selectedFullName, setSelectedFullName] = useState("");

    useEffect(() => {
        fetchData(true);
    }, [page]);

    const fetchData = async (fromRefresh) => {
        try {
            const postData = {
                "page": page,
                "userId": currentUserId
            };
            const response = await getChatRooms(postData);
            if (response && response.rooms) {
                if (fromRefresh) {
                    setChatRoomsList(response.rooms);
                } else {
                    setChatRoomsList(prevRooms => [...prevRooms, ...response.rooms]);
                }
                setTotalData(response.total);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching chat rooms:', error);
            setIsLoading(false);
        }
    };

    const handleChatRoomClick = (fullname,roomItem) => {
        setSelectedFullName(fullname);
        const otherUsers = roomItem.room_users.filter(user => user.id !== currentUserId);
        const otherUserId =  otherUsers[0].id ?? "";
        setSelectedUserId(otherUserId);
        setIsPopupChatOpen(true);
    };

    const renderChatRooms = () => {
        if (isLoading) {
            return <CircularProgress />;
        }

        if (!isLoading && chatRoomsList.length === 0) {
            return (
                <div style={{ textAlign: "center", marginTop: 50 }}>
                    <p>{trans.chat_rooms_none}</p>
                    {/* <Button variant="contained" onClick={startChatWithAdmin}>
                        Start Chat with Admin
                    </Button> */}
                </div>
            );
        }

        return (
            <List>
                {chatRoomsList.map(room => {
                    const otherUsers = room.room_users.filter(user => user.id !== currentUserId);
                    const roomTitle = room.title || otherUsers.map(user => user.fullname).join(", ");
                    const lastMessage = room.last_message?.text || "No messages yet";
                    const lastMessageTime = new Date(room.last_message?.timestamp).toLocaleString();

                    return (
                        <ListItem key={room.id} button onClick={() => handleChatRoomClick(roomTitle,room)}>
                            <Avatar src={otherUsers[0]?.img_thumb_url || ""} />
                            <ListItemText
                            style={{marginLeft:'16px'}}
                                primary={roomTitle}
                                secondary={
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>{lastMessage}</span>
                                        <span>{lastMessageTime}</span>
                                    </div>
                                }
                            />
                        </ListItem>
                    );
                })}
            </List>
        );
    };


    const handleClosePopupChat = () => {
        setIsPopupChatOpen(false);
    };



    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <h2>{trans.menu_chats}</h2>
                        {renderChatRooms()}
                    </Paper>
                </Grid>
            </Grid>

            <PopupChat isOpen={isPopupChatOpen} onClose={handleClosePopupChat} userId={selectedUserId} fullname={selectedFullName}/>

        </Container>
    );
};

export default ChatRoomsPage;
