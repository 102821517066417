import {
  POST_CATEGORIES_ALL,
  POST_CATEGORIES_ADD,
  POST_CATEGORIES_EDIT,
  POST_CATEGORIES_DELETE
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  categoriesData: null,
  addCategoryData: null,
  editCategoryData: null,
  deleteCategoryData: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_CATEGORIES_ALL:
               return {
                ...state,
               categoriesData: action.payload
          }
          case POST_CATEGORIES_ADD:
            return {
              ...state,
              addCategoryData: action.payload
          }
          case POST_CATEGORIES_EDIT:
            return {
              ...state,
              editCategoryData: action.payload
          }
          case POST_CATEGORIES_DELETE:
            return {
              ...state,
              deleteCategoryData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;