import {
  POST_EXPIRED_USERS,
  POST_DASHBOARD_MAIN
} from '../actionTypes';

const INIT_STATE = {
  dashboardMain: null,
  expiredUsersData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_EXPIRED_USERS:
                   return {
                     ...state,
                     expiredUsersData: action.payload
          }     
          case POST_DASHBOARD_MAIN:
            return {
              ...state,
              dashboardMain: action.payload
   }     
        default:
              return state;
        }
};
      
export default reducer;