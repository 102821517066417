import {
  POST_INSURANCE_TYPE_ALL,
  POST_INSURANCE_TYPE_ADD,
  POST_INSURANCE_TYPE_EDIT,
  POST_INSURANCE_TYPE_ADD_CATEGORY,
  POST_INSURANCE_TYPE_EDIT_CATEGORY,
  POST_INSURANCE_TYPE_SINGLE,
  POST_INSURANCE_TYPE_ADD_CATEGORY_PRICE,
  POST_INSURANCE_TYPE_EDIT_CATEGORY_PRICE,
  POST_INSURANCE_TYPE_ADD_CATEGORY_FAQ,
  POST_INSURANCE_TYPE_EDIT_CATEGORY_FAQ,
  POST_INSURANCE_TYPE_DELETE_CATEGORY_PRICE,
  POST_INSURANCE_TYPE_DELETE_CATEGORY_FAQ,
  POST_INSURANCE_TYPE_CHANGE_FAQ_ORDER,
  POST_INSURANCE_TYPE_CHANGE_PRICE_ORDER,
  POST_INSURANCE_TYPE_ADD_CATEGORY_PACK,
  POST_INSURANCE_TYPE_EDIT_CATEGORY_PACK,
  POST_INSURANCE_TYPE_DELETE_CATEGORY_PACK,
  POST_INSURANCE_TYPE_REORDER_CATEGORY_PACK,
  POST_INSURANCE_TYPE_ADD_TAB,
  POST_INSURANCE_TYPE_EDIT_TAB,
  POST_INSURANCE_TYPE_DELETE_TAB,
  POST_INSURANCE_TYPE_REORDER_TAB
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  insuranceTypesData: null,
  addInsuranceTypeData: null,
  editInsuranceTypeData: null,
  addInsuranceTypeCategoryData: null,
  editInsuranceTypeCategoryData: null,
  singleInsuranceTypeData: null,
  addInsuranceTypeCategoryPriceData: null,
  editInsuranceTypeCategoryPriceData: null,
  addInsuranceTypeCategoryFaqData: null,
  editInsuranceTypeCategoryFaqData: null,
  deleteInsuranceTypeCategoryFaqData: null,
  deleteInsuranceTypeCategoryPriceData: null,
  changeInsuranceTypeCategoryPriceOrderData: null,
  changeInsuranceTypeCategoryFaqOrderData: null,
  addInsuranceTypeCategoryPackData: null,
  editInsuranceTypeCategoryPackData: null,
  deleteInsuranceTypeCategoryPackData: null,
  changeInsuranceTypeCategoryPackOrderData: null,
  addInsuranceTypeTabData: null,
  editInsuranceTypeTabData: null,
  deleteInsuranceTypeTabData: null,
  changeInsuranceTypeTabOrderData: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_INSURANCE_TYPE_ALL:
               return {
                ...state,
                insuranceTypesData: action.payload
          }
          case POST_INSURANCE_TYPE_ADD:
            return {
              ...state,
              addInsuranceTypeData: action.payload
          }
          case POST_INSURANCE_TYPE_EDIT:
            return {
              ...state,
              editInsuranceTypeData: action.payload
          }
          case POST_INSURANCE_TYPE_ADD_CATEGORY:
            return {
              ...state,
              addInsuranceTypeCategoryData: action.payload
          }
          case POST_INSURANCE_TYPE_EDIT_CATEGORY:
            return {
              ...state,
              editInsuranceTypeCategoryData: action.payload
          }
          case POST_INSURANCE_TYPE_SINGLE:
            return {
              ...state,
              singleInsuranceTypeData: action.payload
          }
          case POST_INSURANCE_TYPE_ADD_CATEGORY_PRICE:
            return {
              ...state,
              addInsuranceTypeCategoryPriceData: action.payload
          }
          case POST_INSURANCE_TYPE_EDIT_CATEGORY_PRICE:
            return {
              ...state,
              editInsuranceTypeCategoryPriceData: action.payload
          }
          case POST_INSURANCE_TYPE_ADD_CATEGORY_FAQ:
            return {
              ...state,
              addInsuranceTypeCategoryFaqData: action.payload
          }
          case POST_INSURANCE_TYPE_EDIT_CATEGORY_FAQ:
            return {
              ...state,
              editInsuranceTypeCategoryFaqData: action.payload
          }
          case POST_INSURANCE_TYPE_DELETE_CATEGORY_PRICE:
            return {
              ...state,
              deleteInsuranceTypeCategoryPriceData: action.payload
          }
          case POST_INSURANCE_TYPE_DELETE_CATEGORY_FAQ:
            return {
              ...state,
              deleteInsuranceTypeCategoryFaqData: action.payload
          }
          case POST_INSURANCE_TYPE_CHANGE_PRICE_ORDER:
            return {
              ...state,
              changeInsuranceTypeCategoryPriceOrderData: action.payload
          }
          case POST_INSURANCE_TYPE_CHANGE_FAQ_ORDER:
            return {
              ...state,
              changeInsuranceTypeCategoryFaqOrderData: action.payload
          }
          case POST_INSURANCE_TYPE_ADD_CATEGORY_PACK:
            return {
              ...state,
              addInsuranceTypeCategoryPackData: action.payload
          }
          case POST_INSURANCE_TYPE_EDIT_CATEGORY_PACK:
            return {
              ...state,
              editInsuranceTypeCategoryPackData: action.payload
          }
          case POST_INSURANCE_TYPE_DELETE_CATEGORY_PACK:
            return {
              ...state,
              deleteInsuranceTypeCategoryPackData: action.payload
          }
          case POST_INSURANCE_TYPE_REORDER_CATEGORY_PACK:
            return {
              ...state,
              changeInsuranceTypeCategoryPackOrderData: action.payload
          }
          case POST_INSURANCE_TYPE_ADD_TAB:
            return {
              ...state,
              addInsuranceTypeTabData: action.payload
          }
          case POST_INSURANCE_TYPE_EDIT_TAB:
            return {
              ...state,
              editInsuranceTypeTabData: action.payload
          }
          case POST_INSURANCE_TYPE_DELETE_TAB:
            return {
              ...state,
              deleteInsuranceTypeTabData: action.payload
          }
          case POST_INSURANCE_TYPE_REORDER_TAB:
            return {
              ...state,
              changeInsuranceTypeTabOrderData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;