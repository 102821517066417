import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getLocalDate, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannersEdit } from '../../redux/banners/action';
import { POST_BANNER_EDIT, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditBannerItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [gasPrice,setGasPrice] = useState(0);
    const [dieselPrice,setDieselPrice] = useState(0);
    const [lpgPrice,setLpgPrice] = useState(0);
    const [evPrice,setEvPrice] = useState(0);
    const [gasFixed,setGasFixed] = useState(true);
    const [dieselFixed,setDieselFixed] = useState(true);
    const [lpgFixed,setLpgFixed] = useState(true);
    const [evFixed,setEvFixed] = useState(true);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

          props.onSubmitData(postData);

      }
    }, [props.toGetData]);

    const makePostItem = () => {
      let postData = {
        "gasPrice": gasPrice,
        "dieselPrice": dieselPrice,
        "lpgPrice": lpgPrice,
        "evPrice": evPrice,
        "gasFixed":gasFixed,
        "dieselFixed": dieselFixed,
        "lpgFixed": lpgFixed,
        "evFixed": evFixed
      };

      return postData;
    }

    useEffect(() => {
      if (editItem != null){
          setGasPrice(editItem.gasPrice);
          setDieselPrice(editItem.dieselPrice);
          setLpgPrice(editItem.lpgPrice);
          setEvPrice(editItem.evPrice);
          setGasFixed(editItem.gasFixed);
          setDieselFixed(editItem.dieselFixed);
          setLpgFixed(editItem.lpgFixed);
          setEvFixed(editItem.evFixed);
      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    //FUNCTIONS
    const clearData = () => {
      setGasPrice(0);
      setDieselPrice(0);
      setLpgPrice(0);
      setEvPrice(0);
      setGasFixed(false);
      setDieselFixed(false);
      setLpgFixed(false);
      setEvFixed(false);
    }

    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

        {editItem == null ? null :
           <Grid item xs={12}>
              <div className='_flex_row'>
                <img width="40" className='_countries_img' alt={`country_${editItem.name}_img`} src={editItem.icon}></img>
                <div style={{width: '10px'}}></div>
                <div style={{fontWeight: 'bold'}}>{editItem.name}</div>
              </div>
           </Grid>}

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="gasPrice"
                    label={trans.table_head_gasoline}
                    value={gasPrice}
                    type="number"
                    onChange={(e)=> {setGasPrice(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>


            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="gasFixed">{trans.table_fuel_fixed_price}</InputLabel>
                  <Select
                          labelId="gasFixed"
                          id="gasFixed"
                          value={gasFixed}
                          label={trans.table_fuel_fixed_price}
                          onChange={(e) => setGasFixed(e.target.value)}>
                          <MenuItem key={`menu_item_status_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`menu_item_status_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="dieselPrice"
                    label={trans.table_head_diesel}
                    value={dieselPrice}
                    type="number"
                    onChange={(e)=> {setDieselPrice(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>


            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="dieselFixed">{trans.table_fuel_fixed_price}</InputLabel>
                  <Select
                          labelId="dieselFixed"
                          id="dieselFixed"
                          value={dieselFixed}
                          label={trans.table_fuel_fixed_price}
                          onChange={(e) => setDieselFixed(e.target.value)}>
                          <MenuItem key={`menu_item_status_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`menu_item_status_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="lpgPrice"
                    label={trans.table_head_lpg}
                    value={lpgPrice}
                    type="number"
                    onChange={(e)=> {setLpgPrice(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>


            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="lpgFixed">{trans.table_fuel_fixed_price}</InputLabel>
                  <Select
                          labelId="lpgFixed"
                          id="lpgFixed"
                          value={lpgFixed}
                          label={trans.table_fuel_fixed_price}
                          onChange={(e) => setLpgFixed(e.target.value)}>
                          <MenuItem key={`menu_item_status_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`menu_item_status_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>


            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="evPrice"
                    label={trans.table_head_ev}
                    value={evPrice}
                    type="number"
                    onChange={(e)=> {setEvPrice(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>


            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="evFixed">{trans.table_fuel_fixed_price}</InputLabel>
                  <Select
                          labelId="evFixed"
                          id="evFixed"
                          value={evFixed}
                          label={trans.table_fuel_fixed_price}
                          onChange={(e) => setEvFixed(e.target.value)}>
                          <MenuItem key={`menu_item_status_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`menu_item_status_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

        </Grid>
      </div>
    );
}

export default EditBannerItem;
