import {
  POST_SOURCES_ALL,
  POST_SOURCES_ADD,
  POST_SOURCES_EDIT,
  POST_SOURCES_DELETE,
  POST_SOURCES_REMOVE_ICON
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
 sourcesData: null,
  addSourceData: null,
  editSourceData: null,
  deleteSourceData: null,
  removeIconSourceData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_SOURCES_ALL:
               return {
                ...state,
              sourcesData: action.payload
          }
          case POST_SOURCES_ADD:
            return {
              ...state,
              addSourceData: action.payload
          }
          case POST_SOURCES_EDIT:
            return {
              ...state,
              editSourceData: action.payload
          }
          case POST_SOURCES_DELETE:
            return {
              ...state,
              deleteSourceData: action.payload
          }
          case POST_SOURCES_REMOVE_ICON:
            return {
              ...state,
              removeIconSourceData: action.payload
          }

        default:
              return state;
        }
};
      
export default reducer;