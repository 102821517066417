import {
  POST_BANNER_ALL,
  POST_BANNER_ADD,
  POST_BANNER_EDIT,
  POST_BANNER_EDIT_ORDER,
  POST_BANNER_DELETE_IMAGE,
  POST_BANNER_SINGLE
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  bannersData: null,
  addBannerData: null,
  editBannerData: null,
  changeBannerImageOrder: null,
  deleteImageBannerData: null,
  singleBannerData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_BANNER_ALL:
               return {
                ...state,
               bannersData: action.payload
          }
          case POST_BANNER_ADD:
            return {
              ...state,
              addBannerData: action.payload
          }
          case POST_BANNER_EDIT:
            return {
              ...state,
              editBannerData: action.payload
          }
          case POST_BANNER_EDIT_ORDER:
            return {
              ...state,
              changeBannerImageOrder: action.payload
          } 
          case POST_BANNER_DELETE_IMAGE:
            return {
              ...state,
              deleteImageBannerData: action.payload
          }
          case POST_BANNER_SINGLE:
                return {
                  ...state,
                  singleBannerData: action.payload
          }
          
        default:
              return state;
        }
};
      
export default reducer;