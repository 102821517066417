import {
  POST_SIGN_IN,
  POST_SIGN_OUT,
  POST_USER_INFO,
  USER_TOKEN_SET,
  USER_ID_SET,
  POST_CREATE_ACCOUNTS,
  POST_USER_SINGLE,
  POST_ACCOUNT_CONTACT_OTPION_ADD,
  POST_ACCOUNT_CONTACT_OTPION_DELETE,
  POST_USER_CHANGE_PASSWORD,
  POST_USER_CHANGE_ACCESS
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
  import * as Const from '../../constants';
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postSignIn = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`account/login`,postData);


  if (response.data != null && response.data.status){
    Store.storeData(USER_TOKEN_SET, response.data.token);
    Store.storeData(USER_ID_SET, response.data.userId);

    //USER IS LOGGED IN. SO WE STORE THE TOKEN HEADR TO AXIOS FOR LATER USE INTO OUR API
    axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.token;
  }

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_SIGN_IN, payload: response.data});
};

    
export const postUserInfo = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/userinfo`,postData);


  // if (General.isLoggedIn){
  //   let userId = Store.getData(USER_ID_SET);
  //   if (response.data != null && response.data.userInfo != null){
  //     if (response.data.userInfo.id == userId){
  //         //THIS IS THE USER THAT IS LOGGED IN DATA. SO WE STORE THEM FOR LATER USE
  //         Store.storeData(USER_DATA, response.data);
  //     }
  //   }
  // }
  

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USER_INFO, payload: response.data});
};


export const postLogout = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.userId = Store.getData(USER_ID_SET);

  const response = await axios.post(`account/logout`,postData);


  if (response.data != null && response.data.status){
    Store.storeData(USER_TOKEN_SET, "");
    Store.storeData(USER_ID_SET, "");
    dispatch({type: POST_USER_INFO, payload: null});
    //USER IS LOGGED IN. SO WE STORE THE TOKEN HEADR TO AXIOS FOR LATER USE INTO OUR API
    axios.defaults.headers.common['Authorization'] = ""
  }

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_SIGN_OUT, payload: response.data});
};

export const postCreateAccounts = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const axiosInstance = axios.create({
    timeout: 120000, // Increase timeout to 20 seconds
  });

  const response = await axiosInstance.post(`account/create-accounts`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CREATE_ACCOUNTS, payload: response.data});
};

export const postEditAccounts = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;




  const response = await axios.post(`account/mass-edit-users`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CREATE_ACCOUNTS, payload: response.data});
};


export const postUserSingle = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/userinfo`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USER_SINGLE, payload: response.data});
};


export const postAddContactOption = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/add-contact-option`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_ACCOUNT_CONTACT_OTPION_ADD, payload: response.data});
};


export const postDeleteContactOption = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/delete-contact-option`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_ACCOUNT_CONTACT_OTPION_DELETE, payload: response.data});
};

export const postChangeUserPassword = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/change-pass`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USER_CHANGE_PASSWORD, payload: response.data});
};

export const postChangeUserAccess = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/edit-user-access`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USER_CHANGE_ACCESS, payload: response.data});
};