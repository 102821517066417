//SETTINGS
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_COUNTRIES = "GET_COUNTRIES";


//USER
export const USER_TOKEN_SET = "USER_TOKEN_SET";
export const USER_ID_SET = "USER_ID_SET";
export const POST_SIGN_IN = "POST_SIGN_IN";
export const POST_SIGN_OUT = "POST_SIGN_OUT";
export const POST_USER_INFO = "POST_USER_INFO";
export const POST_USER_SINGLE = "POST_USER_SINGLE";
export const POST_CREATE_ACCOUNTS = "POST_CREATE_ACCOUNTS";
export const POST_USER_CHANGE_PASSWORD = "POST_USER_CHANGE_PASSWORD";
export const POST_USER_CHANGE_ACCESS = "POST_USER_CHANGE_ACCESS";

//USERS
export const POST_USERS_ALL = "POST_USERS_ALL";
export const POST_USERS_ADD = "POST_USERS_ADD";
export const POST_USERS_EDIT = "POST_USERS_EDIT";
export const POST_USERS_CHANGE_APPROVED = "POST_USERS_CHANGE_APPROVED";
export const POST_USERS_CHANGE_ACCESS_PACKAGES = "POST_USERS_CHANGE_ACCESS_PACKAGES";
export const POST_USERS_CHANGE_COMPANIES = "POST_USERS_CHANGE_COMPANIES";
export const POST_USERS_CHANGE_ROUTES = "POST_USERS_CHANGE_ROUTES";

//CATEGORIES
export const POST_CATEGORIES_ALL = "POST_CATEGORIES_ALL";
export const POST_CATEGORIES_ADD = "POST_CATEGORIES_ADD";
export const POST_CATEGORIES_EDIT = "POST_CATEGORIES_EDIT";
export const POST_CATEGORIES_DELETE = "POST_CATEGORIES_DELETE";

//SOURCES
export const POST_SOURCES_ALL = "POST_SOURCES_ALL";
export const POST_SOURCES_ADD = "POST_SOURCES_ADD";
export const POST_SOURCES_EDIT = "POST_SOURCES_EDIT";
export const POST_SOURCES_DELETE = "POST_SOURCES_DELETE";
export const POST_SOURCES_REMOVE_ICON = "POST_SOURCES_REMOVE_ICON";

//UPLOAD
export const POST_UPLOAD_IMAGE = "POST_UPLOAD_IMAGE";
export const POST_UPLOAD_FILE = "POST_UPLOAD_FILE";

//TAGS
export const POST_TAGS_ALL = "POST_TAGS_ALL";
export const POST_TAGS_ALL_PICK = "POST_TAGS_ALL_PICK";
export const POST_TAGS_ADD = "POST_TAGS_ADD";
export const POST_TAGS_EDIT = "POST_TAGS_EDIT";
export const POST_TAGS_DELETE = "POST_TAGS_DELETE";
export const POST_TAGS_REMOVE_EVERYWHERE = "POST_TAGS_REMOVE_EVERYWHERE";

//POSTS
export const POST_POSTS_ALL = "POST_POSTS_ALL";
export const POST_POSTS_ADD = "POST_POSTS_ADD";
export const POST_POSTS_EDIT = "POST_POSTS_EDIT";
export const POST_POSTS_DELETE = "POST_POSTS_DELETE";

//SOCIAL TEMPLATE
export const POST_SOCIAL_TEMPLATE_ADD = "POST_SOCIAL_TEMPLATE_ADD";
export const POST_SOCIAL_TEMPLATE_ALL = "POST_SOCIAL_TEMPLATE_ALL";
export const POST_SOCIAL_TEMPLATE_EDIT = "POST_SOCIAL_TEMPLATE_EDIT";
export const POST_SOCIAL_TEMPLATE_DELETE = "POST_SOCIAL_TEMPLATE_DELETE";

//BANNERS
export const POST_BANNER_ALL = "POST_BANNER_ALL";
export const POST_BANNER_ADD = "POST_BANNER_ADD";
export const POST_BANNER_EDIT = "POST_BANNER_EDIT";
export const POST_BANNER_DELETE = "POST_BANNER_DELETE";
export const POST_BANNER_EDIT_ORDER = "POST_BANNER_EDIT_ORDER";
export const POST_BANNER_DELETE_IMAGE = "POST_BANNER_DELETE_IMAGE";
export const POST_BANNER_SINGLE = "POST_BANNER_SINGLE";

//NOTIFICATIONS
export const POST_NOTIFICATION_ALL = "POST_NOTIFICATION_ALL";
export const POST_NOTIFICATION_ADD = "POST_NOTIFICATION_ADD";


//BANNERS
export const POST_COUNTRY_ALL = "POST_COUNTRY_ALL";
export const POST_COUNTRY_EDIT_FUEL = "POST_COUNTRY_EDIT_FUEL";

//CONTACT
export const POST_CONTACT_ALL = "POST_CONTACT_ALL";

//INSURANCE COMPANIES
export const POST_INSURANCE_COMPANY_ALL = "POST_INSURANCE_COMPANY_ALL";
export const POST_INSURANCE_COMPANY_ADD = "POST_INSURANCE_COMPANY_ADD";
export const POST_INSURANCE_COMPANY_EDIT = "POST_INSURANCE_COMPANY_EDIT";
export const POST_INSURANCE_COMPANY_DELETE = "POST_INSURANCE_COMPANY_DELETE";
export const POST_INSURANCE_COMPANY_CHANGE_ORDER = "POST_INSURANCE_COMPANY_CHANGE_ORDER";

export const POST_VEHICLE_ALL = "POST_VEHICLE_ALL";
export const POST_VEHICLE_EDIT = "POST_VEHICLE_EDIT";

//ADMIN
export const POST_EXPIRED_USERS = "POST_EXPIRED_USERS";
export const POST_DASHBOARD_MAIN = "POST_DASHBOARD_MAIN";

//INSURANCE TYPES
export const POST_INSURANCE_TYPE_ALL = "POST_INSURANCE_TYPE_ALL";
export const POST_INSURANCE_TYPE_ADD = "POST_INSURANCE_TYPE_ADD";
export const POST_INSURANCE_TYPE_EDIT = "POST_INSURANCE_TYPE_EDIT";
export const POST_INSURANCE_TYPE_DELETE = "POST_INSURANCE_TYPE_DELETE";
export const POST_INSURANCE_TYPE_ADD_CATEGORY = 'POST_INSURANCE_TYPE_ADD_CATEGORY';
export const POST_INSURANCE_TYPE_EDIT_CATEGORY = 'POST_INSURANCE_TYPE_EDIT_CATEGORY';
export const POST_INSURANCE_TYPE_SINGLE = "POST_INSURANCE_TYPE_SINGLE";
export const POST_INSURANCE_TYPE_ADD_CATEGORY_PRICE = "POST_INSURANCE_TYPE_ADD_CATEGORY_PRICE";
export const POST_INSURANCE_TYPE_EDIT_CATEGORY_PRICE = "POST_INSURANCE_TYPE_EDIT_CATEGORY_PRICE";
export const POST_INSURANCE_TYPE_ADD_CATEGORY_FAQ = "POST_INSURANCE_TYPE_ADD_CATEGORY_FAQ";
export const POST_INSURANCE_TYPE_EDIT_CATEGORY_FAQ = "POST_INSURANCE_TYPE_EDIT_CATEGORY_FAQ";
export const POST_INSURANCE_TYPE_DELETE_CATEGORY_PRICE = "POST_INSURANCE_TYPE_ADD_CATEGORY_PRICE";
export const POST_INSURANCE_TYPE_DELETE_CATEGORY_FAQ = "POST_INSURANCE_TYPE_EDIT_CATEGORY_FAQ";
export const POST_INSURANCE_TYPE_CHANGE_PRICE_ORDER = "POST_INSURANCE_TYPE_CHANGE_PRICE_ORDER";
export const POST_INSURANCE_TYPE_CHANGE_FAQ_ORDER = "POST_INSURANCE_TYPE_CHANGE_FAQ_ORDER";
export const POST_INSURANCE_TYPE_ADD_CATEGORY_PACK = "POST_INSURANCE_TYPE_ADD_CATEGORY_PACK";
export const POST_INSURANCE_TYPE_EDIT_CATEGORY_PACK = "POST_INSURANCE_TYPE_EDIT_CATEGORY_PACK";
export const POST_INSURANCE_TYPE_DELETE_CATEGORY_PACK = "POST_INSURANCE_TYPE_DELETE_CATEGORY_PACK";
export const POST_INSURANCE_TYPE_REORDER_CATEGORY_PACK = "POST_INSURANCE_TYPE_REORDER_CATEGORY_PACK";
export const POST_INSURANCE_TYPE_ADD_TAB = "POST_INSURANCE_TYPE_ADD_TAB";
export const POST_INSURANCE_TYPE_EDIT_TAB = "POST_INSURANCE_TYPE_EDIT_TAB";
export const POST_INSURANCE_TYPE_DELETE_TAB = "POST_INSURANCE_TYPE_DELETE_TAB";
export const POST_INSURANCE_TYPE_REORDER_TAB = "POST_INSURANCE_TYPE_REORDER_TAB";

//INSURANCE CONTRACT
export const POST_INSURANCE_CONTRACT_ALL = "POST_INSURANCE_CONTRACT_ALL";
export const POST_INSURANCE_CONTRACT_ADD = "POST_INSURANCE_CONTRACT_ADD";
export const POST_INSURANCE_CONTRACT_EDIT = "POST_INSURANCE_CONTRACT_EDIT";
export const POST_INSURANCE_CONTRACT_ADD_FILE = "POST_INSURANCE_CONTRACT_ADD_FILE";
export const POST_INSURANCE_CONTRACT_EDIT_FILE = "POST_INSURANCE_CONTRACT_EDIT_FILE";
export const POST_INSURANCE_CONTRACT_DELETE_FILE = "POST_INSURANCE_CONTRACT_DELETE_FILE";
export const POST_INSURANCE_CONTRACT_SINGLE = "POST_INSURANCE_CONTRACT_SINGLE";
export const POST_INSURANCE_CONTRACT_REORDER_FILES = "POST_INSURANCE_CONTRACT_REORDER_FILES";

//USER CONTACT OPTION
export const POST_ACCOUNT_CONTACT_OTPION_ADD = "POST_ACCOUNT_CONTACT_OTPION_ADD";
export const POST_ACCOUNT_CONTACT_OTPION_DELETE = "POST_ACCOUNT_CONTACT_OTPION_DELETE";


//INSURANCE OFFERS
export const POST_INSURANCE_OFFER_ALL = "POST_INSURANCE_OFFER_ALL";
export const POST_INSURANCE_OFFER_ADD = "POST_INSURANCE_OFFER_ADD";
export const POST_INSURANCE_OFFER_EDIT = "POST_INSURANCE_OFFER_EDIT";
export const POST_INSURANCE_OFFER_SINGLE = "POST_INSURANCE_OFFER_SINGLE";
export const POST_INSURANCE_OFFER_ADD_FILE = "POST_INSURANCE_OFFER_ADD_FILE";
export const POST_INSURANCE_OFFER_EDIT_FILE = "POST_INSURANCE_OFFER_EDIT_FILE";
export const POST_INSURANCE_OFFER_DELETE_FILE = "POST_INSURANCE_OFFER_DELETE_FILE";
export const POST_INSURANCE_OFFER_REORDER_FILES = "POST_INSURANCE_OFFER_REORDER_FILES";
export const POST_INSURANCE_OFFER_ADD_OFFER_PACK = "POST_INSURANCE_OFFER_ADD_OFFER_PACK";
export const POST_INSURANCE_OFFER_EDIT_OFFER_PACK = "POST_INSURANCE_OFFER_EDIT_OFFER_PACK";
export const POST_INSURANCE_OFFER_DELETE_OFFER_PACK = "POST_INSURANCE_OFFER_DELETE_OFFER_PACK";
export const POST_INSURANCE_OFFER_REORDER_OFFER_PACK = "POST_INSURANCE_OFFER_REORDER_OFFER_PACK";