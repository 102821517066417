import {
  POST_INSURANCE_TYPE_ALL,
  POST_INSURANCE_TYPE_ADD,
  POST_INSURANCE_TYPE_EDIT,
  POST_INSURANCE_TYPE_ADD_CATEGORY,
  POST_INSURANCE_TYPE_EDIT_CATEGORY,
  POST_INSURANCE_TYPE_SINGLE,
  POST_INSURANCE_TYPE_ADD_CATEGORY_PRICE,
  POST_INSURANCE_TYPE_EDIT_CATEGORY_PRICE,
  POST_INSURANCE_TYPE_ADD_CATEGORY_FAQ,
  POST_INSURANCE_TYPE_EDIT_CATEGORY_FAQ,
  POST_INSURANCE_TYPE_DELETE_CATEGORY_PRICE,
  POST_INSURANCE_TYPE_DELETE_CATEGORY_FAQ,
  POST_INSURANCE_TYPE_CHANGE_PRICE_ORDER,
  POST_INSURANCE_TYPE_CHANGE_FAQ_ORDER,
  POST_INSURANCE_TYPE_ADD_CATEGORY_PACK,
  POST_INSURANCE_TYPE_EDIT_CATEGORY_PACK,
  POST_INSURANCE_TYPE_DELETE_CATEGORY_PACK,
  POST_INSURANCE_TYPE_REORDER_CATEGORY_PACK,
  POST_INSURANCE_TYPE_ADD_TAB,
  POST_INSURANCE_TYPE_EDIT_TAB,
  POST_INSURANCE_TYPE_DELETE_TAB,
  POST_INSURANCE_TYPE_REORDER_TAB
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postInsuranceTypesAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/all`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_ALL, payload: response.data});
};

    
export const postInsuranceTypeAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/add`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_ADD, payload: response.data});
};

export const postInsuranceTypeEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_EDIT, payload: response.data});
};


export const postInsuranceTypeAddCategory = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/add-category`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_ADD_CATEGORY, payload: response.data});
};

export const postInsuranceTypeEditCategory = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/edit-category`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_EDIT_CATEGORY, payload: response.data});
};


export const postInsuranceTypeSingle = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/single`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_SINGLE, payload: response.data});
};


export const postInsuranceTypeAddCategoryPrice = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/add-category-price`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_ADD_CATEGORY_PRICE, payload: response.data});
};


export const postInsuranceTypeEditCategoryPrice = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/edit-category-price`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_EDIT_CATEGORY_PRICE, payload: response.data});
};


export const postInsuranceTypeAddCategoryFaq = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/add-category-faq`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_ADD_CATEGORY_FAQ, payload: response.data});
};


export const postInsuranceTypeEditCategoryFaq = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/edit-category-faq`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_EDIT_CATEGORY_FAQ, payload: response.data});
};

export const postInsuranceTypeDeleteCategoryPrice = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/delete-category-price`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_DELETE_CATEGORY_PRICE, payload: response.data});
};


export const postInsuranceTypeDeleteCategoryFaq = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/delete-category-faq`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_DELETE_CATEGORY_FAQ, payload: response.data});
};

export const postInsuranceTypeChangePriceOrder = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/change-price-order`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_CHANGE_PRICE_ORDER, payload: response.data});
};

export const postInsuranceTypeChangeFaqOrder = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/change-faq-order`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_CHANGE_FAQ_ORDER, payload: response.data});
};


export const postInsuranceTypeAddCategoryPack = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/add-category-pack`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_ADD_CATEGORY_PACK, payload: response.data});
};

export const postInsuranceTypeEditCategoryPack = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/edit-category-pack`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_EDIT_CATEGORY_PACK, payload: response.data});
};

export const postInsuranceTypeDeleteCategoryPack = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/delete-category-pack`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_DELETE_CATEGORY_PACK, payload: response.data});
};


export const postInsuranceTypeChangePackOrder = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/change-pack-order`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_REORDER_CATEGORY_PACK, payload: response.data});
};

export const postInsuranceTypeAddTab = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/add-tab`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_ADD_TAB, payload: response.data});
};

export const postInsuranceTypeEditTab = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/edit-tab`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_EDIT_TAB, payload: response.data});
};

export const postInsuranceTypeDeleteTab = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/delete-tab`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_DELETE_TAB, payload: response.data});
};


export const postInsuranceTypeChangeTabOrder = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-type/change-tab-order`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_TYPE_REORDER_TAB, payload: response.data});
};