import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PRIMARY_COLOR, editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import EuroIcon from '@mui/icons-material/Euro';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage, postUploadSingleFile } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { postInsuranceContractAddFile, postInsuranceContractDeleteFile, postInsuranceContractEditFile, postInsuranceContractReorderFiles, postInsuranceContractSingle } from '../../redux/insuranceContract/action';
import FormDialog from '../global/formDialog';
import { POST_INSURANCE_CONTRACT_ADD_FILE, POST_INSURANCE_CONTRACT_DELETE_FILE, POST_INSURANCE_CONTRACT_EDIT_FILE, POST_INSURANCE_CONTRACT_REORDER_FILES, POST_INSURANCE_CONTRACT_SINGLE, POST_UPLOAD_FILE, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SweetAlert from 'sweetalert2';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';
import {  arrayMove } from  'react-sortable-hoc'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const EditInsuranceContractFiles =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
    const uploadFileData = useSelector(({upload}) => upload.uploadFileData);
    const addInsuranceContractFileData  = useSelector(({insuranceContract}) => insuranceContract.addInsuranceContractFileData);
    const editInsuranceContractFileData = useSelector(({insuranceContract}) => insuranceContract.editInsuranceContractFileData);
    const deleteFileInsuranceContractData = useSelector(({insuranceContract}) => insuranceContract.deleteFileInsuranceContractData);
    const singleInsuranceContractData = useSelector(({insuranceContract}) => insuranceContract.singleInsuranceContractData);
    const reorderFilesInsuranceContractData = useSelector(({insuranceContract}) => insuranceContract.reorderFilesInsuranceContractData);
 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [insuranceContractItem,setInsuranceContractItem] = useState(null);
    const [title,setTitle] = useState("");

    const [file_path,setFilePath] = useState("");
    const [status,setStatus] = useState(true);
    

    const [currentFile, setCurrentFile] = useState({url: "", file: null});
    const [isRequesting,setIsRequesting] = useState(false);

    const [selectedLang,setSelectedLang] = useState(settingsData.settings.langs[0]);
 
    const [selectedFile,setSelectedFile] = useState(null);

    const [selectedFileEditItem,setSelectedFileEditItem] = useState(null);

    const [contractFiles, setContractFiles] = useState([]);

    //REFS
    const fileInputRef=useRef();

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
  
    const makePostItem = () => {

      let postData = {
        "title": title,
        "status": status,
      };

      if (uploadFileData != null){
        postData.file_path = uploadFileData.filePath;
        postData.file_name = uploadFileData.fileName;
      }

      return postData;
    }

    const uploadSingleFile = () => {
      if (currentFile.file != null){
        var formData = new FormData();
        formData.append('path', `contracts/${editItem.id}`);
        formData.append("file", currentFile.file);
        dispatch(postUploadSingleFile(formData))
      }
    }

    const saveFileData = (postData) => {
      if (selectedFile == null){
        console.log(postData);
        dispatch(postInsuranceContractAddFile(postData))
      } else {
        postData.fileId = selectedFile.id;
        console.log(postData);
        dispatch(postInsuranceContractEditFile(postData))
      }

    }

    useEffect(() => {
      if (addInsuranceContractFileData != null){
        if (addInsuranceContractFileData.status) {
          toast.dismiss();
          toast.success(addInsuranceContractFileData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onFilesUpdated();

          updateTypeData()

          setIsRequesting(false);

        } else {
          toast.dismiss();
          toast.error(addInsuranceContractFileData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_INSURANCE_CONTRACT_ADD_FILE, payload: null});

      } 
    },[addInsuranceContractFileData]);

    useEffect(() => {
      if (editInsuranceContractFileData != null){
        if (editInsuranceContractFileData.status) {
          toast.dismiss();
          toast.success(editInsuranceContractFileData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onFilesUpdated();

              updateTypeData()

              setIsRequesting(false);
        } else {
          toast.dismiss();
          toast.error(editInsuranceContractFileData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_INSURANCE_CONTRACT_EDIT_FILE, payload: null});

      } 
    },[editInsuranceContractFileData]);

    useEffect(() => {
      if (deleteFileInsuranceContractData != null){
        if (deleteFileInsuranceContractData.status) {
          toast.dismiss();
          toast.success(deleteFileInsuranceContractData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onFilesUpdated();

              updateTypeData()

              setIsRequesting(false);
        } else {
          toast.dismiss();
          toast.error(deleteFileInsuranceContractData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_INSURANCE_CONTRACT_DELETE_FILE, payload: null});

      } 
    },[deleteFileInsuranceContractData]);

    useEffect(() => {
      if (reorderFilesInsuranceContractData != null){
        if (reorderFilesInsuranceContractData.status) {
          toast.dismiss();
          toast.success(reorderFilesInsuranceContractData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onFilesUpdated();

              updateTypeData()

              setIsRequesting(false);
        } else {
          toast.dismiss();
          toast.error(reorderFilesInsuranceContractData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_INSURANCE_CONTRACT_REORDER_FILES, payload: null});

      } 
    },[reorderFilesInsuranceContractData]);

    

    useEffect(() => {
      if (singleInsuranceContractData != null){
    
        setInsuranceContractItem(singleInsuranceContractData)
        setContractFiles(singleInsuranceContractData.files);

        dispatch({type: POST_INSURANCE_CONTRACT_SINGLE, payload: null});
      } 
    },[singleInsuranceContractData]);


    



    useEffect(() => {
      if (editItem != null){

          setInsuranceContractItem(editItem);
          setContractFiles(editItem.files);

      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
      if (selectedFile != null){

          setTitle(selectedFile.title);

          setFilePath(selectedFile.file_path);
          setStatus(selectedFile.status);

          // setLanguageItems();

      } else {
        clearData();
      }
    },[selectedFile]);

    useEffect(() => { 
     // console.log("uploadFileData",uploadFileData);
      if (uploadFileData != null){
        if (uploadFileData.status) {
          toast.dismiss();
          toast.success(uploadFileData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
             
              let postData = makePostItem();

              if (editItem != null){
                postData.itemId = editItem.id;
              }

              saveFileData(postData);
        } else {
          toast.dismiss();
          toast.error(uploadFileData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_UPLOAD_FILE, payload: null});
      }
    }, [uploadFileData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    useEffect(() => {
      if (selectedLang){
        // setLanguageItems();
      }
  }, [selectedLang]);

    //FUNCTIONS
    const clearData = () => {
        setTitle("");
        setStatus(true);
        setFilePath("");
        setCurrentFile({url: "",file: null})
        setSelectedFile(null);
    }

    const updateIconImageFile = (event) => {
      if (event.target.files.length != 0) {
        var mimeType = event.target.files[0].type;
        if (mimeType !== 'application/pdf') {
          return;
        }
        let file = event.target.files[0];
      
        let reader = new FileReader();
        reader.onloadend = () => {
          setCurrentFile({ url: reader.result, file: file });
        };
        reader.readAsDataURL(file);
      }      
    }

   
    const changeLanguage = (lang) => {
      setSelectedLang(lang);
    }

    const columnData = [
      {id: "file_path", align: true, disablePadding: false, label:  trans.table_head_file},
      {id: "file_name", align: true, disablePadding: false, label:  trans.table_head_name},
      {id: "status", align: true, disablePadding: false, label: trans.table_head_status},
    ];
  

    const onEditRow = (e,item) => {
      e.preventDefault();
      setSelectedFile(item);

    }

    const onClean = (e) => {
      e.preventDefault();
      setSelectedFile(null);
    }

    const onSubmit = (e) => {
      e.preventDefault();

      if (isRequesting){
        return;
      }

      setIsRequesting(true);
      
      if (currentFile.file != null || currentFile.url != null){
        let postData = makePostItem();

        if (editItem != null){
          postData.itemId = editItem.id;
        }

        if (currentFile.file != null){
          uploadSingleFile();
        } else {
          saveFileData(postData);
        }
      }
    }


    const updateTypeData = () => {
      dispatch(postInsuranceContractSingle({
        "itemId": editItem.id,
        "langLoc":  selectedLang.value
      }));
      clearData();
    }

    const onDeleteRow = (e,item) => {
      e.preventDefault();
      SweetAlert.fire({
        theme: "dark",
        title:trans.delete_title,
        text:trans.delete_descr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.delete_title,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            deleteItem(item);
        }
      })
    }

    const deleteItem = (item) => {
      dispatch(postInsuranceContractDeleteFile({
        "itemId": editItem.id,
        "fileId": item.id,
        "langLoc": "el"
      }));
    }
 
    const onSortEnd  = ({oldIndex, newIndex}) => {
      const updatedItems = arrayMove(contractFiles, oldIndex, newIndex);
      const updatedItemsWithOrder = updatedItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      setContractFiles(updatedItemsWithOrder);
      dispatch(postInsuranceContractReorderFiles({"contractId": editItem.id,orderedItems: updatedItemsWithOrder}))
    };
    
    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

    {settingsData.settings.langs.length > 1 ? 
          <Grid item xs={12}>

            <LangPicker
                onLangSelectedChanged={changeLanguage}
            />

          </Grid> : null }
      

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="title"
                    label={trans.table_head_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

          
         
            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_visible}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.table_head_visible}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`menu_item_visible`} value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
          

        {selectedFile == null ? 
            <Grid item xs={12}>
              <div className='_flex_row_space'>
                <div>
                  <span>{trans.upload_insurance_contract_file_field}</span>
                      <div style={{marginTop:'15px'}}>
                        <Button color="success" variant="outlined" onClick={()=>fileInputRef.current.click()} >{trans.choose_file}</Button>
                        <input ref={fileInputRef} id="fileupload" type="file" name="file" onChange={(e) => updateIconImageFile(e)}  multiple style={{display:'none'}}/>
                  </div>
                </div>
                <div>
                    {currentFile.url == "" ? 
                     <div>{file_path}</div>
                  : <div><strong>{currentFile.file.name}</strong></div> }
                      
                </div>
              </div>
                 
            </Grid> : null}

            <Grid container justifyContent="center" >
              <Grid item>
                {selectedFile != null ?  
                <Button style={{marginTop:'10px', marginBottom:'10px',backgroundColor: 'green'}}  variant="contained"  disabled={props.onSubmitLoading} onClick={onClean}>{trans.cancel_btn}</Button>
                : null }
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={(selectedFile == null && currentFile.file == null) || isRequesting == true}  onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
            </Grid>
            
            <Grid item xs={12} >

              <div className='_insurance_files_container'>
                  {insuranceContractItem != null ? 
                  <Table size="small" style={{overflowX:'auto'}}>
                    <TableHead>
                  
                        <TableRow>
                        <TableCell/>
                        {columnData.map(column => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric}
                              padding={column.disablePadding ? "none" : "normal"}
                            >
                                {column.label}
                            </TableCell>
                          );
                        })}
                        <TableCell/>
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBodySortable onSortEnd={(sortEnd) => onSortEnd(sortEnd)} useDragHandle>
                        {contractFiles.map((row,index) => (
                          <SortableRow index={index} key={row.id}>
                            <TableCell><DragHandler /></TableCell> 
                            <TableCell>{<a target='_blank' href={row.file_url}>{row.file_name}</a>}</TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{`${trans[`status_${row.status}`]}`}</TableCell>
                            <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onEditRow(e,row)}  variant="outlined">{trans.edit_item_title}</Button></TableCell>
                            <TableCell>
                              <Button
                                startIcon={<DeleteOutlineIcon />}
                                size="small"
                                onClick={(e) => onDeleteRow(e, row)}
                                variant="outlined"
                              >
                                {trans.table_delete_button}
                              </Button>
                            </TableCell>
                            </SortableRow>
                        ))}
                      </TableBodySortable>
                  
                  </Table> : null }

              </div>

            </Grid>

        </Grid>

        

      </div>
    );
}

export default EditInsuranceContractFiles;
