import {
  POST_VEHICLE_ALL,
  POST_VEHICLE_EDIT
} from '../actionTypes';

const INIT_STATE = {
  vehiclesData: null,
  editVehicleData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_VEHICLE_ALL:
               return {
                ...state,
                vehiclesData: action.payload
          }
          case POST_VEHICLE_EDIT:
            return {
              ...state,
              editVehicleData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;