import React, { useState,useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { WEB_SITE_NAME, WEB_SITE_URL } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { postSignIn, postUserInfo } from '../../redux/auth/action';
import { toast } from 'react-toastify';
import { POST_SIGN_IN } from '../../redux/actionTypes';
import {logo_red_large} from '../../images';

const SignIn = () => {

const dispatch = useDispatch();
const settingsData = useSelector(({settings}) => settings.settingsData);
const signInData = useSelector(({auth}) => auth.signInData);
const userInfoData = useSelector(({auth}) => auth.userInfoData);
const [loading,setLoading] = useState(false);

const trans = settingsData.trans;



useEffect(() => {
    if (signInData != null) {
        if (signInData.status) {
            getUserData()
        } else {
            setLoading(false);
            toast.dismiss();
            toast.error(signInData.message, {
                position: toast.POSITION.TOP_RIGHT
                });
        }
        dispatch({type: POST_SIGN_IN, payload: null});
    } 
}, [signInData]);

useEffect(() => {
    if (userInfoData != null) {
        if (userInfoData.id == ""){
            setLoading(false);
            return;
        }
        window.location.reload();
    } 
}, [userInfoData]);

const getUserData = () => {
    if (signInData != null){
        let postData = {userId: signInData.userId};
        dispatch(postUserInfo(postData))
    }
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={WEB_SITE_URL}>
        {WEB_SITE_NAME}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // // eslint-disable-next-line no-console
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });

    let email = data.get('email');
    let password = data.get('password');

    setLoading(true);
    let postData = {email,password};
    dispatch(postSignIn(postData))

  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
           <img src={logo_red_large} width="340px" alt="logo" style={{margin:"10px"}}/>
          <Avatar style={{ height: '40px', width: '40px' }} sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon style={{ height: '20px', width: '20px' }} />
          </Avatar>
         
          <Typography component="h1" variant="h7">
            {trans.sign_in_title}
          </Typography>
          <div style={{marginTop: "10px", marginBottom: "10px"}}>
            {trans.sign_in_desc}
          </div>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={trans.email_field}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={trans.pass_field}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={trans.remember_me}
            /> */}
             <LoadingButton 
             loading={loading}    
             type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
                {trans.sign_in_btn}
            </LoadingButton>
           
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
       
      </Container>
  );
}

export default SignIn;