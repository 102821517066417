import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
// import { initializeFirebase } from './firebase/FirebaseInit';
// import registerServiceWorker from './firebase/registerServiceWorker';
import {createStore, applyMiddleware, compose} from 'redux';
import reduxThunk from 'redux-thunk';

import MainApp from './components/mainApp';
import reducers from './redux';
import * as Const from './constants';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


import './fonts/AC-RalewayHeavy.ttf'; 
import './fonts/AC-RalewayRegular.ttf'; 
import './fonts/AC-RalewaySemiBold.ttf'; 
import registerServiceWorker from './firebase/registerServiceWorker';
import { initializeFirebase } from './firebase/firebaseSet';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      if (Const.DEBUG_MODE){
        console.log("Registration successful, scope is:", registration.scope);
      }
    })
    .catch(function(err) {
      if (Const.DEBUG_MODE){
        console.log("Service worker registration failed, error:", err);
      }
    });
}

ReactDOM.render(
  <Provider store={store}>
    <MainApp />
  </Provider>,
  document.querySelector('#root')
);


registerServiceWorker();
initializeFirebase();
