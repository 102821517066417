import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PRIMARY_COLOR, editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EuroIcon from '@mui/icons-material/Euro';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { POST_INSURANCE_TYPE_ADD_CATEGORY, POST_INSURANCE_TYPE_ADD_CATEGORY_FAQ, POST_INSURANCE_TYPE_CHANGE_FAQ_ORDER, POST_INSURANCE_TYPE_DELETE_CATEGORY_FAQ, POST_INSURANCE_TYPE_EDIT_CATEGORY, POST_INSURANCE_TYPE_EDIT_CATEGORY_FAQ, POST_INSURANCE_TYPE_SINGLE, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SweetAlert from 'sweetalert2';
import { postInsuranceTypeAddCategoryFaq, postInsuranceTypeChangeFaqOrder, postInsuranceTypeDeleteCategoryFaq, postInsuranceTypeEditCategory, postInsuranceTypeEditCategoryFaq, postInsuranceTypeSingle } from '../../redux/insuranceType/action';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';
import { SortableContainer , SortableHandle , SortableElement , arrayMove } from  'react-sortable-hoc'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const EditInsuranceCategoryFaqsItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const addInsuranceTypeCategoryFaqData = useSelector(({insuranceTypes}) => insuranceTypes.addInsuranceTypeCategoryFaqData);
    const editInsuranceTypeCategoryFaqData = useSelector(({insuranceTypes}) => insuranceTypes.editInsuranceTypeCategoryFaqData);
    const deleteInsuranceTypeCategoryFaqData = useSelector(({insuranceTypes}) => insuranceTypes.deleteInsuranceTypeCategoryFaqData);
    const changeInsuranceTypeCategoryFaqOrderData =  useSelector(({insuranceTypes}) => insuranceTypes.changeInsuranceTypeCategoryFaqOrderData);
    const singleInsuranceTypeData = useSelector(({insuranceTypes}) => insuranceTypes.singleInsuranceTypeData);

 
    const {selectedCategoryEditItem,selectedInsuranceTypeItem} = props;
    
    //HOOKS VARIABLES
    const [insuranceTypeCategoryItem,setInsuranceTypeCategoryItem] = useState(null);
    const [selectedFaq,setSelectedFaq] = useState(null);
    const [title,setTitle] = useState("");
    const [desc,setDesc] = useState("");
    const [coverType,setCoverType] = useState("");
    const [categoryFaqs, setCategoryFaqs] = useState([]);
    
    const [selectedLang,setSelectedLang] = useState(settingsData.settings.langs[0]);
 



    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
  
    const makePostItem = () => {

      let postData = {
        "title": title,
        "desc": desc,
        "coverType": coverType,
        "lang": selectedLang.value,
        "categoryId": selectedCategoryEditItem.id,
        "insuranceTypeId": selectedInsuranceTypeItem.id
      };

      return postData;
    }

    const saveCategoryData = (postData) => {
      if (selectedFaq == null){
        console.log(postData);
        dispatch(postInsuranceTypeAddCategoryFaq(postData))
      } else {
        postData.faqId = selectedFaq._id;
        console.log(postData);
        dispatch(postInsuranceTypeEditCategoryFaq(postData))
      }

    }

    useEffect(() => {
      if (addInsuranceTypeCategoryFaqData != null){
        if (addInsuranceTypeCategoryFaqData.status) {
          toast.dismiss();
          toast.success(addInsuranceTypeCategoryFaqData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onCategoriesUpdated();

          updateTypeData()

        } else {
          toast.dismiss();
          toast.error(addInsuranceTypeCategoryFaqData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_ADD_CATEGORY_FAQ, payload: null});

      } 
    },[addInsuranceTypeCategoryFaqData]);

    useEffect(() => {
      if (editInsuranceTypeCategoryFaqData != null){
        if (editInsuranceTypeCategoryFaqData.status) {
          toast.dismiss();
          toast.success(editInsuranceTypeCategoryFaqData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onCategoriesUpdated();

              updateTypeData()
        } else {
          toast.dismiss();
          toast.error(editInsuranceTypeCategoryFaqData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_EDIT_CATEGORY_FAQ, payload: null});

      } 
    },[editInsuranceTypeCategoryFaqData]);

    useEffect(() => {
      if (deleteInsuranceTypeCategoryFaqData != null){
        if (deleteInsuranceTypeCategoryFaqData.status) {
          toast.dismiss();
          toast.success(deleteInsuranceTypeCategoryFaqData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onCategoriesUpdated();

              updateTypeData()
        } else {
          toast.dismiss();
          toast.error(deleteInsuranceTypeCategoryFaqData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_DELETE_CATEGORY_FAQ, payload: null});

      } 
    },[deleteInsuranceTypeCategoryFaqData]);

    useEffect(() => {
      if (changeInsuranceTypeCategoryFaqOrderData != null){
        if (changeInsuranceTypeCategoryFaqOrderData.status) {
              props.onCategoriesUpdated();
              updateTypeData()
        } 
        dispatch({type: POST_INSURANCE_TYPE_CHANGE_FAQ_ORDER, payload: null});
      } 
    },[changeInsuranceTypeCategoryFaqOrderData]);

    useEffect(() => {
      if (singleInsuranceTypeData != null){
    
        singleInsuranceTypeData.categories.forEach(insuranceTypeCategory => {
                if (insuranceTypeCategory.id == selectedCategoryEditItem.id){
                  setInsuranceTypeCategoryItem(insuranceTypeCategory);
                  setCategoryFaqs(insuranceTypeCategory.faqs);
                }
        });

        dispatch({type: POST_INSURANCE_TYPE_SINGLE, payload: null});
      } 
    },[singleInsuranceTypeData]);




    useEffect(() => {
      if (selectedCategoryEditItem != null){

          setInsuranceTypeCategoryItem(selectedCategoryEditItem);
          setCategoryFaqs(selectedCategoryEditItem.faqs);
      } else {
        clearData();
      }
    },[selectedCategoryEditItem]);

    useEffect(() => {
      if (selectedFaq != null){

          setTitle(selectedFaq.title);
          setDesc(selectedFaq.desc);
          setCoverType(selectedFaq.cover_type);
      

      } else {
        clearData();
      }
    },[selectedFaq]);


    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

  //   useEffect(() => {
  //     if (selectedLang){
  //       setLanguageItems();
  //     }
  // }, [selectedLang]);

    //FUNCTIONS
    const clearData = () => {
        setTitle("");
        setDesc("");
        setCoverType("");
    }
   
    const changeLanguage = (lang) => {
      setSelectedLang(lang);
    }

    const columnData = [
      {id: "title", align: true, disablePadding: false, label:  trans.table_head_faq_title},
      // {id: "desc", align: true, disablePadding: false, label: trans.table_head_faq_desc},
      {id: "required", align: true, disablePadding: false, label: trans.faq_required_cover_type}
    ];
  

    const onEditRow = (e,item) => {
      e.preventDefault();
      setSelectedFaq(item);
    }

    const onClean = (e) => {
      e.preventDefault();
      setSelectedFaq(null);
    }

    const onSubmit = (e) => {
      e.preventDefault();

      if (title != "" && desc != ""){
        let postData = makePostItem();

        saveCategoryData(postData);
      }
    }


    const updateTypeData = () => {
      dispatch(postInsuranceTypeSingle({
        "itemId": selectedInsuranceTypeItem.id,
        "langLoc": selectedLang.value
      }));
      clearData();
      setSelectedFaq(null);
    }

    const onDeleteRow = (e,item) => {
      e.preventDefault();
      SweetAlert.fire({
        theme: "dark",
        title:trans.delete_title,
        text:trans.delete_descr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.delete_title,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            deleteItem(item);
        }
      })
    }


    const deleteItem = (item) => {
      dispatch(postInsuranceTypeDeleteCategoryFaq({
        "insuranceTypeId": selectedInsuranceTypeItem.id,
        "categoryId": selectedCategoryEditItem.id,
        "faqId": item._id,
        "langLoc": "el"
      }));
    }

    // Handler for traversing completion, helper arrayMove is used
    const onSortEnd  = ({oldIndex, newIndex}) => {
      const updatedItems = arrayMove(categoryFaqs, oldIndex, newIndex);
      const updatedItemsWithOrder = updatedItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      setCategoryFaqs(updatedItemsWithOrder);
      dispatch(postInsuranceTypeChangeFaqOrder({"insuranceTypeId": selectedInsuranceTypeItem.id,
      "categoryId": selectedCategoryEditItem.id,orderedItems: updatedItemsWithOrder}))
    };

 
    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

    {settingsData.settings.langs.length > 1 ? 
          <Grid item xs={12}>

            <LangPicker
                onLangSelectedChanged={changeLanguage}
            />

          </Grid> : null }
      

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="faq_title"
                    label={trans.table_head_faq_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="coverType">{trans.faq_required_cover_type}</InputLabel>
                  <Select
                          labelId="coverType"
                          id="coverType"
                          value={coverType}
                          label={trans.faq_required_cover_type}
                          onChange={(e) => setCoverType(e.target.value)}>
                          <MenuItem key={`faq_required_cover_type_true`} value={"required"}>{trans.faq_required_cover_type_true}</MenuItem>
                          <MenuItem key={`faq_required_cover_type_false`} value={"optional"}>{trans.faq_required_cover_type_false}</MenuItem>
                          <MenuItem key={`faq_required_cover_type_guide`} value={"guide"}>{trans.faq_required_cover_type_guide}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="faq_desc"
                    label={trans.table_head_faq_desc}
                    value={desc}
                    type="text"
                    onChange={(e)=> {setDesc(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

        
   
            <Grid container justifyContent="center" >
              <Grid item>
                {selectedFaq != null ?  
                <Button style={{marginTop:'10px', marginBottom:'10px',backgroundColor: 'green'}}  variant="contained"  disabled={props.onSubmitLoading} onClick={onClean}>{trans.cancel_btn}</Button>
                : null }
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={title == "" || desc == ""} onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
            </Grid>
            
            <Grid item xs={12} >

              <div className='_insurance_categories_container'>
                  {insuranceTypeCategoryItem != null ? 
                  <Table size="small" style={{overflowX:'auto'}}>
                    <TableHead>
                        <TableRow>

                        <TableCell/>
                        {columnData.map(column => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric}
                              padding={column.disablePadding ? "none" : "normal"}
                            >
                                {column.label}
                            </TableCell>
                          );
                        })}
                        <TableCell/>
                        <TableCell/>
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBodySortable onSortEnd={(sortEnd) => onSortEnd(sortEnd)} useDragHandle>
                      {categoryFaqs.map((row,index) => (
                        <SortableRow index={index} key={row._id}>
                            <TableCell><DragHandler /></TableCell> 
                            <TableCell>{row.title}</TableCell>
                            {/* <TableCell>{row.desc}</TableCell> */}
                            <TableCell>{row.cover_type == "required" ? trans.faq_required_cover_type_true : row.cover_type  == "guide" ? trans.faq_required_cover_type_guide : trans.faq_required_cover_type_false  }</TableCell>
                            <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onEditRow(e,row)}  variant="outlined">{trans.edit_item_title}</Button></TableCell>
                            <TableCell><Button startIcon={<DeleteOutlineIcon />} size="small"  onClick={(e) => onDeleteRow(e,row)}  variant="outlined">{trans.table_delete_button}</Button></TableCell>

                            </SortableRow>
                        ))}
                    </TableBodySortable>
                  
                  </Table> : null }

              </div>

            </Grid>

        </Grid>
      </div>
    );
}

export default EditInsuranceCategoryFaqsItem;
