import {
  GET_SETTINGS,
  GET_COUNTRIES
} from '../actionTypes';
  
const INIT_STATE = {
  settingsData: null,
  countriesData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case GET_SETTINGS:
                   return {
                     ...state,
                     settingsData: action.payload
                }
          case GET_COUNTRIES:
                  return {
                    ...state,
                    countriesData: action.payload
               }
        default:
              return state;
        }
};
      
export default reducer;