import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { PRIMARY_COLOR } from '../../constants';

import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import { getLocalDate, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannerChangeImagesOrder, postBannerImagesDelete, postBannerSingle, postBannersEdit } from '../../redux/banners/action';
import { POST_BANNER_DELETE_IMAGE, POST_BANNER_EDIT, POST_BANNER_EDIT_ORDER, POST_BANNER_SINGLE, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';
import { SortableContainer , SortableHandle , SortableElement , arrayMove } from  'react-sortable-hoc'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SweetAlert from 'sweetalert2';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const EditBannerImagesItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const deleteImageBannerData = useSelector(({banners}) => banners.deleteImageBannerData);
    const uploadImageData = useSelector(({upload}) => upload.uploadImageData);
    const editBannerData = useSelector(({banners}) => banners.editBannerData);
    const changeBannerImageOrder = useSelector(({banners}) => banners.changeBannerImageOrder);
    const singleBannerData = useSelector(({banners}) => banners.singleBannerData);
 
    const {editItem} = props;
    
    //HOOKS VARIABLES

    const [status,setStatus] = useState(true);
    const [img,setImg] = useState("");
    const [img_thumb,setImgThumb] = useState("");
    const [fit,setFit] = useState("FIT");
    const [currentImage, setCurrentImage] = useState({url: "", file: null});
    const [web_link,setWebLink] = useState("");
    const [editImages,setEditImages] = useState([]);
    const [selectedBanner,setSelectedBanner] = useState(null);
    const [selectedImage,setSelectedImage] = useState(null);
    

    //REFS
    const fileInputRef=useRef();

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
 
    //HOOKS
    const makePostItem = () => {
      let postData = {
        "updateImages": true
      };

      var oldImages = [];
      editImages.forEach((element,index) => {
        oldImages.push(element);
      });

      if (selectedImage != null){
        oldImages.forEach((element,index) => {     
          if (element._id == selectedImage._id){
            oldImages[index].web_link = web_link;
            oldImages[index].fit = fit;
            oldImages[index].status = status;

            if (selectedImage != null && uploadImageData != null){
              oldImages[index].img = uploadImageData.img;
              oldImages[index].img_thumb =  uploadImageData.img_thumb;
            }
          }
        });

        postData.images = oldImages;
      }


      if (uploadImageData != null && selectedImage == null){
        oldImages.push({  
          img:  uploadImageData.img,
          img_thumb:  uploadImageData.img_thumb,
          web_link: web_link,
          status: status,
          fit: fit,
          order: 1
        });
        
        // Adjust the order of existing items
        oldImages.forEach((image, index) => {
          image.order = index + 2;
        });

        postData.images = oldImages;
      } 

      return postData;
    }

    const uploadImageFile = () => {
      if (currentImage.file != null){
        var formData = new FormData();
        formData.append('path', "banners");
        formData.append("image_file", currentImage.file);
        dispatch(postUploadImage(formData))
      }
    }


    useEffect(() => {
      if (editItem != null){
          setSelectedBanner(editItem.selectedBanner);
          setEditImages(editItem.images);
      } else {
        clearData();
      }
    },[editItem]);


    useEffect(() => {
      if (selectedImage != null){
          setStatus(selectedImage.status);
          setImg(selectedImage.img_url);
          setImgThumb(selectedImage.img_thumb_url);
          setFit(selectedImage.fit);
          setWebLink(selectedImage.web_link);
      } else {
        clearData();
      }
    },[selectedImage]);

    useEffect(() => { 
      if (uploadImageData != null){
        if (uploadImageData.status) {
          toast.dismiss();
          toast.success(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
             
              let postData = makePostItem();

              if (editItem != null){
                postData.itemId = editItem.id;
              }

              onSubmitData(postData);
            
        } else {
          toast.dismiss();
          toast.error(uploadImageData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_UPLOAD_IMAGE, payload: null});
      }
    }, [uploadImageData]);

    useEffect(() => { 
      if (deleteImageBannerData != null){
        if (deleteImageBannerData.status) {
          toast.dismiss();
          toast.success(deleteImageBannerData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              onReloadItem();
        } else {
          toast.dismiss();
          toast.error(deleteImageBannerData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_BANNER_DELETE_IMAGE, payload: null});
      }
    }, [deleteImageBannerData]);

    useEffect(() => { //EDIT DATA
      if (editBannerData != null){
        if (editBannerData.status) {
          toast.dismiss();
          toast.success(editBannerData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              
            onReloadItem();
        } else {
          toast.dismiss();
          toast.error(editBannerData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_BANNER_EDIT, payload: null});
      }
    }, [editBannerData]);


    useEffect(() => { //EDIT DATA
      if (changeBannerImageOrder != null){
        if (changeBannerImageOrder.status) {
          toast.dismiss();
          toast.success(changeBannerImageOrder.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              
            onReloadItem();
        } else {
          toast.dismiss();
          toast.error(changeBannerImageOrder.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_BANNER_EDIT_ORDER, payload: null});
      }
    }, [changeBannerImageOrder]);

    
    useEffect(() => { //EDIT DATA
      if (singleBannerData != null){
        if (singleBannerData.status) {
          toast.dismiss();
          toast.success(singleBannerData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setSelectedBanner(singleBannerData);
              setEditImages(singleBannerData.images);
              clearData();
              setSelectedImage(null);
        } else {
          toast.dismiss();
          toast.error(singleBannerData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_BANNER_SINGLE ,payload: null});
      }
    }, [singleBannerData]);
    

    

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    //FUNCTIONS
    const clearData = () => {
        setStatus(true);
        setFit('FIT');
        setImg("");
        setImgThumb("");
        setWebLink("");
        setCurrentImage({url: "", file: null})
    }


    const updateIconImageFile = (event) => {
      if (event.target.files.length != 0 ){
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
        let file = event.target.files[0];
  
        let reader = new FileReader();
        reader.onloadend = () => {
          setCurrentImage({url:reader.result, file: file});
        }
        reader.readAsDataURL(file);
      }
    }


    const columnData = [
      {id: "img", align: true, disablePadding: false, label:  trans.table_head_image},
      {id: "status", align: true, disablePadding: false, label: trans.table_head_status},
      {id: "fit", align: true, disablePadding: false, label: trans.table_head_box_fit}
    ];

    const onSortEnd  = ({oldIndex, newIndex}) => {
      const updatedItems = arrayMove(editImages, oldIndex, newIndex);
      const updatedItemsWithOrder = updatedItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      setEditImages(updatedItemsWithOrder);
      dispatch(postBannerChangeImagesOrder({"itemId": editItem.id,orderedItems: updatedItemsWithOrder}))
    };


    const onEditRow = (e,item) => {
      e.preventDefault();
      setSelectedImage(item);
    }


    const onDeleteRow = (e,item) => {
      e.preventDefault();
      SweetAlert.fire({
        theme: "dark",
        title:trans.delete_title,
        text:trans.delete_descr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.delete_title,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            deleteItem(item);
        }
      })
    }

    const deleteItem = (item) => {
      console.log(item);
      dispatch(postBannerImagesDelete({
        "itemId": editItem.id,
        "imageId": item._id,
        "langLoc": "el"
      }));
    }
 
    const onClean = (e) => {
      e.preventDefault();
      setSelectedImage(null);
      clearData();
    }

    const onSubmit = (e) => {
      e.preventDefault();

      if (currentImage.file != null){
        let postData = makePostItem();

        if (editItem != null){
          postData.itemId = editItem.id;
  
          if (currentImage.file != null){
            uploadImageFile();
          } else {
            onSubmitData(postData);
          }
        } 
      } else {
        let postData = makePostItem();
        postData.itemId = editItem.id;
        onSubmitData(postData);
      }

    }

    const onSubmitData = (postData) => {
      dispatch(postBannersEdit(postData));
    }


    const onReloadItem = () => {
      props.onDataSubmited();
      dispatch(postBannerSingle({itemId: editItem.id,fromApp: false}));
    }

    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">



            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_visible}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.table_head_visible}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`menu_item_visible`} value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

           
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="visible_state">{trans.table_head_box_fit}</InputLabel>
                <Select
                    labelId="fit"
                    id="fit"
                    value={fit}
                    label={trans.table_head_box_fit}
                    onChange={(e) => setFit(e.target.value)}>{
                          settingsData.settings.box_fit_types.map(boxFitType => {
                            return <MenuItem key={`box_fit_${boxFitType}`} value={boxFitType}>{boxFitType}</MenuItem>
                          })
                    }
                </Select>
              </FormControl>
            </Grid>


            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="web_link"
                    label={trans.table_head_web_link}
                    value={web_link}
                    type="text"
                    onChange={(e)=> {setWebLink(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
                  <span>{trans.upload_banner_img_field}</span>
                  <div style={{marginTop:'15px'}}>
                    <Button color="success" variant="outlined" onClick={()=>fileInputRef.current.click()} >{trans.choose_file}</Button>
                    <input ref={fileInputRef} id="fileupload" type="file" name="file" onChange={(e) => updateIconImageFile(e)}  multiple style={{display:'none'}}/>
                  </div>
            </Grid>

            <Grid item xs={12} >
              {currentImage.url == "" ? 
              <img style={{maxHeight:'150px',maxWidth:'100%'}} src={img}></img>
              : <img style={{maxHeight:'150px',maxWidth:'100%'}} src={currentImage.url}></img>}
                  
              </Grid>
            
           
            <Grid container justifyContent="center" >
              <Grid item>
                {selectedImage != null ?  
                <Button style={{marginTop:'10px', marginBottom:'10px',backgroundColor: 'green'}}  variant="contained"  disabled={props.onSubmitLoading} onClick={onClean}>{trans.cancel_btn}</Button>
                : null }
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={currentImage.file == null && currentImage.url == "" && img == ""} onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
            </Grid>

              <Grid item xs={12} >

              <div className='_insurance_categories_container'>
                  {editItem != null ? 
                  <Table size="small" style={{overflowX:'auto'}}>
                    <TableHead>
                        <TableRow>

                        <TableCell/>
                        {columnData.map(column => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric}
                              padding={column.disablePadding ? "none" : "normal"}
                            >
                                {column.label}
                            </TableCell>
                          );
                        })}
                        <TableCell/>
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBodySortable onSortEnd={(sortEnd) => onSortEnd(sortEnd)} useDragHandle>
                      {editImages.map((row,index) => (
                        <SortableRow index={index} key={row._id}>
                            <TableCell><DragHandler /></TableCell> 
                            <TableCell><img src={row.img_thumb_url} height={'40px'} width={'40px'}></img></TableCell>
                            <TableCell>{`${trans[`status_${row.status}`]}`}</TableCell>
                            <TableCell>{row.fit}</TableCell>
                            <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onEditRow(e,row)}  variant="outlined">{trans.edit_item_title}</Button></TableCell>
                            <TableCell><Button startIcon={<DeleteOutlineIcon />} size="small"  onClick={(e) => onDeleteRow(e,row)}  variant="outlined">{trans.table_delete_button}</Button></TableCell>

                            </SortableRow>
                        ))}
                    </TableBodySortable>
                  
                  </Table> : null }

              </div>

            </Grid>

        </Grid>
      </div>
    );
}

export default EditBannerImagesItem;
