import {
  POST_SOCIAL_TEMPLATE_ALL,
  POST_SOCIAL_TEMPLATE_ADD,
  POST_SOCIAL_TEMPLATE_EDIT,
  POST_SOCIAL_TEMPLATE_DELETE
} from '../actionTypes';


  
const INIT_STATE = {
  socialTemplatesData: null,
  addSocialTemplateData: null,
  editSocialTemplateData: null,
  deleteSocialTemplateData: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_SOCIAL_TEMPLATE_ALL:
               return {
                ...state,
                socialTemplatesData: action.payload
          }
          case POST_SOCIAL_TEMPLATE_ADD:
            return {
              ...state,
              addSocialTemplateData: action.payload
          }
          case POST_SOCIAL_TEMPLATE_EDIT:
            return {
              ...state,
              editSocialTemplateData: action.payload
          }
          case POST_SOCIAL_TEMPLATE_DELETE:
            return {
              ...state,
              deleteSocialTemplateData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;