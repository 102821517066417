import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getLocalDate, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannersEdit } from '../../redux/banners/action';
import { POST_BANNER_EDIT, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditCarItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
  
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [model,setModel] = useState("");
    const [brand,setBrand] = useState("");
    const [costPerUnit,setCostPerUnit] = useState("");
    const [fuel_type, setFuelType] = useState("");

    //REFS
    const trans = settingsData.trans;
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

          props.onSubmitData(postData);

      }
    }, [props.toGetData]);

    const makePostItem = () => {
      let postData = {
        "model": model,
        "brand": brand,
        "costPerUnit":costPerUnit,
        "fuel_type": fuel_type
      };

      return postData;
    }

    useEffect(() => {
      if (editItem != null){
          setBrand(editItem.brand);
          setModel(editItem.model);
          setFuelType(editItem.fuel_type)
          setCostPerUnit(editItem.costPerUnit);
      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    //FUNCTIONS
    const clearData = () => {
        setBrand("");
        setModel("");
        setFuelType("");
        setCostPerUnit("");
    }

    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

      

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="brand"
                    label={trans.table_head_brand}
                    value={brand}
                    type="text"
                    onChange={(e)=> {setBrand(e.target.value)}}
                    fullWidth
                    disabled
                    variant="standard" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="widht"
                    label={trans.table_head_model}
                    value={model}
                    type="text"
                    onChange={(e)=> {setModel(e.target.value)}}
                    fullWidth
                    disabled
                    variant="standard" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="height"
                    label={trans.table_head_fuel_type}
                    value={fuel_type}
                    type="text"
                    onChange={(e)=> {setFuelType(e.target.value)}}
                    fullWidth
                    disabled
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="web_link"
                    label={trans.table_head_costPerUnit}
                    value={costPerUnit}
                    type="text"
                    onChange={(e)=> {setCostPerUnit(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>
         

        </Grid>
      </div>
    );
}

export default EditCarItem;
