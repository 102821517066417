import {
  POST_INSURANCE_COMPANY_ALL,
  POST_INSURANCE_COMPANY_ADD,
  POST_INSURANCE_COMPANY_EDIT,
  POST_INSURANCE_COMPANY_CHANGE_ORDER
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postInsuranceCompaniesAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-company/all`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_COMPANY_ALL, payload: response.data});
};

    
export const postInsuranceCompanyAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-company/add`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_COMPANY_ADD, payload: response.data});
};

export const postInsuranceCompanyEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-company/edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_COMPANY_EDIT, payload: response.data});
};


export const postInsuranceCompaniesChangeOrder = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`insurance-company/change-order`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_INSURANCE_COMPANY_CHANGE_ORDER, payload: response.data});
};
