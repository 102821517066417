import React from 'react';

const UserStatusText = (user,trans) => {
  var text, color;  
  if (user.enabled == true){
    text =  trans.account_status_approved;
    color = "green";
  }  else {
    text =  trans.account_status_rejected
    color = "red";
  }

  return <div style={{color: color}}>
    <b>{text}</b>
  </div>;
}

export default UserStatusText;