import './insurance_contract.css';
import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_INSURANCE_CONTRACT_ADD, POST_INSURANCE_CONTRACT_EDIT } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postInsuranceContractAdd, postInsuranceContractAll, postInsuranceContractEdit, } from '../../redux/insuranceContract/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import TablePagination from '@mui/material/TablePagination';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import { getLocalDate, getLocalDateWithoutTime } from '../../helpers/General';
import EditInsuranceContractItem from './EditInsuranceContractItem';
import EditInsuranceContractFiles from './EditInsuranceContractFiles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EditNotificationItem from '../notifications/EditNotificationItem';
import { postNotificationsAdd } from '../../redux/notifications/action';
import { postUsersAll } from '../../redux/users/action';

import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Box, Checkbox, TableContainer, Typography, useMediaQuery } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { postInsuranceCompaniesAll } from '../../redux/insuranceCompany/action';
import { postInsuranceTypesAll } from '../../redux/insuranceType/action';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const InsuranceContract = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const insuranceContractData = useSelector(({insuranceContract}) => insuranceContract.insuranceContractData);
  const addInsuranceContractData = useSelector(({insuranceContract}) => insuranceContract.addInsuranceContractData);
  const editInsuranceContractData = useSelector(({insuranceContract}) => insuranceContract.editInsuranceContractData);
  const usersData = useSelector(({users}) => users.usersData);
  const insuranceCompaniesData = useSelector(({insuranceCompanies}) => insuranceCompanies.insuranceCompaniesData);
  const insuranceTypesData = useSelector(({insuranceTypes}) => insuranceTypes.insuranceTypesData);


  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [contractsArr,setContractArr] = useState([]);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [addModal, setAddModal] = useState(false);
  const [addFilesModal, setAddFilesModal] = useState(false);

  const [toGetData, setToGetData] = useState(false);
 
  const [toClearData, setToClearData] = useState(false);

  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"created_at": -1});
  const [searchContractKeyword, setSearchContractKeyword] = useState("");
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [bannerTypeSelect, setSearchTypeSelect] = useState(0);
  const [accountStatusSelect, setAccountStatusSelect] = useState("none");

  const [toGetPushData, setToGetPushData] = useState(false);
  const [pushModal, setPushModal] = useState(false);

  const [fromTitle, setFromTitle] = useState("");
  const [fromDesc, setFromDesc] = useState("");
  const [fromUserId, setFromUserId] = useState("");
  const [fromItemId, setFromItemId] = useState("");

  
  const [usersArr,setUsersArr] = useState([]);
  const [usersFilter, setUsersFilter] = useState(null);
  const [searchUser,setSearchUser] = useState(null);
  const [userId, setUserId] = useState(null);

  const [insuranceType, setInsuranceType] = useState("");
  const [insuranceTypeCategory, setInsuranceTypeCategory] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [companiesArr,setCompaniesArr] = useState([]);
  const [insuranceTypesArr,setInsuranceTypesArr] = useState([]);
  const [insuranceTypeCategoriesArr,setInsuranceTypeCategoriesArr] = useState([]);


  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
   getUserData();
   getCompanyData();
   getInsuranceTypesData();
  }, []);

  const getUserData = () => {
    let postData = {
      hasPaginate: false,
      searchKeyword: searchUser,
    };

    dispatch(postUsersAll(postData)); 
  }

  useEffect(() => {
    if (usersData != null){
      console.log("usersDatausersDatausersDatausersData",usersData);
       setUsersArr(usersData.users);
    } 
  },[usersData]);

  useEffect(() => { 
    if (insuranceCompaniesData != null){
      setCompaniesArr(insuranceCompaniesData.insurance_companies);
    }
  }, [insuranceCompaniesData]);

  useEffect(() => { //GOT DATA
    if (insuranceTypesData != null){
      setInsuranceTypesArr(insuranceTypesData.insurance_types);
    }
  }, [insuranceTypesData]);



  const getCompanyData = () => {
    let postData = {
      fromApp: true
    };

    dispatch(postInsuranceCompaniesAll(postData)); 
  }

  const getInsuranceTypesData = () => {
    let postData = {
      fromApp: true
    };

    dispatch(postInsuranceTypesAll(postData)); 
  }


  // useEffect(() => { //GOT DATA
  //   if (editItem != null &&
  //      editItem.insuranceType != null && insuranceTypesArr != null && insuranceTypesArr.length > 0 && insuranceTypesData != null && insuranceTypeCategoriesArr.length == 0){
  //     setInsuranceType(editItem.insuranceType.id);
  //     hangleInsuranceTypeChange(editItem.insuranceType.id,editItem.insuranceTypeCategory.id)
  //   }
  // }, [insuranceTypesArr]);

  const hangleInsuranceTypeChange = (value,category) => {
    let id = value;
    var newInsuranceCategoriesArr = [];
    insuranceTypesArr.forEach(insuranceType => {
      if (insuranceType.id == value){
        newInsuranceCategoriesArr = insuranceType.categories;
      }
    });
    setInsuranceType(id);
    setInsuranceTypeCategory(category);
    setInsuranceTypeCategoriesArr(newInsuranceCategoriesArr);
  }


  useEffect(() => {
    if (userId != null){
       getData();
    } 
  },[userId]);

  useEffect(() => {
    if (insuranceTypeCategory != null){
       getData();
    } 
  },[insuranceTypeCategory]);


  useEffect(() => {
    if (insuranceCompany != null){
       getData();
    } 
  },[insuranceCompany]);



  useEffect(() => { //GOT DATA
    if (insuranceContractData != null){
     // console.log("insuranceContractData",insuranceContractData);
      setContractArr(insuranceContractData.insurance_contracts);
      // setTotalData(insuranceContractData.total);
      setLoadedFirstTime(true);
    }
  }, [insuranceContractData]);

  useEffect(() => { //ADD DATA
    if (addInsuranceContractData != null){
      if (addInsuranceContractData.status) {
        toast.dismiss();
        toast.success(addInsuranceContractData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addInsuranceContractData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_INSURANCE_CONTRACT_ADD, payload: null});
    }
  }, [addInsuranceContractData]);

  useEffect(() => { //EDIT DATA
    if (editInsuranceContractData != null){
      if (editInsuranceContractData.status) {
        toast.dismiss();
        toast.success(editInsuranceContractData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(editInsuranceContractData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_INSURANCE_CONTRACT_EDIT, payload: null});
    }
  }, [editInsuranceContractData]);

  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchContractKeyword != null && loadedFirstTime){
      reloadData();
    }
  }, [searchContractKeyword]);
  
  useEffect(() => { 
    if (bannerTypeSelect != null && bannerTypeSelect && bannerTypeSelect != 0){
      reloadData();
    }
  }, [bannerTypeSelect]);

  useEffect(() => { 
    if (accountStatusSelect != null && accountStatusSelect && accountStatusSelect != "none"){
      reloadData();
    }
  }, [accountStatusSelect]);
  
  

  //FUNCTIONS
  const getData = () => {
    console.log(sort);
    let postData = {
      fromApp: false,
      sort_by: sort,
      limit: rowsPerPage
    };

    if (userId != null && userId != ""){
      postData.filter_user = userId;
    }

    if (insuranceTypeCategory != null && insuranceTypeCategory != ""){
      postData.filter_insurance_type_category = insuranceTypeCategory;
    }

    if (insuranceCompany != null && insuranceCompany != ""){
      postData.filter_insurance_company = insuranceCompany;
    }


    if (searchContractKeyword != null && searchContractKeyword != ""){
      postData.filter_search = searchContractKeyword;
    }

    dispatch(postInsuranceContractAll(postData)); 
  }

  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const onAddNew = (e) => {
    e.preventDefault();
    setDialogTitle(trans.add_banner_title)
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onDialogFilesClose = () => {
    setAddFilesModal(false);
  }

  const onDialogSubmit = () => {
    console.log("hey");
    setToGetData(true);
  }

  const onSubmitData = (data) => {
    let postData = data;
    console.log(data);
    console.log(editItem);
    if (editItem != null){
      postData.itemId = editItem.id;
      setEditItem(null);
      dispatch(postInsuranceContractEdit(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } else {
      setEditItem(null);
      dispatch(postInsuranceContractAdd(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    }
  }

  const onErrorData = () => {
    setToGetData(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, settingsData.settings.page_limit));
  };

  const createSortHandler = (property) => {
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);


    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };

  


  const onViewRow = (e,item) => {
    e.preventDefault();
    setToClearData(false);
    setDialogTitle(trans.edit_banner_title)
    setEditItem(item);
    setAddModal(true);
  }


  const onViewFiles = (e,item) => {
    e.preventDefault();
    setEditItem(item);
    setAddFilesModal(true);
  }

  const searchContractChanged = (value) => {
    setSearchContractKeyword(value);
  }

  //UI
  const columnData = [
    {id: "contractNum", align: true, disablePadding: false, label:  trans.table_head_insurance_contract_num},
    // {id: "img", align: true, disablePadding: false, label:  trans.table_head_image},
    {id: "user", align: true, disablePadding: false, label: trans.table_head_user},
    {id: "startDate", align: true, disablePadding: false, label: trans.table_head_insurance_date},
    // {id: "endDate", align: true, disablePadding: false, label: trans.table_head_insurance_end_date},
    // {id: "insuranceType.title", align: true, disablePadding: false, label: trans.table_head_insurance_type},
    {id: "insuranceTypeCategory.title", align: true, disablePadding: false, label: trans.table_head_insurance_category},
    {id: "insuranceCompany.title", align: true, disablePadding: false, label: trans.table_head_insurance_company},
    {id: "cost", align: true, disablePadding: false, label: trans.table_head_insurance_cost},
    {id: "created_at", align: true, disablePadding: false, label: trans.table_head_created_at},
  ];

  const onChangeUserType = (value) => {
    setSearchTypeSelect(value);
  }
  
  const onChangeAccountStatus = (value) => {
    setAccountStatusSelect(value);
  }

  const onFilesUpdated = () => {
    reloadData()
  }

  const openToInform = (e,item) => {
    e.preventDefault();
    setFromTitle(`${trans.insurance_push_new_contract_title}`);
    setFromDesc(`${trans.insurance_push_new_contract_desc} ${item.insuranceTypeCategory.title}`);
    if (item.userId != null){
      setFromUserId(item.userId.id);
    }
    setFromItemId(item.id);
    setPushModal(true)
  }

  const onPushModalClose = () => {
    setPushModal(false);
  }

  const onDialogPushSubmit = () => {
    setToGetPushData(true);
  }

  const onSubmitPushData = (data) => {
    let postData = data; 
    dispatch(postNotificationsAdd(postData));
    setPushModal(false);
    setToGetPushData(false);
  }


  const handleUsersChange = (e,selectedValues) => {
    setUsersFilter(selectedValues);
    setUserId(selectedValues != null ? selectedValues.id : "")
    setSearchUser("");
  }

  const handleUserSearchInput = (value) => {
    setSearchUser(value);
  }


  useEffect(() => {
    if (searchUser != null){
      getUserData();
    } 
  },[searchUser]);

  const isSmallScreen = useMediaQuery('(max-width:1200px)'); // Detect small screens

  const isMediumScreen = useMediaQuery('(max-width:1600px)'); // Detect small screens

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_insurance_contract}</CustomTitle>
          <Button startIcon={<QueueIcon />} size="small" color="secondary" onClick={(e) => onAddNew(e)}  variant="outlined">{trans.button_add_new}</Button>
        </div>
        <br/>
        <Grid
            spacing={1}
            container
            direction="row"
            alignItems="center">

          <Grid item  s={12} xs={12}  md={6}>
                <TextField
                  id="standard-search"
                  label={trans.search_field}
                  type="search"
                  fullWidth
                  variant="standard" 
                  onChange={(e) => searchContractChanged(e.target.value)}
                />
            </Grid>   
          
              
            <Grid item  s={12} xs={12}  md={6}>
              <Autocomplete
                    fullWidth
                    id="checkboxes-users"
                    options={usersArr}
                   
                    value={usersFilter}
                    onChange={(e,value) => handleUsersChange(e,value)}
                    getOptionLabel={(option) => option.fullname}
                    onInputChange={(event, newInputValue) => {
                      handleUserSearchInput(newInputValue);
                    }}
                    filterOptions={x => x}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderOption={(props, option, { selected }) => (
                      <li  {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.fullname}
                      </li>
                    )}
                    
                    renderInput={(params) => (
                      <TextField {...params} label={trans.users_label} placeholder={trans.add_user}  />
                    )}
              />
              </Grid>

              <Grid item xs={4}>
          <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_type">{trans.table_head_insurance_type}</InputLabel>
                  <Select
                          labelId="table_head_insurance_type"
                          id="table_head_insurance_type"
                          value={insuranceType}
                          label={trans.table_head_insurance_type}
                          onChange={(e) => hangleInsuranceTypeChange(e.target.value,null)}>
                           {insuranceTypesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>

        <Grid item xs={4}>
            <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_category">{trans.table_head_insurance_category}</InputLabel>
                  <Select
                          labelId="table_head_insurance_category"
                          id="table_head_insurance_category"
                          value={insuranceTypeCategory}
                          label={trans.table_head_insurance_category}
                          onChange={(e) => setInsuranceTypeCategory(e.target.value)}>
                           {insuranceTypeCategoriesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_company">{trans.table_head_insurance_company}</InputLabel>
                  <Select
                          labelId="table_head_insurance_company"
                          id="table_head_insurance_company"
                          value={insuranceCompany}
                          label={trans.insurance_contract_state}
                          onChange={(e) => setInsuranceCompany(e.target.value)}>
                           {companiesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>

        </Grid>
        {/* <Grid
            spacing={1}
            container
            direction="row"
            alignItems="center">
          <Grid item  s={12} xs={12}  md={8}>
              <TextField
                id="standard-search"
                label={trans.search_field}
                type="search"
                fullWidth
                variant="standard" 
                disabled={(bannerTypeSelect != 0 && bannerTypeSelect != "all") || (accountStatusSelect != "none"  && accountStatusSelect != "all") }
                onChange={(e) => searchChanged(e.target.value)}
              />
          </Grid>   
          <Grid item  s={12}  xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="sortType">{trans.banner_type_select}</InputLabel>
              <Select
                    variant="standard"
                    labelId="bannerTypeSelect"
                    id="bannerTypeSelect"
                    value={bannerTypeSelect}
                    label={trans.banner_type_select}
                    onChange={(e) => onChangeUserType(e.target.value)}>
                      <MenuItem key={"banner_type_0"} value={"all"}>{trans.all}</MenuItem> 
                      <MenuItem key={"banner_type_1"} value={1}>{trans.bannerTypeDriver}</MenuItem> 
                      <MenuItem key={"banner_type_2"} value={2}>{trans.bannerTypePassenger}</MenuItem>
                      <MenuItem key={"banner_type_3"} value={3}>{trans.bannerTypeBoth}</MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item  s={12}  xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="sortType">{trans.table_head_account_status}</InputLabel>
              <Select
                    variant="standard"
                    labelId="accountStatusSelect"
                    id="accountStatusSelect"
                    value={accountStatusSelect}
                    label={trans.table_head_account_status}
                    onChange={(e) => onChangeAccountStatus(e.target.value)}>
                          <MenuItem key={"account_status_none"} value={"all"}>{trans.all}</MenuItem> 
                      <MenuItem key={"account_status_aproved"} value={"approved"}>{trans.account_status_approved}</MenuItem> 
                      <MenuItem key={"account_status_rejected"} value={"rejected"}>{trans.account_status_rejected}</MenuItem>
                      <MenuItem key={"account_status_pending"} value={"pending"}>{trans.account_status_pending}</MenuItem>
                </Select>
              </FormControl>
          </Grid>
       </Grid> */}
       
       <div>
      <br />
      {!isSmallScreen ? (
        <TableContainer style={{ display: 'block', overflowX: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columnData.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.numeric ? 'right' : 'left'}
                    padding={column.disablePadding ? 'none' : 'normal'}
                    style={{ padding: '4px 8px', whiteSpace: 'nowrap' }}
                  >
                    <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        onClick={() => createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ))}
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {contractsArr.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.contractNum}</TableCell>
                  <TableCell>{row.userId.fullname}</TableCell>
                  <TableCell>
                    <div>
                      {trans.table_date_from}: {getLocalDateWithoutTime(row.startDate)} {trans.table_date_to}: {getLocalDateWithoutTime(row.endDate)}
                    </div>
                  </TableCell>
                  <TableCell>
                    {row.insuranceType.title} / {row.insuranceTypeCategory.title}
                  </TableCell>
                  <TableCell>{row.insuranceCompany.title}</TableCell>
                  <TableCell>{`${row.cost}€`}</TableCell>
                  <TableCell>{getLocalDateWithoutTime(row.created_at)}</TableCell>
                  <TableCell>
                    <div style={{display:'flex',flexDirection:isMediumScreen ? 'column' : 'row', gap: '8px' }}>
                    <Button
                      startIcon={<HowToRegIcon />}
                      size="small"
                      color="secondary"
                      onClick={(e) => onViewRow(e, row)}
                      variant="outlined"
                    >
                      {trans.view_item_title}
                    </Button>
                    <Button
                      startIcon={<PictureAsPdfIcon />}
                      size="small"
                      color="secondary"
                      onClick={(e) => onViewFiles(e, row)}
                      variant="outlined"
                    >
                      {trans.insurance_contract_files}
                    </Button>
                    <Button
                      startIcon={<NotificationsActiveIcon />}
                      size="small"
                      color="secondary"
                      onClick={(e) => openToInform(e, row)}
                      variant="outlined"
                    >
                      {trans.table_head_inform_button}
                    </Button>
                    </div>
                  </TableCell>
              
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
  {contractsArr.map((row) => (
    <Box
      key={row.id}
      style={{
        border: '1px solid #ddd',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: '#2c2c2c',
      }}
    >
      {/* Corrected Labels with Spacing */}
      <Box mb={1}>
        <Typography variant="h6">
          <strong>{trans.table_head_insurance_contract_num}:</strong> {row.contractNum}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="body1">
          <strong>{trans.table_head_user}:</strong> {row.userId.fullname}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="body1">
          <strong>{trans.table_head_insurance_date}:</strong> {getLocalDateWithoutTime(row.startDate)}{' '}
          {trans.table_date_to}: {getLocalDateWithoutTime(row.endDate)}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="body1">
          <strong>{trans.table_head_insurance_category}:</strong> {row.insuranceType.title} /{' '}
          {row.insuranceTypeCategory.title}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="body1">
          <strong>{trans.table_head_insurance_company}:</strong> {row.insuranceCompany.title}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="body1">
          <strong>{trans.table_head_insurance_cost}:</strong> {`${row.cost}€`}
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography variant="body1">
          <strong>{trans.table_head_created_at}:</strong> {getLocalDateWithoutTime(row.created_at)}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} mt={2}>
      <Box display="flex" gap={2}>
        <Button
          startIcon={<HowToRegIcon />}
          size="small"
          color="secondary"
          variant="outlined"
          onClick={(e) => onViewRow(e, row)}
        >
          {trans.view_item_title}
        </Button>
        <Button
          startIcon={<PictureAsPdfIcon />}
          size="small"
          color="secondary"
          variant="outlined"
          onClick={(e) => onViewFiles(e, row)}
        >
          {trans.insurance_contract_files}
        </Button>
        </Box>
        <Box display="flex" gap={2}>
        <Button
          startIcon={<NotificationsActiveIcon />}
          size="small"
          color="secondary"
          variant="outlined"
          onClick={(e) => openToInform(e, row)}
        >
          {trans.table_head_inform_button}
        </Button>
        </Box>
      </Box>
    </Box>
  ))}
</Box>

      
      )}

      <TablePagination
        component="div"
        count={totalData}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
      </Paper>
    </Grid>
  </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${editItem == null ? trans.add_contract_title : trans.edit_contract_title }`}
        body={<EditInsuranceContractItem toGetData={toGetData} toClearData={toClearData} onSubmitData={onSubmitData} onErrorData={onErrorData} editItem={editItem}/> }
        onSubmit={onDialogSubmit}
        maxWidth={"md"}
      />         

       <FormDialog
        open={addFilesModal}
        onClose={onDialogFilesClose}
        title={`${editItem == null ? trans.insurance_contract_files : trans.insurance_contract_files }`}
        body={<EditInsuranceContractFiles editItem={editItem} onFilesUpdated={onFilesUpdated}/> }
        maxWidth={"md"}
        hasSubmit={false}
      />         


      <FormDialog
        open={pushModal}
        onClose={onPushModalClose}
        title={trans.add_notitification_title}
        body={<EditNotificationItem toGetData={toGetPushData} toClearData={() => {}} onSubmitData={onSubmitPushData} editItem={null} from="contracts" fromTitle={fromTitle} fromDesc={fromDesc} fromUserId={fromUserId} fromItemId={fromItemId}/> }
        onSubmit={onDialogPushSubmit}
        maxWidth={"md"}
      />            


</Container>);
}

export default InsuranceContract;