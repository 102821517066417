import React, {Fragment,useEffect,useState} from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import Home from './home';
import Login from './login';
import PrivateRoute from './privateRoute';
import {Provider} from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import history from '../history';
import { toast } from 'react-toastify';
import * as General from '../helpers/General'
import * as Store from '../helpers/Store';

import { fetchToken,askForPermissioToReceiveNotifications, onMessageListener } from '../firebase/firebaseSet';

const CustomToastWithLink = (title,body,userId,type) => (
  <div>
    <div>{title}</div>
    <div>{body}</div>
    {type == "contact" ? 
      <a href={`/app/contact`}>View Contact</a> : 
      <a href={`/app/users/${userId}`}>View Profile</a>}
  </div>
);

const MainApp = () => {

  //NOTIFICATION
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);



  
  askForPermissioToReceiveNotifications();


  fetchToken(setTokenFound);
  

  onMessageListener().then(payload => {
    setNotification({title: payload.data.title, body: payload.data.body})
    setShow(true);
    console.log("NOTIFICATION_RECIEVED",payload);

    toast.dismiss();
    toast.info(CustomToastWithLink(payload.data.title,payload.data.body,payload.data.typeId,payload.data.type));
    // toast.success(payload.data.title, {
    //     position: toast.POSITION.TOP_RIGHT
    //     });

  }).catch(err => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({title: "Notification", body: "This is a test notification"})
    setShow(true);
  }
  //ENDOFNOTIFICATION

  const [darkModeChecked, setDarkModeChecked] = useState(false);

  const theme = createTheme({
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            subtitle1: 'h2',
            subtitle2: 'h2',
            body1: 'span',
            body2: 'span',
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#DE3532",
      },
      secondary: {
        main: "#757575",
      },
      mode: darkModeChecked ? 'dark' : 'light',
      ...(!darkModeChecked
      ? {
      
          background: {
            default: "#f5f7fa",
            paper: "#ffffff",
          },
        }
      : {
        primary: {
          main: "#DE3532",
        },
          secondary: {
            main: "#f5f7fa",
          },
          background: {
            default: "#616161",
            paper: "#1b1b1b",
          },
        }),
    },
  });


  const onModeChange = (isDarkChecked) => {
    setDarkModeChecked(isDarkChecked);
  }


  useEffect(()=>{
    setDarkModeChecked(General.getLightsMode() == "dark")
    },[]);



  
  return (
    <div> 
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider theme={theme}>
    <Fragment>
    <Router location={history.location} navigator={history}>
   
        <Routes>
          {/* <Route exact path='/' element={<PrivateRoute exact path='/'/>}>
            <Route exact path='/' element={<Home/>}/>
            <Route exact path='/dashboard' element={<Home/>}/>
            <Route exact path='/categories' element={<Home/>}/>
            <Route exact path='/sources' element={<Home/>}/>
            <Route exact path='/tags' element={<Home/>}/>
          </Route> */}
          <Route  path='/' element={<PrivateRoute path={'/'}><Navigate to="/app"></Navigate></PrivateRoute>}/> 
           <Route  path='app/*' element={<PrivateRoute path={'app/*'}><Home onModeChange={onModeChange}/></PrivateRoute>}/> 
          {/* <Route  path='app/*' element={<PrivateRoute  path='app/*'/>}>
            <Route  path='app/*' element={<Home/>}/>
          </Route> */}
          {/* <Route exact path='/dashboard' element={<PrivateRoute exact path='/dashboard'/>}>
            <Route exact path='/dashboard' element={<Home/>}/>
          </Route>
           */}
          <Route  path='/login' element={<PrivateRoute path={'/login'}><Login /></PrivateRoute>}/> 
          {/* <Route exact element={<PrivateRoute exact path='/login'/>}>
            <Route exact path='/login' element={<Login/>}/>
          </Route> */}
          {/* <Route exact path='/register' element={<Register/>}/> */}
          {/* <Route exact path='/login' element={<Login/>}/> */}
        </Routes>
       
      
    </Router>
    </Fragment>
    </ThemeProvider>
    <ToastContainer/>
    </LocalizationProvider>
    </div>
  );
};

export default MainApp;
