import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import { SortableContainer , SortableHandle , SortableElement , arrayMove } from  'react-sortable-hoc'

const  SortableRow  = SortableElement(({ children, index }) => {

    return (
        <TableRow key={index}>
          {children}
        </TableRow >
      )
  })

  export default SortableRow;