import './importusers.css';
import React, { useState,useEffect,CSSProperties } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_COMPANY_ADD, POST_COMPANY_EDIT, POST_CREATE_ACCOUNTS } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postImportUsersAdd, postCompaniesAll, postImportUsersEdit, } from '../../redux/insuranceCompany/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import TablePagination from '@mui/material/TablePagination';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QueueIcon from '@mui/icons-material/Queue';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SweetAlert from 'sweetalert2';

import { getLocalDate } from '../../helpers/General';
import { PRIMARY_COLOR } from '../../constants';

import HashLoader from "react-spinners/HashLoader";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { postCreateAccounts } from '../../redux/auth/action';
import CompanyPicker from '../insurance_company/InsuranceCompanyPicker';
import PackagesPicker from './PackagesPicker';
import { TextField } from '@mui/material';

var xlsx = require("xlsx");






const ImportUsers = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const createAccountsData = useSelector(({auth}) => auth.createAccountsData);


  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [importUsersArr,setImportUsersArr] = useState([]);
  const [loadingImport,setLoadingImport] = useState(false);
  const [importFinished,setImportFinished] = useState(false);
  // const [selectedCompanies,setSelectedCompanies] = useState([]);
  //const [selectedPackages,setSelectedPackages] = useState([]);
  //const [availableRoutes, setAvailableRoutes] = useState("0");
  


  //HOOKS
  useEffect(() => { //ONLOAD

  }, []);

  useEffect(() => { //ONLOAD
      if (createAccountsData != null){
        setLoadingImport(false);
        if (createAccountsData.status) {
          setImportFinished(true);
          toast.dismiss();
          toast.success(createAccountsData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              
        } else {
          toast.dismiss();
          toast.error(createAccountsData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_CREATE_ACCOUNTS, payload: null});
      }
   }, [createAccountsData]);
 

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1, range: 3 });

            const headerRow = jsonData[0]; // Get the values from row 4 (index 3)
            const dataRows = jsonData.slice(1); // Get the remaining rows starting from row 5 (index 4)
            
            const json = dataRows.map((row) => {
              const obj = {};
              headerRow.forEach((header, index) => {
                obj[header] = row[index];
              });
              return obj;
            });
       
            var addedUsers = [];

            json.forEach(element => {
              if (element["Πελάτης"] != null && element["Πελάτης"] != "" && element["A/A"] != null){
                addedUsers.push(element);
              }
            });
            console.log(addedUsers);

            setImportUsersArr(addedUsers);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
    }
}

const onImportUsers = (e) => {
  e.preventDefault();
  SweetAlert.fire({
    theme: "dark",
    title:trans.button_import_users,
    text:trans.import_users_desc,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: PRIMARY_COLOR,
    confirmButtonText:trans.btn_submit_import_users,
    cancelButtonText: trans.cancel_btn,
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
        //OK
        //REMOVE
        multiCreateUsers();
    
    }
  })
}

const multiCreateUsers = () => {
  let postData = {
    accList: importUsersArr,
    //packages: selectedPackages,
   // companies: selectedCompanies,
   // availableRoutes
  };

  setLoadingImport(true);

  dispatch(postCreateAccounts(postData));
}

// const onCompaniesSelectChange = (selected) => {
//  // setSelectedCompanies(selected);
// }

// const onPackagesSelectChange = (selected) => {
//   setSelectedPackages(selected);
// }




  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <div className='_flex_row_space'>
          <CustomTitle>{trans.menu_import_users}</CustomTitle>
        </div>
        <br/>
    
        <div>

        <form>
            <label htmlFor="upload">{trans.upload_users_file}: <a style={{color: '#F90'}} href="https://cdn.insurancemotors.eu/demo/insurancemotors_import_users.xls" target="_blank" rel="noreferrer">{trans.example}</a></label>
            <br/>
            <br/>
            {/* <input
                type="file"
                name="upload"
                id="upload"
                onChange={readUploadFile}
            /> */}
            <Button
  variant="contained"
  component="label"
>
  {trans.button_import_users_select_file}
  <input
    type="file"
    name="upload"
    id="upload"
    hidden
    onChange={readUploadFile}
  />
</Button>
        </form>

        <br/>

        <div>
          <span>{trans.upload_users_num}: {importUsersArr.length}</span>
        </div>


        <br/>

       

{/*     
        {importUsersArr.length > 0 && loadingImport == false ?  <div>
          <h4>{trans.upload_users_select_available_routes}</h4>
            <TextField
                    margin="dense"
                    id="availableRoutes"
                    label={trans.field_available_routes}
                    value={availableRoutes}
                    type="number"
                    onChange={(e)=> {setAvailableRoutes(e.target.value)}}
                    fullWidth
                    variant="standard" />
          </div> : null} */}
      
        {/* {importUsersArr.length > 0 && loadingImport == false ?  <div>
          <h4>{trans.upload_users_select_company}</h4>
          <CompanyPicker onCompaniesSelectChange={onCompaniesSelectChange}/>
          </div> : null} */}


        {/* {importUsersArr.length > 0 && loadingImport == false ?  <div>
          <h4>{trans.upload_users_select_package}</h4>
          <PackagesPicker onPackagesSelectChange={onPackagesSelectChange}/>
        </div> : null} */}


        {importUsersArr.length > 0 && loadingImport == false ? <div style={{float: 'right'}}>
          <Button startIcon={<ImportExportIcon />}  color="primary" onClick={(e) => onImportUsers(e)}  variant="outlined">{trans.button_import_users}</Button>
        </div> : null }
     

        {loadingImport == true && importFinished != true ? 
          <div> 
           <div>{trans.import_users_wait_desc}</div> 
           <br/>
          <HashLoader
                  color={"orange"}
                  loading={loadingImport}
                  size={70}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
          </div> :
          null
        }

        </div>
      </Paper>
    </Grid>
  </Grid>

        

</Container>);
}

export default ImportUsers;