import {
  POST_COUNTRY_ALL,
  POST_COUNTRY_EDIT_FUEL
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  countriesData: null,
  editCountryFuelDat: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_COUNTRY_ALL:
               return {
                ...state,
               countriesData: action.payload
          }
          case POST_COUNTRY_EDIT_FUEL:
            return {
              ...state,
              editCountryFuelData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;