import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getLocalDate, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannersEdit } from '../../redux/banners/action';
import { POST_BANNER_EDIT, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditBannerItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
    const uploadImageData = useSelector(({upload}) => upload.uploadImageData);

 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [title,setTitle] = useState("");
    const [position,setPosition] = useState("HOME");
    const [status,setStatus] = useState(true);
    const [has_expire,setHasExpire] = useState(false);
    const [expire_date,setExpireDate] = useState(new Date());

    const [width,setWidth] = useState(0);
    const [height,setHeight] = useState(0);
    const [delay_sec,setDelaySec] = useState(0);
    const [auto_move,setAutoMove] = useState(true);
  
    
    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
 
    //HOOKS
    useEffect(() => {
      if (props.toGetData){

          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

       
          props.onSubmitData(postData);
          
      }
    }, [props.toGetData]);

    const makePostItem = () => {
      let postData = {
        "position": position,
        "title": title,
        "status": status,
        "has_expire": has_expire,
        "expire_date":expire_date,
        "width": width,
        "height": height,
        "delay_sec": delay_sec,
        "auto_move": auto_move
      };

      return postData;
    }

    useEffect(() => {
      if (editItem != null){
          setTitle(editItem.title);
          setPosition(editItem.position);
          setStatus(editItem.status);
          setHasExpire(editItem.has_expire);
          setExpireDate(editItem.expire_date);
          setWidth(editItem.width);
          setHeight(editItem.height);
          setDelaySec(editItem.delay_sec);
          setAutoMove(editItem.auto_move);
      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    //FUNCTIONS
    const clearData = () => {
        setPosition("HOME");
        setTitle("");
        setStatus(true);
        setHasExpire(false);
        setExpireDate(new Date());
        setWidth(0);
        setHeight(0);
        setDelaySec(3);
        setAutoMove(true);
    }

    const handleDateChange = (newValue) => {
      // Get the user's timezone offset in minutes
      const timezoneOffset = newValue.getTimezoneOffset();
    
      // Convert the offset to milliseconds and adjust the date
      const adjustedDate = new Date(newValue.getTime() - timezoneOffset * 60000);
    
      // Set the adjusted date
      setExpireDate(adjustedDate);
    };



    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

      

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="title"
                    label={trans.table_head_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="visible_state">{trans.table_head_position}</InputLabel>
                <Select
                    labelId="position"
                    id="position"
                    value={position}
                    label={trans.table_head_position}
                    onChange={(e) => setPosition(e.target.value)}>{
                          settingsData.settings.banner_positions.map(bannerPosition => {
                            return <MenuItem key={`menu_banner_item_${bannerPosition}`} value={bannerPosition}>{bannerPosition}</MenuItem>
                          })
                    }
                </Select>
              </FormControl>
            </Grid>


            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_visible}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.table_head_visible}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`menu_item_visible`} value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="has_expire">{trans.table_head_has_expire}</InputLabel>
                  <Select
                          labelId="has_expire"
                          id="has_expire"
                          value={has_expire}
                          label={trans.table_head_has_expire}
                          onChange={(e) => setHasExpire(e.target.value)}>
                          <MenuItem key={`menu_item_status_true`} value={true}>{trans.status_true}</MenuItem>
                          <MenuItem key={`menu_item_status_false`} value={false}>{trans.status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} >
              <DesktopDatePicker
                fullWidth
                label={trans.table_head_expire_date}
                value={expire_date}
                onChange={(newValue)=> handleDateChange(newValue)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                    margin="dense"
                    id="widht"
                    label={trans.table_head_width}
                    value={width}
                    type="number"
                    onChange={(e)=> {setWidth(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={3}>
              <TextField
                    margin="dense"
                    id="height"
                    label={trans.table_head_height}
                    value={height}
                    type="text"
                    onChange={(e)=> {setHeight(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="delay_sec"
                    label={trans.table_head_delay_sec}
                    value={delay_sec}
                    type="number"
                    onChange={(e)=> {setDelaySec(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="has_expire">{trans.table_head_auto_move}</InputLabel>
                  <Select
                          labelId="auto_move"
                          id="auto_move"
                          value={auto_move}
                          label={trans.table_head_auto_move}
                          onChange={(e) => setAutoMove(e.target.value)}>
                          <MenuItem key={`auto_move_true`} value={true}>{trans.insurance_offer_expires_yes}</MenuItem>
                          <MenuItem key={`auto_move_false`} value={false}>{trans.insurance_offer_expires_no}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

        </Grid>
      </div>
    );
}

export default EditBannerItem;
