import './posts.css';
import React, { useState,useEffect,useCallback } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { DATE_FORMAT, PRIMARY_COLOR, SEARCH_TYPE_EXACT, SEARCH_TYPE_EXPANDED, SEARCH_TYPE_FUZZY } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { POST_POSTS_ADD, POST_POSTS_DELETE, POST_POSTS_EDIT } from '../../redux/actionTypes';
import CustomTitle from '../global/CustomTitle';
import { postPostsAdd, postPostsAll, postPostsDelete, postPostsEdit } from '../../redux/posts/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import FormDialog from '../global/formDialog';
import AddPost from './addPost';
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SweetAlert from 'sweetalert2';
import { addZeroToNumbers, convertBigNumber, getLocalDate, replaceAll } from '../../helpers/General';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { postCategoriesAll } from '../../redux/categories/action';
import { postSourcesAll } from '../../redux/sources/action';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ShareImageGenerate from './shareImage/shareImageGenerate';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const Posts = () => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const postsData = useSelector(({posts}) => posts.postsData);
  const addPostsData = useSelector(({posts}) => posts.addPostsData);
  const editPostsData = useSelector(({posts}) => posts.editPostsData);
  const deletePostsData = useSelector(({posts}) => posts.deletePostsData);
  const sourcesData = useSelector(({sources}) => sources.sourcesData);
  const categoriesData = useSelector(({categories}) => categories.categoriesData);
  const tagsData = useSelector(({tags}) => tags.tagsData);


  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;
  const searchTypeItems = [
    { value: trans.search_type_exact, id: SEARCH_TYPE_EXACT },
    { value: trans.search_type_fuzzy, id: SEARCH_TYPE_FUZZY},
    { value: trans.search_type_expanded, id: SEARCH_TYPE_EXPANDED}
  ];

  //HOOKS VARIABLES
  const [postsArr,setPostsArr] = useState([]);
  const [allPostsSelected,setAllPostsSelected] = useState(false);
  const [totalData,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page,setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [addModal, setAddModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [toGetData, setToGetData] = useState(false);
  const [toClearData, setToClearData] = useState(false);
  const [editItem,setEditItem] = useState(null);
  const [sort, setSort] = useState({"created_at": -1});
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dialogTitle, setDialogTitle] = useState(trans.add_item_title);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [sourcesFilter, setSourceFilter] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [sourcesArr, setSourcesArr] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [searchTagKeyword,setSerachTagKeyword] = useState("");
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [focusedInput, setFocusedInput] = useState();
  const [originalDateStartValue, setOrignalDateStartValue] = useState(null);
  const [originalDateEndValue, setOrignalDateEndValue] = useState(null);
  const [hasSorting, setHasSorting] = useState(true);
  const [sortType, setSortType] = useState("relevant");
  const [searchType, setSearchType] = useState(searchTypeItems[0].id);

  //HOOKS
  useEffect(() => { //ONLOAD
   getData();
    getFiltersData(); 

  
      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          //console.log("Enter key was pressed. Run your function.");
          // event.preventDefault();
          onDialogSubmit();
        }
      };
      document.addEventListener("keypress", listener);
      return () => {
        document.removeEventListener("keypress", listener);
      };
  
  }, []);

  useEffect(() => { //GOT DATA
    if (postsData != null){
     // setPostsArr(postsData.posts)
      var items = [];
      postsData.posts.forEach(element => {
          var newPostItem = element;
          newPostItem.selected = false;
          items.push(element);
      });
      setPostsArr(items);
      setTotalData(postsData.total);
      setLoadedFirstTime(true);
    }
  }, [postsData]);

  useEffect(() => {
    if (sourcesData != null){
       setSourcesArr(sourcesData.sources);
    
    } 
  },[sourcesData]);

  useEffect(() => {
    if (categoriesData != null){
       setCategoriesArr(categoriesData.categories);
      
    } 
  },[categoriesData]);

  useEffect(() => {
    if (tagsData != null){
      var arr = [];
      arr.push({id: "empty",name: "Empty"})
      tagsData.tags.forEach(element => {
        arr.push(element);
      });
      setTagsArr(arr);
    } 
  },[tagsData]);

  useEffect(() => { //ADD DATA
    if (addPostsData != null){
      if (addPostsData.status) {
        toast.dismiss();
        toast.success(addPostsData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      
        setToClearData(true);
        reloadData();
      } else {
        toast.dismiss();
        toast.error(addPostsData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_POSTS_ADD, payload: null});
    }
  }, [addPostsData]);

  useEffect(() => { //EDIT DATA
    if (editPostsData != null){
      if (editPostsData.status) {
        toast.dismiss();
        toast.success(editPostsData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        setEditItem(null);
        getData()
      } else {
        toast.dismiss();
        toast.error(editPostsData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_POSTS_EDIT, payload: null});
    }
  }, [editPostsData]);

  useEffect(() => { //DELETE DATA
    if (deletePostsData != null){
      if (deletePostsData.status) {
        toast.dismiss();
        toast.success(deletePostsData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        setToClearData(true);
        getData()
      } else {
        toast.dismiss();
        toast.error(deletePostsData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
      }
      dispatch({type: POST_POSTS_DELETE, payload: null});
    }
  }, [deletePostsData]);

  useEffect(() => { //PAGE CHANGED
    if (page != null && loadedFirstTime){
      getData();
    }
  }, [page]);

  useEffect(() => { //ROWS PER PAGE CHANGED
    if (rowsPerPage != null && loadedFirstTime){
     reloadData();
    }
  }, [rowsPerPage]);

  useEffect(() => { //SORT CHANGED
    if (sort != null && loadedFirstTime){
      reloadData();
    }
  }, [sort]);

  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchKeyword != null && loadedFirstTime){
      if (sortType == "relevant" && searchKeyword != ""){
        setHasSorting(false);
      } else {
        setHasSorting(true);
      } 
      reloadData();
    }
  }, [searchKeyword]);


  useEffect(() => { //TAGS CHANGED
    if (tagsFilter != null && loadedFirstTime){
      reloadData();
    }
  }, [tagsFilter]);

  useEffect(() => { //TAGS CHANGED
    if (categoriesFilter != null && loadedFirstTime){
    reloadData();
    }
  }, [categoriesFilter]);

  useEffect(() => { //TAGS CHANGED
    if (sourcesFilter != null && loadedFirstTime){
     reloadData();
    }
  }, [sourcesFilter]);

  useEffect(() => { //originalDateStartValue CHANGED
    if (loadedFirstTime){
     reloadData();
    }
  }, [originalDateStartValue]);


  useEffect(() => { //originalDateEndValue CHANGED
    if (loadedFirstTime){
      reloadData();
    }
  }, [originalDateEndValue]);

  useEffect(() => {
    if (searchTagKeyword != null && searchTagKeyword != ""){
      
      getTags();
    } 
  },[searchTagKeyword]);


  useEffect(() => {
    console.log(sortType);
    if (sortType != null){
      if (sortType == "relevant" && searchKeyword != ""){
        setHasSorting(false);
      } else {
        setHasSorting(true);
      }
   
    } 
  },[sortType]);



  useEffect(() => {
    if (searchType != null && searchKeyword != ""){
      getData();
    } 
  },[searchType]);

  // const checkKeyPress = useCallback((e) => {
  //   const { key, keyCode } = e;
  //   console.log(key, keyCode);
  //   if (key === "Enter") {
  //     if (addModal) {
  //       onDialogSubmit();
  // }
  //   }
  // },[addModal]);

  // useEffect(() => {
  //   window.addEventListener("keypress", checkKeyPress);
  //   return () => {
  //     window.removeEventListener("keypress", checkKeyPress);
  //   };
  // }, [checkKeyPress]);

  //FUNCTIONS
  const getData = () => {
    let fixedTagsFilter = [];
    
    
    tagsFilter.forEach(element => {
      fixedTagsFilter.push(element.id);
    });

    let fixedCategoriesFilter = [];
    
    categoriesFilter.forEach(element => {
      fixedCategoriesFilter.push(element.id);
    });

    var fixedSourcesFilter = [];

    sourcesFilter.forEach(element => {
      fixedSourcesFilter.push(element);
    });

    var startNumber = 0;
    var endNumber = 0; 

    if (originalDateStartValue != null){
      const originalStardDate = originalDateStartValue._d;
      startNumber = parseInt(`${originalStardDate.getFullYear()}${addZeroToNumbers(originalStardDate.getMonth()+1)}${addZeroToNumbers(originalStardDate.getDate())}`,0)
    }

    if (originalDateEndValue != null){
      const originalEndDate = originalDateEndValue._d;
      endNumber = parseInt(`${originalEndDate.getFullYear()}${addZeroToNumbers(originalEndDate.getMonth()+1)}${addZeroToNumbers(originalEndDate.getDate())}`,0)
    }


    let postData = {
          hasPaginate: true,
          fromApp: false,
          page:page + 1,
          limit: rowsPerPage,
          sort,
          searchKeyword: searchKeyword,
          sourcesFilter:fixedSourcesFilter,
          tagsFilter: fixedTagsFilter,
          categoriesFilter: fixedCategoriesFilter,
          originalStartDate: startNumber,
          originalEndDate: endNumber,
          sortType,
          searchType: searchType
        };
    dispatch(postPostsAll(postData)); 
  }

  const getFiltersData = () => {
    let postData = {hasPaginate: false};
    dispatch(postCategoriesAll(postData)); 
    dispatch(postSourcesAll(postData)); 
    getTags();
  }
  const getTags = () => {
    let postData = {hasPaginate: false,searchKeyword: searchTagKeyword};
    dispatch(postTagsAll(postData)); 
  }


  const reloadData = () =>  {
    if (page == 0){
      getData()
    } else {
      setPage(0);
    }
  }

  const onAddNew = (e) => {
    e.preventDefault();
    setDialogTitle(trans.add_item_title)
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setToClearData(true);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onDialogShareClose = () => {
    setShareModal(false);
  }

  const onDialogSubmit = () => {
    setToGetData(true);
  }

  const onSubmitData = (data) => {
    if (data == null) {
      setToGetData(false);
      return;
    }
    let postData = data;

    if (editItem != null){
      postData.itemId = editItem.id;

      dispatch(postPostsEdit(postData));
      setToGetData(false);
      setToClearData(false);
      onDialogClose();
    } else {
      dispatch(postPostsAdd(postData));
      setToGetData(false);
      setToClearData(false);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, settingsData.settings.page_limit));
  };

  const createSortHandler = (property) => {
    if (!hasSorting){
      return;
    }
    const orderBy = property;

    var sortObj = {};
    sortObj[`${property}`] = order == "asc" ?  1 : -1;
    setSort(sortObj);


    setOrderBy(orderBy);
    if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
  };

  

  const onEditRow = (e,item) => {
    e.preventDefault();
    setToClearData(false);
    setDialogTitle(trans.edit_item_title)
    setEditItem(item);
    setAddModal(true);
  }

  const onDeleteRow = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText:trans.btn_delete,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
          //REMOVE
          let postData = {itemId: item.id}
          dispatch(postPostsDelete(postData));
      }
    })
  }

  const searchChanged = (value) => {
    if (value == "" || value.length > 3){
      setSearchKeyword(value);
    }
  
  }

  const handleCategoriesChange = (e,selectedValues) => {
    setCategoriesFilter(selectedValues);
  }

  const handleSourceChange = (e,selectedValue) => {
    if (selectedValue == null){
      setSourceFilter("");
    } else {
      setSourceFilter(selectedValue);
    }
   
  }

  const handleTagsChange  = (e,selectedValues) => {
    setTagsFilter(selectedValues);
  }

  const handleTagsInput = (value) => {
    setSerachTagKeyword(value);
  }

  const onDatesChange = ({ startDate, endDate }) => {
    setOrignalDateStartValue(startDate);
    setOrignalDateEndValue(endDate);
  }

  const onChangeSearchType = (item) => {
    setSearchType(item);
  }


  //UI
  const columnData = [
    {id: "title", align: true, disablePadding: false, label: trans.post_title},
    {id: "source_img", align: true, disablePadding: false, label: ""},
    {id: "source_field", align: true, disablePadding: false, label: trans.source_field},
    {id: "short_code", align: true, disablePadding: false, label: trans.table_post_code},
    
   
    {id: "original_date_int", align: true, disablePadding: false, label: trans.table_head_original_date},
    {id: "copylink", align: true, disablePadding: false, label: "Copy Link"},
    {id: "social", align: true, disablePadding: false, label: "Social"},
    {id: "created_at", align: true, disablePadding: false, label: trans.table_head_created_at},
    {id: "updated_at", align: true, disablePadding: false, label: trans.table_head_updated_at},
    {id: "edit", align: true, disablePadding: false, label: ""},
    {id: "delete", align: true, disablePadding: false, label: ""},
    {id: "clicks", align: true, disablePadding: false, label: trans.table_post_clicks},
    {id: "visible", align: true, disablePadding: false, label: trans.table_head_state},
  ];

  const copyLinkToClipboard = (e,wholeShortUrl) => {
    e.preventDefault();
    navigator.clipboard.writeText(wholeShortUrl);
    toast.success(trans.list_post_item_copied, {
      position: toast.POSITION.TOP_RIGHT
      });
  }

  const selectPost = (item) =>  {
    var items = [];
    postsArr.forEach(element => {
      items.push(element);
    });
    items.forEach((value,index) => {
      if (value.id == item.id) {
        items[index].selected = !item.selected;
      }
     
    })
    setPostsArr(items);
  }

  const getSelectedNumb = () => {
    var selecteditems = [];
    postsArr.forEach(element => {
      if (element.selected){
        selecteditems.push(element);
      }
    });
    return selecteditems.length;
  }

  const copySelectedLinksToClipboard =(e,type) => {

 

    var text = "";
    postsArr.forEach(element => {

      if (element.selected){

        let twitterTemplate = "";
        let instagramTemplate = "";
    
        settingsData.settings.socialTemplates.forEach(element => {
          if (element.type == "twitter_multi") {
            twitterTemplate = element.text;
          }
          if (element.type == "instagram_multi") {
            instagramTemplate = element.text;
          }
        });

        
          const postShortUrl = `/post/${element.short_code}`;

          if (type === "twitter"){

      
          let twitterSocialText = replaceAll(twitterTemplate,"{TITLE}",element.title);
        

          twitterSocialText = replaceAll(twitterSocialText,"{URL}",`insurancemotors.com${postShortUrl}`);

          if (element.source.twitterId != null && element.source.twitterId != "") {
            twitterSocialText = replaceAll(twitterSocialText,"{SOURCE}",`@${element.source.twitterId}`);
          } else {
            twitterSocialText = replaceAll(twitterSocialText,"{SOURCE}",element.source.name);
          }


          text = text + "\n\n" + twitterSocialText;

         } else {

               
          let instagramSocialText = replaceAll(instagramTemplate,"{TITLE}",element.title);
        

          instagramSocialText = replaceAll(instagramSocialText,"{URL}",`insurancemotors.com${postShortUrl}`);

          if (element.source.twitterId != null && element.source.twitterId != "") {
            instagramSocialText = replaceAll(instagramSocialText,"{SOURCE}",`@${element.source.twitterId}`);
          } else {
            instagramSocialText = replaceAll(instagramSocialText,"{SOURCE}",element.source.name);
          }


          text = text + "\n\n" + instagramSocialText;

         }

          
      }
    });



    e.preventDefault();
    navigator.clipboard.writeText(text);
    toast.success(trans.list_post_item_copied, {
      position: toast.POSITION.TOP_RIGHT
      });


  }

  const changeAllPostSelected = () => {
    var allSelected = !allPostsSelected;
    if (allSelected){
      var items = [];
      postsArr.forEach(element => {
        items.push(element);
      });
      items.forEach((value,index) => {
          items[index].selected = true;
      })
      setPostsArr(items);
    } else {
      var items = [];
      postsArr.forEach(element => {
        items.push(element);
      });
      items.forEach((value,index) => {
          items[index].selected = false;
      })
      setPostsArr(items);
    }

    setAllPostsSelected(allSelected);
  }

  const openShareImage = (e,post) => {
    e.preventDefault();    
    setEditItem(post);
    setShareModal(true);
  }

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <CustomTitle>{trans.menu_posts}</CustomTitle>
        <Grid
            spacing={1}
            container
            direction="row"
            alignItems="center">
          <Grid item  s={12} xs={12}  md={6}>
              <TextField
                id="standard-search"
                label={trans.search_field}
                type="search"
                fullWidth
                variant="standard" 
                onChange={(e) => searchChanged(e.target.value)}
              />
          </Grid>   
          <Grid item  s={12} xs={12}  md={2}>

          <FormControl fullWidth>
                    <InputLabel id="sortType">{trans.search_type}</InputLabel>
                    <Select
                            variant="standard"
                            labelId="searchType"
                            id="searchType"
                            value={searchType}
                            label={trans.search_type}
                            disabled={searchKeyword == ""}
                            onChange={(e) => onChangeSearchType(e.target.value)}>
                              {searchTypeItems.map((item,index) => {
                                return (  <MenuItem key={"searchtype" + index} value={item.id}>{item.value}</MenuItem> )
                              })}
                           
                    </Select>
                </FormControl>

          </Grid>
          <Grid item  s={12} xs={12}  md={2}>

          <FormControl fullWidth>
                    <InputLabel id="sortType">{trans.sort_type}</InputLabel>
                    <Select
                            variant="standard"
                            labelId="sortType"
                            id="sortType"
                            value={sortType}
                            label={trans.sort_type}
                            disabled={searchKeyword == ""}
                            onChange={(e) => setSortType(e.target.value)}>
                            <MenuItem value={"relevant"}>{trans.sory_by_relevant}</MenuItem> 
                            <MenuItem value={"table"}>{trans.sort_by_table}</MenuItem>
                    </Select>
                </FormControl>

          </Grid>
          <Grid item  s={12}  xs={12} md={2}>
            <Button  onClick={(e) => onAddNew(e)} style={{width: '100%'}} variant="outlined">{trans.button_add_new}</Button>
          </Grid>
       </Grid>

      <br/>
       <Grid
            spacing={2}
            container
            direction="row"
            alignItems="center">
          <Grid item  s={12} xs={12}  md={2}>
               <Autocomplete
                      multiple
                      fullWidth
                      id="select_sources"
                      options={sourcesArr}
                      
                      value={sourcesFilter}
                      onChange={(e,value) => handleSourceChange(e,value)}
                      getOptionLabel={(option) => option.name || ""}
                    
                      isOptionEqualToValue={(option, value) => option.id == value.id}
                      renderOption={(props, option, { selected }) => (
                        <li  {...props} key={option.id}>
                           <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          <img
                              loading="lazy"
                              width="15"
                              src={`${option.icon_url}`}
                              alt=""
                            />
                          &nbsp;  
                          {option.name}
                        </li>
                      )}
                      
                      renderInput={(params) => (
                        <TextField {...params} label={trans.sources_label} placeholder={trans.add_post_source}  />
                      )}
                    />
            {/* <FormControl fullWidth>
                    <InputLabel id="source_id">{trans.add_post_source}</InputLabel>
                    <Select
                            labelId="source_id"
                            id="source"
                            value={sourcesFilter}
                            label={trans.add_post_source}
                            onChange={(e) => setSourceFilter(e.target.value)}>
                              <MenuItem value={""}>{trans.no_select_option}</MenuItem>
                            {sourcesArr.map((item,index)=> {
                              return ( <MenuItem value={item.id}>{item.name}</MenuItem>)
                            })}  
                    </Select>
                </FormControl> */}
          </Grid>   
          <Grid item  s={12} xs={12}  md={2}>
              <Autocomplete
                      multiple
                      fullWidth
                      id="checkboxes-categories"
                      options={categoriesArr}
                      disableCloseOnSelect
                      value={categoriesFilter}
                      onChange={(e,value) => handleCategoriesChange(e,value)}
                      getOptionLabel={(option) => option.name}
                    
                      isOptionEqualToValue={(option, value) => option.id == value.id}
                      renderOption={(props, option, { selected }) => (
                        <li  {...props} key={option.id}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      
                      renderInput={(params) => (
                        <TextField {...params} label={trans.categories_label} placeholder={trans.add_post_category}  />
                      )}
                    />
          </Grid>   
          <Grid item  s={12} xs={12}  md={3}>
          <Autocomplete
                  multiple
                  freeSolo
                  fullWidth
                  id="checkboxes-tags"
                  options={tagsArr}
                  disableCloseOnSelect
                  value={tagsFilter}
                  onChange={(e,value) => handleTagsChange(e,value)}
                  getOptionLabel={(option) => option.name}
                  onInputChange={(event, newInputValue) => {
                    handleTagsInput(newInputValue);
                  }}
                 
                  isOptionEqualToValue={(option, value) => option.id == value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  
                  renderInput={(params) => (
                    <TextField {...params} label={trans.tags_label} placeholder={trans.add_post_tag} />
                  )}
                />
          </Grid>   
          <Grid item  s={12} xs={12}  md={3}>
            <DateRangePicker
              firstDayOfWeek={1}
              minimumNights={0} 
              isOutsideRange={() => false}
              showClearDates
              onDatesChange={onDatesChange}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              startDate={originalDateStartValue}
              endDate={originalDateEndValue}
            />
          </Grid>   

          <Grid item  s={12} xs={12}  md={2}>
                    
            <div style={{
                display: 'flex',
                border:'solid 0.1px white',
                borderRadius:'6px',
                paddingLeft:'7px',
                paddingRight:'7px',
                width:'100%',
                alignItems:'center'
                }}>
                <p>Selected: {getSelectedNumb()}</p>
                {getSelectedNumb() > 0 ?
                <div style={{marginLeft: '8px'}}>
                        <div 
                          onClick={(e) => copySelectedLinksToClipboard(e,"twitter")} style={{textDecoration:'underline',cursor:'pointer'}} 
                          className={`_list_post_item_clicks_text`}>{trans.copy_twitter}
                        </div>
                        
                        <div 
                          onClick={(e) => copySelectedLinksToClipboard(e,"instagram")} style={{textDecoration:'underline',cursor:'pointer'}} 
                          className={`_list_post_item_clicks_text`}>{trans.copy_instagram}
                        </div>
                      </div> : null }
            </div>
          </Grid>

       </Grid>
       
       
        <div>
         <br/>
          <Table style={{width:'95%',
display:'block',
overflowX:'auto'}}>
            <TableHead>
                <TableRow>
                <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={allPostsSelected}
                          onChange={(e) => changeAllPostSelected()}
                          inputProps={{
                            'aria-labelledby': "",
                          }}
                        />
                      </TableCell>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? "none" : "normal"}
                    >
                      <Tooltip
                  
                        title="Sort"
                        placement={column.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                         hideSortIcon={!hasSorting || column.label == ""}
                          active={hasSorting &&  orderBy === column.id}
                          direction={order}
                          onClick={() => createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                })}
              
                </TableRow>
            </TableHead>
            <TableBody>
                {postsArr.map((row) => { 

              const postShortUrl = `/post/${row.short_code}`;
              const wholeShortUrl = `https://insurancemotors.com${postShortUrl}`

              let twitterTemplate = "";
              let instagramTemplate = "";

              settingsData.settings.socialTemplates.forEach(element => {
                if (element.type == "twitter") {
                  twitterTemplate = element.text;
                }
                if (element.type == "instagram") {
                  instagramTemplate = element.text;
                }
              });

              let twitterSocialText = replaceAll(twitterTemplate,"{TITLE}",row.title);
              let instagramSocialText = replaceAll(instagramTemplate,"{TITLE}",row.title);

              twitterSocialText = replaceAll(twitterSocialText,"{URL}",`insurancemotors.com${postShortUrl}`);
              instagramSocialText = replaceAll(instagramSocialText,"{URL}",`insurancemotors.com${postShortUrl}`);

              if (row.source.twitterId != null && row.source.twitterId != "") {
                twitterSocialText = replaceAll(twitterSocialText,"{SOURCE}",`@${row.source.twitterId}`);
              } else {
                twitterSocialText = replaceAll(twitterSocialText,"{SOURCE}",row.source.name);
              }

              if (row.source.instagramId != null && row.source.instagramId != "") {
                instagramSocialText = replaceAll(instagramSocialText,"{SOURCE}",`@${row.source.instagramId}`);
              } else {
                instagramSocialText = replaceAll(instagramSocialText,"{SOURCE}",row.source.name);
              }


                
                return(
                  <TableRow key={row.id}>
                     <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={row.selected}
                          onChange={(e) => selectPost(row)}
                          inputProps={{
                            'aria-labelledby': "",
                          }}
                        />
                      </TableCell>
                    <TableCell><Link target="_blank" href={row.web_url}>{row.title}</Link></TableCell>
                    <TableCell><img style={{width:'15px',height:'15px',objectFit:'contain'}} src={row.source.icon_url}></img></TableCell>
                    <TableCell>
                    <div 
                          onClick={(e) => copyLinkToClipboard(e,row.source.name)} style={{textDecoration:'underline',cursor:'pointer'}} 
                          className={`_list_post_item_clicks_text`}>{row.source.name}
                    </div>
                    </TableCell>
                    <TableCell>
                    <div 
                          onClick={(e) => copyLinkToClipboard(e,row.short_code)} style={{textDecoration:'underline',cursor:'pointer'}} 
                          className={`_list_post_item_clicks_text`}>{row.short_code}
                    </div>
                    </TableCell>
               
                   
                   
                    <TableCell>{`${row.original_date_item.day}/${row.original_date_item.month}/${row.original_date_item.year}`}</TableCell>
                    <TableCell>{<div 
                      href={postShortUrl} 
                      onClick={(e) => copyLinkToClipboard(e,wholeShortUrl)} style={{textDecoration:'underline',cursor:'pointer'}} 
                      className={`_list_post_item_clicks_text`}>{trans.list_post_item_copy_link}</div>}</TableCell>
                    <TableCell>{
                      <div>
                        <div 
                          onClick={(e) => copyLinkToClipboard(e,twitterSocialText)} style={{textDecoration:'underline',cursor:'pointer'}} 
                          className={`_list_post_item_clicks_text`}>{trans.copy_twitter}
                        </div>
                        
                        <div 
                          onClick={(e) => copyLinkToClipboard(e,instagramSocialText)} style={{textDecoration:'underline',cursor:'pointer'}} 
                          className={`_list_post_item_clicks_text`}>{trans.copy_instagram}
                        </div>

                        <div 
                          onClick={(e) => openShareImage(e,row)} style={{textDecoration:'underline',cursor:'pointer'}} 
                          className={`_list_post_item_clicks_text`}>{trans.share_images}
                        </div>
                      </div>
                    }
                    </TableCell>
                    <TableCell>{getLocalDate(row.created_at)}</TableCell>
                    <TableCell>{getLocalDate(row.updated_at)}</TableCell>
                    <TableCell><Button startIcon={<EditIcon />} size="small" color="secondary" onClick={(e) => onEditRow(e,row)}  variant="outlined">{trans.btn_edit}</Button></TableCell>
                    <TableCell><Button startIcon={<DeleteIcon />} size="small"  color="error" onClick={(e) => onDeleteRow(e,row)}  variant="outlined">{trans.btn_delete}</Button></TableCell>
                    <TableCell>{convertBigNumber(row.clicks)}</TableCell>
                    <TableCell>{row.is_published ? trans.table_head_visible : trans.table_head_hidden}</TableCell>
                  </TableRow>
                )})}
            </TableBody>
          
           </Table>
           <TablePagination
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
         </div>
      </Paper>
    </Grid>
  </Grid>

      <FormDialog
        open={addModal}
        onClose={onDialogClose}
        title={`${dialogTitle} ${trans.post}`}
        body={<AddPost toGetData={toGetData} toClearData={toClearData} onSubmitData={onSubmitData} editItem={editItem}/> }
        onSubmit={onDialogSubmit}
      />            

      <FormDialog
        open={shareModal}
        onClose={onDialogShareClose}
        title={trans.share_post_images_title}
        body={<ShareImageGenerate selectedPost={editItem}/> }
        onSubmit={onDialogSubmit}
        hasSubmit={false}
        maxWidth={"xl"}
      />     

</Container>);
}

export default Posts;