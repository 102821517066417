 import './langPicker.css';
import React, { useState,useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LangPicker = (props) => {


  //SELECTORS
  const settingsData = useSelector(({settings}) => settings.settingsData);
  //VARIABLES
  const dispatch = useDispatch();
  const trans = settingsData.trans;

  //HOOKS VARIABLES
  const [langArr,setLangArr] = useState(settingsData.settings.langs);
  const [selectedLang,setSelectedLang] = useState(settingsData.settings.langs[0]);


  //HOOKS
  useEffect(() => { //ONLOAD
    
  }, []);


  //FUNCTIONS
  const handleLanguageChange = (item) => {
    setSelectedLang(item);
    props.onLangSelectedChanged(item);
  }

  return (langArr.length > 1 ? <div>


    <h4>{trans.select_language}</h4>
    <div className="_lang_picker_container">
        {langArr.map((lang) => {
            return (<div onClick={() => {handleLanguageChange(lang)}} className={`_lang_picker_item ${selectedLang.id == lang.id ? 'active' : ''}`}>
                <img src={lang.icon} alt={lang.name} width={25} height={25} className="_lang_picker_icon" />
                <span className='_lang_picker_text'>{lang.name}</span>
            </div>)
        })}
    </div>     
</div> : null);
}

export default LangPicker;