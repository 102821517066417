import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PRIMARY_COLOR, editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import EuroIcon from '@mui/icons-material/Euro';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage, postUploadSingleFile } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { postInsuranceContractAddFile, postInsuranceContractDeleteFile, postInsuranceContractEditFile, postInsuranceContractReorderFiles, postInsuranceContractSingle } from '../../redux/insuranceContract/action';
import FormDialog from '../global/formDialog';
import { POST_ACCOUNT_CONTACT_OTPION_ADD, POST_ACCOUNT_CONTACT_OTPION_DELETE, POST_INSURANCE_CONTRACT_ADD_FILE, POST_INSURANCE_CONTRACT_DELETE_FILE, POST_INSURANCE_CONTRACT_EDIT_FILE, POST_INSURANCE_CONTRACT_REORDER_FILES, POST_INSURANCE_CONTRACT_SINGLE, POST_UPLOAD_FILE, POST_UPLOAD_IMAGE, POST_USER_SINGLE } from '../../redux/actionTypes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SweetAlert from 'sweetalert2';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';
import {  arrayMove } from  'react-sortable-hoc'
import { postAddContactOption, postDeleteContactOption, postUserSingle } from '../../redux/auth/action';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const EditUserContactOptions =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const uploadFileData = useSelector(({upload}) => upload.uploadFileData);
    const addContactOptionData  = useSelector(({auth}) => auth.addContactOptionData);
    const deleteContactOptionData = useSelector(({auth}) => auth.deleteContactOptionData);
    const userSingleData = useSelector(({auth}) => auth.userSingleData);
   
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [type,setType] = useState("EMAIL");
    const [value,setValue] = useState("");
    const [isRequesting,setIsRequesting] = useState(false);
    const [userItem,setUserItem] = useState(null);
    const [contactOptions,setContractOptions] = useState([]);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
    const makePostItem = () => {

      let postData = {
        "itemId": editItem.id,
        "type": type,
        "value": value,
      };

      return postData;
    }

    useEffect(() => {
      if (addContactOptionData != null){
        if (addContactOptionData.status) {
          toast.dismiss();
          toast.success(addContactOptionData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onContactUpdated();

          updateTypeData()

          setIsRequesting(false);

        } else {
          toast.dismiss();
          toast.error(addContactOptionData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_ACCOUNT_CONTACT_OTPION_ADD, payload: null});

      } 
    },[addContactOptionData]);

    useEffect(() => {
      if (deleteContactOptionData != null){
        if (deleteContactOptionData.status) {
          toast.dismiss();
          toast.success(deleteContactOptionData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onContactUpdated();

              updateTypeData()

              setIsRequesting(false);
        } else {
          toast.dismiss();
          toast.error(deleteContactOptionData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_ACCOUNT_CONTACT_OTPION_DELETE, payload: null});

      } 
    },[deleteContactOptionData]);

    useEffect(() => {
      if (userSingleData != null){
    
        setUserItem(userSingleData)
        setContractOptions(userSingleData.userInfo.contact_options);

        dispatch({type: POST_USER_SINGLE, payload: null});
      } 
    },[userSingleData]);


    useEffect(() => {
      if (editItem != null){
    
        setUserItem(editItem)
        setContractOptions(editItem.contact_options);

      } 
    },[editItem]);
  

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);


    //FUNCTIONS
    const clearData = () => {
        setType("EMAIL");
        setValue('');
    }


    const columnData = [
      {id: "type", align: true, disablePadding: false, label:  trans.contact_option_type},
      {id: "value", align: true, disablePadding: false, label:  trans.contact_option_type_value}
    ];
  


    const onSubmit = (e) => {
      e.preventDefault();


      if (isRequesting){
        return;
      }


      setIsRequesting(true);

      let postData = makePostItem();

      dispatch(postAddContactOption(postData));
      
    }


    const updateTypeData = () => {
      dispatch(postUserSingle({
        "userId": editItem.id,
        "langLoc":  "el"
      }));
      clearData();
    }

    const onDeleteRow = (e,item) => {
      e.preventDefault();
      SweetAlert.fire({
        theme: "dark",
        title:trans.delete_title,
        text:trans.delete_descr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.delete_title,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            deleteItem(item);
        }
      })
    }

    const deleteItem = (item) => {
      dispatch(postDeleteContactOption({
        "itemId": editItem.id,
        "contactOptionId": item._id,
        "langLoc": "el"
      }));
    }

    const getLabelText = (type) => {
      if (type == "EMAIL"){
        return trans.contact_option_type_email;
      } else if (type == "MOBILE"){
        return trans.contact_option_type_mobile;
      } else if (type == "PHONE"){
        return trans.contact_option_type_phone;
      }
    }
 
    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

        <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="contact_option_type">{trans.contact_option_type}</InputLabel>
                  <Select
                          labelId="contact_option_type"
                          id="contact_option_type"
                          value={type}
                          label={trans.contact_option_type}
                          onChange={(e) => setType(e.target.value)}>
                          <MenuItem key={`contact_option_type_email`} value={"EMAIL"}>{trans.contact_option_type_email}</MenuItem>
                          <MenuItem key={`contact_option_type_mobile`} value={"MOBILE"}>{trans.contact_option_type_mobile}</MenuItem>
                          <MenuItem key={`contact_option_type_phone`} value={"PHONE"}>{trans.contact_option_type_phone}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
          

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="value"
                    label={trans.contact_option_type_value}
                    value={value}
                    type="text"
                    onChange={(e)=> {setValue(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

          
         
        

            <Grid container justifyContent="center" >
              <Grid item>
               <br/>
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={(value == null || value == "") || isRequesting == true}  onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
             
            </Grid>
            
            <Grid item xs={12} >

              <div className='_insurance_files_container'>
                  {userItem != null ? 
                  <Table size="small" style={{overflowX:'auto'}}>
                    <TableHead>
                  
                        <TableRow>
                        {columnData.map(column => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric}
                              padding={column.disablePadding ? "none" : "normal"}
                            >
                                {column.label}
                            </TableCell>
                          );
                        })}
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contactOptions.map((row,index) => (
                          <TableRow index={index} key={row.id}>
                            <TableCell>{getLabelText(row.type)}</TableCell>
                            <TableCell>{row.value}</TableCell>
                            <TableCell>
                              <Button
                                startIcon={<DeleteOutlineIcon />}
                                size="small"
                                onClick={(e) => onDeleteRow(e, row)}
                                variant="outlined"
                              >
                                {trans.table_delete_button}
                              </Button>
                            </TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                  
                  </Table> : null }

              </div>

            </Grid>

        </Grid>

        

      </div>
    );
}

export default EditUserContactOptions;
