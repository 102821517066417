import React, { useState,useEffect,CSSProperties } from 'react';
import './dashboard.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import Paper from '@mui/material/Paper';
import CustomTitle from '../global/CustomTitle';
import { postDashboardMain, postExpiredUsers } from '../../redux/admin/action';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate, getLocalDateWithoutTime } from '../../helpers/General';

const Dashboard = () => {

  const dispatch = useDispatch();
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const signInData = useSelector(({auth}) => auth.signInData);
  const userInfoData = useSelector(({auth}) => auth.userInfoData);
  
  const [expiredUsers,setExpiredUsers] = useState([]);
  const [loadingExpiredUsers,setLoadingExpiredUsers] = useState(false);

  const trans = settingsData.trans;


  const expiredUsersData = useSelector(({admin}) => admin.expiredUsersData);

  const dashboardMain = useSelector(({admin}) => admin.dashboardMain);

  const [usersCount,setUsersCount] = useState(0);
  const [contractsCount,setContractsCount] = useState(0);
  const [offersCount,setOffersCount] = useState(0);
  const [companiesCount,setCompaniesCount] = useState(0);


  //HOOKS
  useEffect(() => { //ONLOAD
    getDashboardData();
  }, []);


  useEffect(() => { //ONLOAD
    if (expiredUsersData != null && expiredUsersData.licenseExpired != null && expiredUsersData.overageLicenseExpired != null){
      setLoadingExpiredUsers(false);
      
      var tempList = [];
      expiredUsersData.licenseExpired.forEach(element => {
        tempList.push(element);
      });

      expiredUsersData.overageLicenseExpired.forEach(element => {
        var exists = false;
        tempList.forEach(tempItem => {
          if (tempItem.id == element.id){
            exists = true;
          }
        });
        if (!exists){
          tempList.push(element);
        }
      
      });


      setExpiredUsers(tempList)

    }
  }, [expiredUsersData]);

  useEffect(() => { //ONLOAD
    if (dashboardMain != null){
      console.log(dashboardMain);
      setUsersCount(dashboardMain.stats.usersCount);
      setContractsCount(dashboardMain.stats.contractsCount);
      setOffersCount(dashboardMain.stats.offersCount);
      setCompaniesCount(dashboardMain.stats.companiesCount);
    }
  }, [dashboardMain]);

  


  const getDashboardData = () => {
    setLoadingExpiredUsers(true);
    dispatch(postDashboardMain({}));
  }

  const handleUserClick = (row) => {
    window.open(`https://adminapp.insurancemotors.eu/app/users/${row.id}`, '_blank');
  }

  const buildExpiredUsersList = () => {
    const columnData = [
      {id: "", align: true, disablePadding: false, label: ''},
      {id: "name", align: true, disablePadding: false, label: trans.table_head_fullname},
      {id: "age", align: true, disablePadding: false, label: trans.table_head_age},
      {id: "driving_license_expire", align: true, disablePadding: false, label: trans.table_head_driving_license_expire},
    ];

    if (loadingExpiredUsers){
      return (
          <div className="loading-spinner-container" style={{height:'350px'}}>
            <div className="loading-spinner"></div>
        </div>
      )
    }
    
    return (<div style={{height:'350px'}}>
          <Table size="small" style={{overflowX:'auto',width:'100%'}}>
            <TableHead>
                <TableRow>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? "none" : "normal"}
                    >
                   {column.label}
                    </TableCell>
                  );
                })}
                </TableRow>
            </TableHead>
            <TableBody>
                {expiredUsers.map((row) => (
                  <TableRow style={{cursor:'pointer'}}  key={row.id}>
                    <TableCell onClick={handleUserClick}>{row.img_thumb_url == "#" ? null : <img className='__dashboard_users_img' alt={`user_${row.fullname}_img`} src={row.img_thumb_url} width="30" height="30" ></img>}</TableCell>
                    <TableCell><a target='_blank' rel="noreferrer" href={`https://adminapp.insurancemotors.eu/app/users/${row.id}`}>{row.fullName}</a></TableCell>
                    <TableCell onClick={handleUserClick}>{row.age}</TableCell>
                    <TableCell onClick={handleUserClick}>{getLocalDateWithoutTime(row.driving_license_expire)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          
           </Table>
    </div>)
  }

  

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.dashboard_welcome}, ${userInfoData.userInfo.fullname}`}</CustomTitle>    
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.users_count}`}</CustomTitle> 
               <h2>{usersCount}</h2>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.contractsCount}`}</CustomTitle> 
               <h2>{contractsCount}</h2>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.offersCount}`}</CustomTitle> 
               <h2>{offersCount}</h2>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CustomTitle>{`${trans.companiesCount}`}</CustomTitle> 
               <h2>{companiesCount}</h2>
            </Paper>
          </Grid>

        </Grid>

  

      </Container>
  );
}

export default Dashboard;