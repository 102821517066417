import {
  POST_TAGS_ALL,
  POST_TAGS_ADD,
  POST_TAGS_EDIT,
  POST_TAGS_DELETE,
  POST_TAGS_ALL_PICK,
  POST_TAGS_REMOVE_EVERYWHERE
} from '../actionTypes';


  
const INIT_STATE = {
  tagsData: null,
  tagsPickData: null,
  addTagsData: null,
  editTagsData: null,
  deleteTagsData: null,
  removeFromEverywhereData: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_TAGS_ALL:
               return {
                ...state,
               tagsData: action.payload
          }
          case POST_TAGS_ALL_PICK:
               return {
                ...state,
                tagsPickData: action.payload
          }
          case POST_TAGS_ADD:
            return {
              ...state,
              addTagsData: action.payload
          }
          case POST_TAGS_EDIT:
            return {
              ...state,
              editTagsData: action.payload
          }
          case POST_TAGS_DELETE:
            return {
              ...state,
              deleteTagsData: action.payload
          }
          case POST_TAGS_REMOVE_EVERYWHERE:
            return {
              ...state,
              removeFromEverywhereData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;