import {
  POST_INSURANCE_CONTRACT_ALL,
  POST_INSURANCE_CONTRACT_ADD,
  POST_INSURANCE_CONTRACT_EDIT,
  POST_INSURANCE_CONTRACT_SINGLE,
  POST_INSURANCE_CONTRACT_ADD_FILE,
  POST_INSURANCE_CONTRACT_EDIT_FILE,
  POST_INSURANCE_CONTRACT_DELETE_FILE,
  POST_INSURANCE_CONTRACT_REORDER_FILES
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  insuranceContractData: null,
  addInsuranceContractData: null,
  editInsuranceContractData: null,
  singleInsuranceContractData: null,
  addInsuranceContractFileData: null,
  editInsuranceContractFileData: null,
  deleteFileInsuranceContractData: null,
  reorderFilesInsuranceContractData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_INSURANCE_CONTRACT_ALL:
               return {
                ...state,
                insuranceContractData: action.payload
          }
          case POST_INSURANCE_CONTRACT_ADD:
            return {
              ...state,
              addInsuranceContractData: action.payload
          }
          case POST_INSURANCE_CONTRACT_EDIT:
            return {
              ...state,
              editInsuranceContractData: action.payload
          }
          case POST_INSURANCE_CONTRACT_SINGLE:
            return {
              ...state,
              singleInsuranceContractData: action.payload
          }
          case POST_INSURANCE_CONTRACT_ADD_FILE:
            return {
              ...state,
              addInsuranceContractFileData: action.payload
          }
          case POST_INSURANCE_CONTRACT_EDIT_FILE:
            return {
              ...state,
              editInsuranceContractFileData: action.payload
          }
          case POST_INSURANCE_CONTRACT_DELETE_FILE:
            return {
              ...state,
              deleteFileInsuranceContractData: action.payload
          }
          case POST_INSURANCE_CONTRACT_REORDER_FILES:
            return {
              ...state,
              reorderFilesInsuranceContractData: action.payload
          }
          
        default:
              return state;
        }
};
      
export default reducer;