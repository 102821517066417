import {
  POST_POSTS_ALL,
  POST_POSTS_ADD,
  POST_POSTS_EDIT,
  POST_POSTS_DELETE
} from '../actionTypes';


  
const INIT_STATE = {
  postsData: null,
  addPostsData: null,
  editPostsData: null,
  deletePostsData: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_POSTS_ALL:
               return {
                ...state,
               postsData: action.payload
          }
          case POST_POSTS_ADD:
            return {
              ...state,
              addPostsData: action.payload
          }
          case POST_POSTS_EDIT:
            return {
              ...state,
              editPostsData: action.payload
          }
          case POST_POSTS_DELETE:
            return {
              ...state,
              deletePostsData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;