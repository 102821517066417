import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS, editorConfig, editorConfigSmall } from '../../constants';
import { postTagsAll } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { getLocalDate, getLocalDateWithoutTime, isItemValid } from '../../helpers/General';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { postBannersEdit } from '../../redux/banners/action';
import { POST_BANNER_EDIT, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import JoditEditor from "jodit-react";
import { postUsersAll } from '../../redux/users/action';
import { postInsuranceCompaniesAll } from '../../redux/insuranceCompany/action';
import { postInsuranceTypesAll } from '../../redux/insuranceType/action';
import { postInsuranceContractAll } from '../../redux/insuranceContract/action';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();


const EditInsuranceOfferItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const usersData = useSelector(({users}) => users.usersData);
    
    const insuranceTypesData = useSelector(({insuranceTypes}) => insuranceTypes.insuranceTypesData);
    const insuranceContractData = useSelector(({insuranceContract}) => insuranceContract.insuranceContractData);

 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [compare_text, setCompareText] = useState("");
    const [short_desc, setShortDesc] = useState("");
    const [currentContract, setCurrentContract] = useState(null);
    const [userId, setUserId] = useState(null);
    const [insuranceType, setInsuranceType] = useState("");
    const [insuranceTypeCategory, setInsuranceTypeCategory] = useState("");
    const [expireDate, setExpireDate] = useState(null);
    const [expires, setExpires] = useState(false);
    const [status, setStatus] = useState(true);

    const [usersArr,setUsersArr] = useState([]);
    const [usersFilter, setUsersFilter] = useState(null);
    const [searchUser,setSearchUser] = useState(null);

    const [contractsArr,setContractsArr] = useState([]);
    const [contractsFilter, setContractFilter] = useState(null);
    const [searchContract,setSearchContract] = useState(null);

   
    const [insuranceTypesArr,setInsuranceTypesArr] = useState([]);
    const [insuranceTypeCategoriesArr,setInsuranceTypeCategoriesArr] = useState([]);

    const [offerType, setOfferType] = useState("USER_OFFER");

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;

    const editorDescEl = useRef(null);


    useEffect(() => { //ONLOAD
      getUserData();
      getInsuranceTypesData();
    }, []);
  
    
    const getUserData = () => {
      let postData = {
        hasPaginate: false,
        searchKeyword: searchUser,
      };
  
      dispatch(postUsersAll(postData)); 
    }

    const getContractsData = () => {
      let postData = {
        hasPaginate: false,
        searchKeyword: searchUser,
        filter_user: userId
      };
  
      dispatch(postInsuranceContractAll(postData)); 
    }



    const getInsuranceTypesData = () => {
      let postData = {
        fromApp: true
      };
  
      dispatch(postInsuranceTypesAll(postData)); 
    }
  

    useEffect(() => {
      if (usersData != null){
         setUsersArr(usersData.users);
      } 
    },[usersData]);
  
    useEffect(() => { //GOT DATA
      if (insuranceContractData != null){
        setContractsArr(insuranceContractData.insurance_contracts);
        if (editItem != null &&  editItem.currentContract != null) {
          const found = insuranceContractData.insurance_contracts.find(element => element.id == editItem.currentContract.id);
          if (found != null){
            setCurrentContract(found.id);
            setContractFilter(found);
          }
        }
      }
    }, [insuranceContractData]);

    useEffect(() => { //GOT DATA
      if (insuranceTypesData != null){
        setInsuranceTypesArr(insuranceTypesData.insurance_types);
      }
    }, [insuranceTypesData]);

    
    useEffect(() => { //GOT DATA
      if (editItem != null &&
         editItem.insuranceType != null && insuranceTypesArr != null && insuranceTypesArr.length > 0 && insuranceTypesData != null && insuranceTypeCategoriesArr.length == 0){
        setInsuranceType(editItem.insuranceType.id);
        hangleInsuranceTypeChange(editItem.insuranceType.id,editItem.insuranceTypeCategory.id)
      }
    }, [insuranceTypesArr]);


    //HOOKS
    useEffect(() => {
      console.log(props.toGetData);
      if (props.toGetData){

          if (insuranceTypeCategory == "" || insuranceType == ""){
            props.onErrorData();
            return;
          }

          if (offerType == "USER_OFFER"){
            if (userId == "") {
              props.onErrorData();
              return;
            }
          }
        
          let postData = makePostItem();

          if (editItem != null){
            postData.itemId = editItem.id;
          }

          props.onSubmitData(postData);
          
      }
    }, [props.toGetData]);

    const makePostItem = () => {

      const postData ={
         "title": title,
         "desc": desc,
         "short_desc":short_desc,
         "currentContract": currentContract != null ? currentContract : null,
         "userId": userId,
         "insuranceType": insuranceType,
         "insuranceTypeCategory": insuranceTypeCategory,
         "expireDate": expireDate,
         "expires": expires,
         "status": status,
         "offerType": offerType,
         "compare_text": compare_text
     }
    
      return postData;
    }


    useEffect(() => {
      if (editItem != null){

        setTitle(editItem.title);
        setDesc(editItem.desc);
        setShortDesc(editItem.short_desc);
        setCompareText(editItem.compare_text);

        if (editItem.userId != null){
          setUserId(editItem.userId.id);
        }

        
        if (usersArr.length == 0){
          let newUserData = [];
          newUserData.push(editItem.userId);
          setUsersArr(newUserData);
          setUsersFilter(editItem.userId);
        }

        if (editItem.insuranceType != null){
          setInsuranceType(editItem.insuranceType.id);
          hangleInsuranceTypeChange(editItem.insuranceType.id,editItem.insuranceTypeCategory.id)
        }
       
    
        setExpires(editItem.expires);
        setExpireDate(editItem.expireDate);
        setStatus(editItem.status);
        setOfferType(editItem.offerType);


      } else {
        clearData();
      }
    },[editItem]);


    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    useEffect(() => {
      if (userId != null && userId != ""){
        getContractsData();
      }
    }, [userId]);

   

    //FUNCTIONS
    const clearData = () => {
      setTitle("");
      setDesc('');
      setShortDesc("");
      setOfferType("USER_OFFER");
      setUserId(null);
      setCurrentContract(null);
      setInsuranceType("");
      setInsuranceTypeCategory("");
      setExpireDate(null);
      setExpires(false);
      setStatus(true);
      setCompareText("");
    }

    const handleUsersChange = (e,selectedValues) => {
      setUsersFilter(selectedValues);
      setUserId(selectedValues != null ? selectedValues.id : "")
      setSearchUser("");
    }

    const handleContractsChange = (e,selectedValues) => {
      setContractFilter(selectedValues);
      setCurrentContract(selectedValues != null ? selectedValues.id : "")
      setSearchContract("");
      if (selectedValues != null){
        setInsuranceType(selectedValues.insuranceType.id);
        hangleInsuranceTypeChange(selectedValues.insuranceType.id,selectedValues.insuranceTypeCategory.id)
      }
   
    }
  
    const handleSearchInput = (value) => {
      setSearchUser(value);
    }
  
  
    useEffect(() => {
      if (searchUser != null){
        getUserData();
      } 
    },[searchUser]);
  


    const hangleInsuranceTypeChange = (value,category) => {
      let id = value;
      var newInsuranceCategoriesArr = [];
      insuranceTypesArr.forEach(insuranceType => {
        if (insuranceType.id == value){
          newInsuranceCategoriesArr = insuranceType.categories;
        }
      });
      setInsuranceType(id);
      setInsuranceTypeCategory(category);
      setInsuranceTypeCategoriesArr(newInsuranceCategoriesArr);
    }

    const handleDateChange = (newValue) => {
      // Get the user's timezone offset in minutes
      const timezoneOffset = newValue.getTimezoneOffset();
    
      // Convert the offset to milliseconds and adjust the date
      const adjustedDate = new Date(newValue.getTime() - timezoneOffset * 60000);
    
      // Set the adjusted date
      setExpireDate(adjustedDate);
    };

    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={3} alignItems="center">

        <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="pack_title"
                    label={trans.table_head_pack_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="short_desc"
                    label={trans.table_head_pack_short_desc}
                    value={short_desc}
                    type="text"
                    onChange={(e)=> {setShortDesc(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="compare_text"
                    label={trans.table_head_pack_compare_text}
                    value={compare_text}
                    type="text"
                    onChange={(e)=> {setCompareText(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>


            

        <Grid item xs={12}>
                <JoditEditor
                          ref={editorDescEl}
                           value={desc}
                          config={editorConfigSmall}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setDesc(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
            </Grid>


        <Grid item xs={12} >
              <FormControl fullWidth>
                  <InputLabel id="insurance_offer_type">{trans.insurance_offer_type}</InputLabel>
                  <Select
                          labelId="insurance_offer_type"
                          id="insurance_offer_type"
                          value={offerType}
                          label={trans.insurance_offer_type}
                          onChange={(e) => setOfferType(e.target.value)}>
                          <MenuItem key={`insurance_offer_type_user_offer`} value={"USER_OFFER"}>{trans.insurance_offer_type_user_offer}</MenuItem>
                          <MenuItem key={`insurance_offer_type_all_offer`} value={"ALL_OFFER"}>{trans.insurance_offer_type_all_offer}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

      {offerType != "USER_OFFER" ? null : 
        <Grid item xs={12}>
           <Autocomplete
                    fullWidth
                    id="checkboxes-users"
                    options={usersArr}
                    value={usersFilter}
                    onChange={(e,value) => handleUsersChange(e,value)}
                    getOptionLabel={(option) => option.fullname}
                    onInputChange={(event, newInputValue) => {
                      handleSearchInput(newInputValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderOption={(props, option, { selected }) => (
                      <li  {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.fullname + " (" + option.email + ")"}
                      </li>
                    )}
                    
                    renderInput={(params) => (
                      <TextField {...params} label={trans.users_label} placeholder={trans.add_user}  />
                    )}
              />
        </Grid>
        }

        {offerType != "USER_OFFER" ? null : 
        <Grid item xs={12}>
           <Autocomplete
                    fullWidth
                    id="checkboxes-contracts"
                    options={contractsArr}
                    value={contractsFilter}
                    disabled={userId == null || userId == ""}
                    onChange={(e,value) => handleContractsChange(e,value)}
                    getOptionLabel={(option) => option.contractNum + " " + option.insuranceTypeCategory.title + " " + option.insuranceCompany.title + " " + trans.table_head_insurance_end_date + ": " + getLocalDateWithoutTime(option.endDate)}
                    onInputChange={(event, newInputValue) => {
                      handleSearchInput(newInputValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderOption={(props, option, { selected }) => (
                      <li  {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.contractNum + " " + option.insuranceTypeCategory.title + " " + option.insuranceCompany.title + " " + trans.table_head_insurance_end_date + ": " + getLocalDateWithoutTime(option.endDate)}
                      </li>
                    )}
                    
                    renderInput={(params) => (
                      <TextField {...params} label={trans.contracts_label} placeholder={trans.select_contract}  />
                    )}
              />
        </Grid>
           }

      

        <Grid item xs={6}>
          <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_type">{trans.table_head_insurance_type}</InputLabel>
                  <Select
                          labelId="table_head_insurance_type"
                          id="table_head_insurance_type"
                          value={insuranceType}
                          label={trans.table_head_insurance_type}
                          onChange={(e) => hangleInsuranceTypeChange(e.target.value,null)}>
                           {insuranceTypesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>

      
        <Grid item xs={6}>
            <FormControl fullWidth>
                  <InputLabel id="table_head_insurance_category">{trans.table_head_insurance_category}</InputLabel>
                  <Select
                          labelId="table_head_insurance_category"
                          id="table_head_insurance_category"
                          value={insuranceTypeCategory}
                          label={trans.table_head_insurance_category}
                          onChange={(e) => setInsuranceTypeCategory(e.target.value)}>
                           {insuranceTypeCategoriesArr.map(element => {
                              return  <MenuItem value={element.id}><div className='_flex_row'> 
                                  <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                  <div style={{width: '10px'}}></div>
                                  {element.title}
                                </div></MenuItem>
                            })
                            }
                  </Select>
           </FormControl>
        </Grid>
     
         
          <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="insurance_offer_expires">{trans.insurance_offer_expires}</InputLabel>
                  <Select
                          labelId="insurance_offer_expires"
                          id="insurance_offer_expires"
                          value={expires}
                          label={trans.insurance_offer_expires}
                          onChange={(e) => setExpires(e.target.value)}>
                          <MenuItem key={`insurance_offer_expires_yes`} value={true}>{trans.insurance_offer_expires_yes}</MenuItem>
                          <MenuItem key={`insurance_offer_expires_no`} value={false}>{trans.insurance_offer_expires_no}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>


          <Grid item xs={6}>
            <DesktopDatePicker
                  fullWidth
                  label={trans.insurance_offer_expire_date}
                  value={expireDate}
                  onChange={(newValue)=> {
                    // Get the user's timezone offset in minutes
                    const timezoneOffset = newValue.getTimezoneOffset();
               
                    // Convert the offset to milliseconds and adjust the date
                    const adjustedDate = new Date(newValue.getTime() - timezoneOffset * 60000);
                  
                    // Set the adjusted date
                    handleDateChange(adjustedDate);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
          </Grid>
         
            <Grid item xs={12} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.insurance_offer_state}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.insurance_offer_state}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`insurance_offer_state_active`} value={true}>{trans.insurance_offer_state_active}</MenuItem>
                          <MenuItem key={`insurance_offer_state_inactive`} value={false}>{trans.insurance_offer_state_inactive}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

    
            
        </Grid>
      </div>
    );
}

export default EditInsuranceOfferItem;
