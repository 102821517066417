import {
  POST_USERS_ALL,
  POST_USERS_ADD,
  POST_USERS_EDIT,
  POST_USERS_CHANGE_APPROVED,
  POST_USERS_CHANGE_ACCESS_PACKAGES,
  POST_USERS_CHANGE_COMPANIES,
  POST_USERS_CHANGE_ROUTES
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
  import * as Const from '../../constants';
import { DEBUG_MODE } from '../../constants';
  
 
  
    
export const postUsersAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`admin/users`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USERS_ALL, payload: response.data});
};

    
export const postUsersAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/register`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USERS_ADD, payload: response.data});
};

export const postUsersEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/edit`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USERS_EDIT, payload: response.data});
};

export const postUsersChangeApproved = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/change-user-approved`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USERS_CHANGE_APPROVED, payload: response.data});
};

export const postUsersChangeAccessPackages = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/change-access-packages`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USERS_CHANGE_ACCESS_PACKAGES, payload: response.data});
};

export const postUsersChangeCompanies = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/change-companies`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USERS_CHANGE_COMPANIES, payload: response.data});
};


export const postUsersChangeRoutes = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/change-routes`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USERS_CHANGE_ROUTES, payload: response.data});
};





