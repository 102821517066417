import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import EuroIcon from '@mui/icons-material/Euro';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { POST_INSURANCE_TYPE_ADD_CATEGORY, POST_INSURANCE_TYPE_EDIT_CATEGORY, POST_INSURANCE_TYPE_SINGLE, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { postInsuranceTypeAddCategory, postInsuranceTypeEditCategory, postInsuranceTypeSingle } from '../../redux/insuranceType/action';
import FormDialog from '../global/formDialog';
import EditInsuranceCategoryPricesItem from './EditInsuranceCategoryPricesItem';
import EditInsuranceCategoryFaqsItem from './EditInsuranceCategoryFaqsItem';
import EditInsuranceCategoryPacksItem from './EditInsuranceCategoryPacksItem';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const EditInsuranceCategoriesItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
    const uploadImageData = useSelector(({upload}) => upload.uploadImageData);
    const addInsuranceTypeCategoryData = useSelector(({insuranceTypes}) => insuranceTypes.addInsuranceTypeCategoryData);
    const editInsuranceTypeCategoryData = useSelector(({insuranceTypes}) => insuranceTypes.editInsuranceTypeCategoryData);
    const singleInsuranceTypeData = useSelector(({insuranceTypes}) => insuranceTypes.singleInsuranceTypeData);

 
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [insuranceTypeItem,setInsuranceTypeItem] = useState(null);
    const [title,setTitle] = useState("");
    const [titles,setTitles] = useState([]);
    const [desc,setDesc] = useState("");
    const [descriptions,setDescriptions] = useState([]);
    const [contents,setContents] = useState([]);
    const [content,setContent] = useState("");
    const [pricesModal,setPricesModal] = useState(false);
    const [faqsModal,setFaqsModal] = useState(false);
    const [packsModal,setPacksModal] = useState(false);


    const [img,setImg] = useState("");
    const [img_thumb,setImgThumb] = useState("");
    const [site_link, setSiteLink] = useState("");
    const [status,setStatus] = useState(true);
    const [price_desc, setPriceDesc] = useState("");
    const [price_title, setPriceTitle] = useState("");
    const [packs_title, setPacksTitle] = useState("");
    const [button_type, setButtonType] = useState("");
    const [button_link, setButtonLink] = useState("");
    

    const [currentImage, setCurrentImage] = useState({url: "", file: null});

    const [selectedLang,setSelectedLang] = useState(settingsData.settings.langs[0]);
 

    const [selectedCategory,setSelectedCategory] = useState(null);

    const [selectedCategoryEditItem,setSelectedCategoryEditItem] = useState(null);
    

    //REFS
    const fileInputRef=useRef();
    const editorDescEl = useRef(null);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
  
    const makePostItem = () => {

      let postData = {
        "titles": titles,
        "site_link": site_link,
        "status": status,
        "descriptions": descriptions,
        "contents": contents,
        "price_desc": price_desc,
        "price_title": price_title,
        "packs_title": packs_title,
        "button_type": button_type,
        "button_link": button_link
      };

      if (uploadImageData != null){
        postData.img = uploadImageData.img;
        postData.img_thumb = uploadImageData.img_thumb;
      }

      return postData;
    }

    const uploadImageFile = () => {
      if (currentImage.file != null){
        var formData = new FormData();
        formData.append('path', "insurnacetypecategories");
        formData.append("image_file", currentImage.file);
        dispatch(postUploadImage(formData))
      }
    }

    const saveCategoryData = (postData) => {
      if (selectedCategory == null){
        console.log(postData);
        dispatch(postInsuranceTypeAddCategory(postData))
      } else {
        postData.categoryId = selectedCategory.id;
        console.log(postData);
        dispatch(postInsuranceTypeEditCategory(postData))
      }

    }

    useEffect(() => {
      if (addInsuranceTypeCategoryData != null){
        if (addInsuranceTypeCategoryData.status) {
          toast.dismiss();
          toast.success(addInsuranceTypeCategoryData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onCategoriesUpdated();

          updateTypeData()

        } else {
          toast.dismiss();
          toast.error(addInsuranceTypeCategoryData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_ADD_CATEGORY, payload: null});

      } 
    },[addInsuranceTypeCategoryData]);

    useEffect(() => {
      if (editInsuranceTypeCategoryData != null){
        if (editInsuranceTypeCategoryData.status) {
          toast.dismiss();
          toast.success(editInsuranceTypeCategoryData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onCategoriesUpdated();

              updateTypeData()
        } else {
          toast.dismiss();
          toast.error(editInsuranceTypeCategoryData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_EDIT_CATEGORY, payload: null});

      } 
    },[editInsuranceTypeCategoryData]);

    useEffect(() => {
      if (singleInsuranceTypeData != null){
    
        setInsuranceTypeItem(singleInsuranceTypeData)

        dispatch({type: POST_INSURANCE_TYPE_SINGLE, payload: null});
      } 
    },[singleInsuranceTypeData]);




    useEffect(() => {
      if (editItem != null){

          setInsuranceTypeItem(editItem);

      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
      if (selectedCategory != null){

          setTitles(selectedCategory.titles);
          setDescriptions(selectedCategory.descriptions);
          setContents(selectedCategory.contents);

          setImg(selectedCategory.img_url);
          setImgThumb(selectedCategory.img_thumb_url);
          setSiteLink(selectedCategory.site_link);
          setStatus(selectedCategory.status);
          setPriceDesc(selectedCategory.price_desc);
          setPriceTitle(selectedCategory.price_title);
          setPacksTitle(selectedCategory.packs_title);
          setButtonType(selectedCategory.button_type);
          setButtonLink(selectedCategory.button_link);

          setLanguageItems();

      } else {
        clearData();
      }
    },[selectedCategory]);

    useEffect(() => { 
     // console.log("uploadImageData",uploadImageData);
      if (uploadImageData != null){
        if (uploadImageData.path == "insurnacetypecategories" ){
          if (uploadImageData.status) {
            toast.dismiss();
            toast.success(uploadImageData.message, {
                position: toast.POSITION.TOP_RIGHT
                });
              
                let postData = makePostItem();

                if (editItem != null){
                  postData.itemId = editItem.id;
                }

                saveCategoryData(postData);
          } else {
            toast.dismiss();
            toast.error(uploadImageData.message, {
                position: toast.POSITION.TOP_RIGHT
                });
          }
          dispatch({type: POST_UPLOAD_IMAGE, payload: null});
        }
      }
    }, [uploadImageData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    useEffect(() => {
      if (selectedLang){
        setLanguageItems();
      }
  }, [selectedLang]);

    //FUNCTIONS
    const clearData = () => {
        setTitle("");
        setStatus(true);
        setDesc("");
        setContent("");
        setSiteLink("");
        setTitles([]);
        setDescriptions([]);
        setContents([]);
        setImg("");
        setImgThumb("");
        setPriceDesc("");
        setPriceTitle("");
        setPacksTitle("");
        setButtonType('');
        setButtonLink("");
    }

    const setLanguageItems = () => {
      if (selectedCategory != null){
        if (selectedCategory.titles.find(element => element.lang == selectedLang.value) != null){
          setTitle(selectedCategory.titles.find(element => element.lang == selectedLang.value).value);
        } else {
          setTitle("");
        }
      
        if (selectedCategory.descriptions.find(element => element.lang == selectedLang.value) != null){
          setDesc(selectedCategory.descriptions.find(element => element.lang == selectedLang.value).value);
        } else {
          setDesc("");
        }

        if (selectedCategory.contents.find(element => element.lang == selectedLang.value) != null){
          setContent(selectedCategory.contents.find(element => element.lang == selectedLang.value).value);
        } else {
          setContent("");
        }
      }
    }

    const updateIconImageFile = (event) => {
      if (event.target.files.length != 0 ){
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
        let file = event.target.files[0];
  
        let reader = new FileReader();
        reader.onloadend = () => {
          setCurrentImage({url:reader.result, file: file});
        }
        reader.readAsDataURL(file);
      }
    }

   
    const updateTitle = (text) => {
      setTitle(text);
      if (titles.find(element => element.lang == selectedLang.value) != null ){
        var newData = [];
        titles.forEach((element) => {
          var newElement = {value: element.value, lang: element.lang};
          if (element.lang == selectedLang.value){
            newElement.value = text
          }
          newData.push(newElement);
        });
        setTitles(newData);
      } else {
        var newData = titles;
        newData.push({value: text, lang: selectedLang.value});
        setTitles(newData);
      }
    }

    const updateDesc = (text) => {
      setDesc(text);
      if (descriptions.find(element => element.lang == selectedLang.value) != null ){
        var newData = [];
        descriptions.forEach((element) => {
          var newElement = {value: element.value, lang: element.lang};
          if (element.lang == selectedLang.value){
            newElement.value = text
          }
          newData.push(newElement);
        });
        setDescriptions(newData);
      } else {
        var newData = descriptions;
        newData.push({value: text, lang: selectedLang.value});
        setDescriptions(newData);
      }
    }

    const updateContent = (text) => {
      setContent(text);
      if (contents.find(element => element.lang == selectedLang.value) != null){
        var newData = [];
        contents.forEach((element) => {
          var newElement = {value: element.value, lang: element.lang};
          if (element.lang == selectedLang.value){
            newElement.value = text
          }
          newData.push(newElement);
        });
        setContents(newData);
      } else {
        var newData = contents;
        newData.push({value: text, lang: selectedLang.value});
        setContents(newData);
      }
    }

    const changeLanguage = (lang) => {
      setSelectedLang(lang);
    }

    const columnData = [
      {id: "img", align: true, disablePadding: false, label:  trans.table_head_image},
      {id: "title", align: true, disablePadding: false, label: trans.table_head_title},
      {id: "status", align: true, disablePadding: false, label: trans.table_head_status},
    ];
  

    const onEditRow = (e,item) => {
      e.preventDefault();
      setSelectedCategory(item);

    }

    const onClean = (e) => {
      e.preventDefault();
      setSelectedCategory(null);
    }

    const onSubmit = (e) => {
      e.preventDefault();

      if (title != ""){
        let postData = makePostItem();

        if (editItem != null){
          postData.itemId = editItem.id;
        }

        if (currentImage.file != null){
          uploadImageFile();
        } else {
          saveCategoryData(postData);
        }
      }
    }


    const updateTypeData = () => {
      dispatch(postInsuranceTypeSingle({
        "itemId": editItem.id,
        "langLoc":  selectedLang.value
      }));
      clearData();
    }

    const onEditPriceRow = (e,item) => {
      e.preventDefault();
      setSelectedCategoryEditItem(item);
      setPricesModal(true);
    }

    const onEditFaqRow = (e,item) => {
      e.preventDefault();
      setSelectedCategoryEditItem(item);
      setFaqsModal(true);
    }

    const onEditPackRow = (e,item) => {
      e.preventDefault();
      setSelectedCategoryEditItem(item);
      setPacksModal(true);
    }

    const onDialogPricesModalClose = () => {
      setPricesModal(false);
    }

    const onDialogFaqsModalClose = () => {
      setFaqsModal(false);
    }

    const onDialogPacksModalClose = () => {
      setPacksModal(false);
    }
    

    
    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

    {settingsData.settings.langs.length > 1 ? 
          <Grid item xs={12}>

            <LangPicker
                onLangSelectedChanged={changeLanguage}
            />

          </Grid> : null }
      

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="title"
                    label={trans.table_head_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {updateTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

      
            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="site_link"
                    label={trans.table_head_site_link}
                    value={site_link}
                    type="text"
                    onChange={(e)=> {setSiteLink(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="desc"
                    label={trans.table_head_desc}
                    value={desc}
                    type="text"
                    onChange={(e)=> {updateDesc(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="price_desc"
                    label={trans.table_head_price_desc}
                    value={price_desc}
                    type="text"
                    onChange={(e)=> {setPriceDesc(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="price_title"
                    label={trans.table_head_price_title}
                    value={price_title}
                    type="text"
                    onChange={(e)=> {setPriceTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="packs_title"
                    label={trans.table_head_packs_title}
                    value={packs_title}
                    type="text"
                    onChange={(e)=> {setPacksTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

              <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="button_type">{trans.insurance_category_button_type}</InputLabel>
                  <Select
                          labelId="button_type"
                          id="button_type"
                          value={button_type}
                          label={trans.insurance_category_button_type}
                          onChange={(e) => setButtonType(e.target.value)}>
                          <MenuItem key={`button_type_get_offer`} value={"GET_OFFER"}>{trans.insurance_category_button_type_get_offer}</MenuItem>
                          <MenuItem key={`button_type_compare`} value={"COMPARE"}>{trans.insurance_category_button_type_compare}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="button_link"
                    label={trans.insurance_category_button_link}
                    value={button_link}
                    type="text"
                    onChange={(e)=> {setButtonLink(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>
          

            <Grid item xs={12}>
                <JoditEditor
                          ref={editorDescEl}
                           value={content}
                          config={editorConfigSmall}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => updateContent(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
            </Grid>


          
         
            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_visible}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.table_head_visible}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`menu_item_visible`} value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
          

            <Grid item xs={6}>
              <div className='_flex_row_space'>
                <div>
                  <span>{trans.upload_insurance_type_icon_field}</span>
                      <div style={{marginTop:'15px'}}>
                        <Button color="success" variant="outlined" onClick={()=>fileInputRef.current.click()} >{trans.choose_file}</Button>
                        <input ref={fileInputRef} id="fileupload" type="file" name="file" onChange={(e) => updateIconImageFile(e)}  multiple style={{display:'none'}}/>
                  </div>
                </div>
                <div>
                    {currentImage.url == "" ? 
                  <img style={{maxHeight:'100px',maxWidth:'100%'}} src={img}></img>
                  : <img style={{maxHeight:'100px',maxWidth:'100%'}} src={currentImage.url}></img>}
                      
                </div>
              </div>
                 
            </Grid>

            <Grid container justifyContent="center" >
              <Grid item>
                {selectedCategory != null ?  
                <Button style={{marginTop:'10px', marginBottom:'10px',backgroundColor: 'green'}}  variant="contained"  disabled={props.onSubmitLoading} onClick={onClean}>{trans.cancel_btn}</Button>
                : null }
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={title == ""} onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
            </Grid>
            
            <Grid item xs={12} >

              <div className='_insurance_categories_container'>
                  {insuranceTypeItem != null ? 
                  <Table size="small" style={{overflowX:'auto'}}>
                    <TableHead>
                        <TableRow>
                        {columnData.map(column => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric}
                              padding={column.disablePadding ? "none" : "normal"}
                            >
                                {column.label}
                            </TableCell>
                          );
                        })}
                        <TableCell/>
                        <TableCell/>
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {insuranceTypeItem.categories.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>{row.img_thumb_url == "#" ? null : <img width="40" className='_type_cat_img'  src={row.img_thumb_url}></img>}</TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{`${trans[`status_${row.status}`]}`}</TableCell>
                            <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onEditRow(e,row)}  variant="outlined">{trans.edit_item_title}</Button></TableCell>
                            <TableCell><Button startIcon={<EuroIcon />} size="small" color="secondary" onClick={(e) => onEditPriceRow(e,row)}  variant="outlined">{trans.edit_item_prices}</Button></TableCell>
                            <TableCell><Button startIcon={<QuizIcon />} size="small" color="secondary" onClick={(e) => onEditFaqRow(e,row)}  variant="outlined">{trans.edit_item_faqs}</Button></TableCell>
                            <TableCell><Button startIcon={<Inventory2Icon />} size="small" color="secondary" onClick={(e) => onEditPackRow(e,row)}  variant="outlined">{trans.edit_item_packs}</Button></TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  
                  </Table> : null }

              </div>

            </Grid>

        </Grid>

          
          <FormDialog
              open={pricesModal}
              onClose={onDialogPricesModalClose}
              title={`${trans.edit_item_prices} ${selectedCategoryEditItem != null ? ` ● ${selectedCategoryEditItem.title}` : ''}`}
              body={<EditInsuranceCategoryPricesItem onCategoriesUpdated={updateTypeData} selectedCategoryEditItem={selectedCategoryEditItem} selectedInsuranceTypeItem={editItem}/> }
              // onSubmit={onDialogCategoriesSubmit}
              hasSubmit={false}
              maxWidth={"md"}
          />     

        <FormDialog
              open={faqsModal}
              onClose={onDialogFaqsModalClose}
              title={`${trans.edit_item_faqs} ${selectedCategoryEditItem != null ? ` ● ${selectedCategoryEditItem.title}` : ''}`}
              body={<EditInsuranceCategoryFaqsItem onCategoriesUpdated={updateTypeData} selectedCategoryEditItem={selectedCategoryEditItem} selectedInsuranceTypeItem={editItem}/> }
              // onSubmit={onDialogCategoriesSubmit}
              hasSubmit={false}
              maxWidth={"md"}
          />             

        <FormDialog
              open={packsModal}
              onClose={onDialogPacksModalClose}
              title={`${trans.edit_item_packs} ${selectedCategoryEditItem != null ? ` ● ${selectedCategoryEditItem.title}` : ''}`}
              body={<EditInsuranceCategoryPacksItem onCategoriesUpdated={updateTypeData} selectedCategoryEditItem={selectedCategoryEditItem} selectedInsuranceTypeItem={editItem}/> }
              // onSubmit={onDialogCategoriesSubmit}
              hasSubmit={false}
              maxWidth={"md"}
          />    

      </div>
    );
}

export default EditInsuranceCategoriesItem;
