import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PRIMARY_COLOR, editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EuroIcon from '@mui/icons-material/Euro';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { POST_INSURANCE_TYPE_ADD_CATEGORY, POST_INSURANCE_TYPE_ADD_TAB, POST_INSURANCE_TYPE_REORDER_TAB, POST_INSURANCE_TYPE_DELETE_TAB, POST_INSURANCE_TYPE_EDIT_CATEGORY, POST_INSURANCE_TYPE_EDIT_TAB, POST_INSURANCE_TYPE_SINGLE, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SweetAlert from 'sweetalert2';
import { postInsuranceTypeAddTab, postInsuranceTypeChangeTabOrder, postInsuranceTypeDeleteTab, postInsuranceTypeEditTab, postInsuranceTypeSingle } from '../../redux/insuranceType/action';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';
import { SortableContainer , SortableHandle , SortableElement , arrayMove } from  'react-sortable-hoc'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const EditInsuranceTabsItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const addInsuranceTypeTabData = useSelector(({insuranceTypes}) => insuranceTypes.addInsuranceTypeTabData);
    const editInsuranceTypeTabData = useSelector(({insuranceTypes}) => insuranceTypes.editInsuranceTypeTabData);
    const deleteInsuranceTypeTabData = useSelector(({insuranceTypes}) => insuranceTypes.deleteInsuranceTypeTabData);
    const changeInsuranceTypeTabOrderData =  useSelector(({insuranceTypes}) => insuranceTypes.changeInsuranceTypeTabOrderData);
    const singleInsuranceTypeData = useSelector(({insuranceTypes}) => insuranceTypes.singleInsuranceTypeData);


    const {selectedInsuranceTypeItem} = props;
    
    //HOOKS VARIABLES
    const [selectedTab,setSelectedTab] = useState(null);
    
    const [title,setTitle] = useState("");
    const [desc,setDesc] = useState("");
    const [status,setStatus] = useState(true);
   
    const [typeTabs, setTypeTabs] = useState([]); 
    const [selectedLang,setSelectedLang] = useState(settingsData.settings.langs[0]);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;


    const editorDescEl = useRef(null);

    const makePostItem = () => {

      let postData = {
        "title": title,
        "desc": desc,
        "status": status,
        "lang": selectedLang.value,
        "insuranceTypeId": selectedInsuranceTypeItem.id
      };

      return postData;
    }

    const saveCategoryData = () => {

      let postData = makePostItem();
      if (selectedTab == null){
        console.log(postData);
        dispatch(postInsuranceTypeAddTab(postData))
      } else {
        postData.tabId = selectedTab._id;
        console.log(postData);
        dispatch(postInsuranceTypeEditTab(postData))
      }

    }

    useEffect(() => {
      if (addInsuranceTypeTabData != null){
        if (addInsuranceTypeTabData.status) {
          toast.dismiss();
          toast.success(addInsuranceTypeTabData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onTabsUpdated();

          updateTypeData()

        } else {
          toast.dismiss();
          toast.error(addInsuranceTypeTabData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_ADD_TAB, payload: null});

      } 
    },[addInsuranceTypeTabData]);

    useEffect(() => {
      if (editInsuranceTypeTabData != null){
        if (editInsuranceTypeTabData.status) {
          toast.dismiss();
          toast.success(editInsuranceTypeTabData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onTabsUpdated();

              updateTypeData()
        } else {
          toast.dismiss();
          toast.error(editInsuranceTypeTabData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_EDIT_TAB, payload: null});

      } 
    },[editInsuranceTypeTabData]);

    useEffect(() => {
      if (deleteInsuranceTypeTabData != null){
        if (deleteInsuranceTypeTabData.status) {
          toast.dismiss();
          toast.success(deleteInsuranceTypeTabData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onTabsUpdated();

              updateTypeData()
        } else {
          toast.dismiss();
          toast.error(deleteInsuranceTypeTabData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_DELETE_TAB, payload: null});

      } 
    },[deleteInsuranceTypeTabData]);

    useEffect(() => {
      if (changeInsuranceTypeTabOrderData != null){
        if (changeInsuranceTypeTabOrderData.status) {
              props.onTabsUpdated();
              updateTypeData()
        } 
        dispatch({type: POST_INSURANCE_TYPE_REORDER_TAB, payload: null});
      } 
    },[changeInsuranceTypeTabOrderData]);

    useEffect(() => {
      if (singleInsuranceTypeData != null){
    
        setTypeTabs(singleInsuranceTypeData.tabs);

        dispatch({type: POST_INSURANCE_TYPE_SINGLE, payload: null});
      } 
    },[singleInsuranceTypeData]);




    useEffect(() => {
      if (selectedInsuranceTypeItem != null){
          setTypeTabs(selectedInsuranceTypeItem.tabs);
      } else {
        clearData();
      }
    },[selectedInsuranceTypeItem]);

    useEffect(() => {
      if (selectedTab != null){

          setTitle(selectedTab.title);
          setDesc(selectedTab.desc);
          setStatus(selectedTab.status);
      } else {
        clearData();
      }
    },[selectedTab]);


    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);


    //FUNCTIONS
    const clearData = () => {
        setTitle("");
        setDesc("");
        setStatus(false);
    }
   
    const changeLanguage = (lang) => {
      setSelectedLang(lang);
    }

    const columnData = [
      {id: "title", align: true, disablePadding: false, label:  trans.table_head_pack_title},
      {id: "status", align: true, disablePadding: false, label: trans.pack_status}
    ];
  

    const onEditRow = (e,item) => {
      e.preventDefault();
      setSelectedTab(item);
    }

    const onClean = (e) => {
      e.preventDefault();
      setSelectedTab(null);
    }

    const onSubmit = (e) => {
      e.preventDefault();

      if (title != "" && desc != ""){
        let postData = makePostItem();

        saveCategoryData(postData);
  
      }
    }

    const updateTypeData = () => {
      dispatch(postInsuranceTypeSingle({
        "itemId": selectedInsuranceTypeItem.id,
        "langLoc": selectedLang.value
      }));
      clearData();
      setSelectedTab(null);
    }

    const onDeleteRow = (e,item) => {
      e.preventDefault();
      SweetAlert.fire({
        theme: "dark",
        title:trans.delete_title,
        text:trans.delete_descr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.delete_title,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            deleteItem(item);
        }
      })
    }


    const deleteItem = (item) => {
      dispatch(postInsuranceTypeDeleteTab({
        "insuranceTypeId": selectedInsuranceTypeItem.id,
        "tabId": item._id,
        "langLoc": "el"
      }));
    }

    // Handler for traversing completion, helper arrayMove is used
    const onSortEnd  = ({oldIndex, newIndex}) => {
      const updatedItems = arrayMove(typeTabs, oldIndex, newIndex);
      const updatedItemsWithOrder = updatedItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      setTypeTabs(updatedItemsWithOrder);
      dispatch(postInsuranceTypeChangeTabOrder({"insuranceTypeId": selectedInsuranceTypeItem.id,orderedItems: updatedItemsWithOrder}))
    };


    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

    {settingsData.settings.langs.length > 1 ? 
          <Grid item xs={12}>

            <LangPicker
                onLangSelectedChanged={changeLanguage}
            />

          </Grid> : null }
      

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="pack_title"
                    label={trans.table_head_pack_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="pack_required">{trans.pack_status}</InputLabel>
                  <Select
                          labelId="pack_required"
                          id="visible"
                          value={status}
                          label={trans.pack_status}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`pack_status_true`} value={true}>{trans.pack_status_true}</MenuItem>
                          <MenuItem key={`pack_status_false`} value={false}>{trans.pack_status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
                <JoditEditor
                          ref={editorDescEl}
                           value={desc}
                          config={editorConfigSmall}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setDesc(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={newContent => {}} />
            </Grid>


           
         
            <Grid container justifyContent="center" >
              <Grid item>
                {selectedTab != null ?  
                <Button style={{marginTop:'10px', marginBottom:'10px',backgroundColor: 'green'}}  variant="contained"  disabled={props.onSubmitLoading} onClick={onClean}>{trans.cancel_btn}</Button>
                : null }
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={title == "" || desc == ""} onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
            </Grid>
            
            <Grid item xs={12} >

              <div className='_insurance_categories_container'>
                  {selectedInsuranceTypeItem != null ? 
                  <Table size="small" style={{overflowX:'auto'}}>
                    <TableHead>
                        <TableRow>
                        <TableCell/>
                        {columnData.map(column => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric}
                              padding={column.disablePadding ? "none" : "normal"}
                            >
                                {column.label}
                            </TableCell>
                          );
                        })}
                        <TableCell/>
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBodySortable onSortEnd={(sortEnd) => onSortEnd(sortEnd)} useDragHandle>
                      {typeTabs.map((row,index) => (
                        <SortableRow index={index} key={row._id}>
                            <TableCell><DragHandler /></TableCell> 
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.status == true ? trans.pack_status_true : trans.pack_status_false}</TableCell>
                            <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onEditRow(e,row)}  variant="outlined">{trans.edit_item_title}</Button></TableCell>
                            <TableCell><Button startIcon={<DeleteOutlineIcon />} size="small"  onClick={(e) => onDeleteRow(e,row)}  variant="outlined">{trans.table_delete_button}</Button></TableCell>

                            </SortableRow>
                        ))}
                    </TableBodySortable>
                  
                  </Table> : null }

              </div>

            </Grid>

        </Grid>
      </div>
    );
}

export default EditInsuranceTabsItem;
