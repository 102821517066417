import {combineReducers} from 'redux';


import settings from './settings/reducer';
import auth from './auth/reducer';
import categories from './categories/reducer';
import sources from './sources/reducer';
import upload from './upload/reducer';
import tags from './tags/reducer';
import posts from './posts/reducer';
import socialtemplates from './socialtemplate/reducer';
import users from './users/reducer';
import banners from './banners/reducer';
import notifications from './notifications/reducer';
import countries from './countries/reducer';
import contacts from './contacts/reducer';
import insuranceCompanies from './insuranceCompany/reducer';
import vehicles from './vehicles/reducer';
import admin from './admin/reducer';
import insuranceTypes from './insuranceType/reducer';
import insuranceContract from './insuranceContract/reducer';
import insuranceOffer from './insuranceOffer/reducer';

export default combineReducers({
    settings: settings,
    auth: auth,
    categories: categories,
    sources: sources,
    upload: upload,
    tags: tags,
    posts: posts,
    socialtemplates: socialtemplates,
    users: users,
    banners: banners,
    notifications: notifications,
    countries: countries,
    contacts:contacts,
    insuranceCompanies: insuranceCompanies,
    insuranceTypes: insuranceTypes,
    vehicles: vehicles,
    admin: admin,
    insuranceContract: insuranceContract,
    insuranceOffer: insuranceOffer
  });
