import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PRIMARY_COLOR, editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EuroIcon from '@mui/icons-material/Euro';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import { POST_INSURANCE_TYPE_ADD_CATEGORY, POST_INSURANCE_TYPE_ADD_CATEGORY_PACK, POST_INSURANCE_TYPE_REORDER_CATEGORY_PACK, POST_INSURANCE_TYPE_DELETE_CATEGORY_PACK, POST_INSURANCE_TYPE_EDIT_CATEGORY, POST_INSURANCE_TYPE_EDIT_CATEGORY_PACK, POST_INSURANCE_TYPE_SINGLE, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SweetAlert from 'sweetalert2';
import { postInsuranceTypeAddCategoryPack, postInsuranceTypeChangePackOrder, postInsuranceTypeDeleteCategoryPack, postInsuranceTypeEditCategory, postInsuranceTypeEditCategoryPack, postInsuranceTypeSingle } from '../../redux/insuranceType/action';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';
import { SortableContainer , SortableHandle , SortableElement , arrayMove } from  'react-sortable-hoc'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const EditInsuranceCategoryPacksItem =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const addInsuranceTypeCategoryPackData = useSelector(({insuranceTypes}) => insuranceTypes.addInsuranceTypeCategoryPackData);
    const editInsuranceTypeCategoryPackData = useSelector(({insuranceTypes}) => insuranceTypes.editInsuranceTypeCategoryPackData);
    const deleteInsuranceTypeCategoryPackData = useSelector(({insuranceTypes}) => insuranceTypes.deleteInsuranceTypeCategoryPackData);
    const changeInsuranceTypeCategoryPackOrderData =  useSelector(({insuranceTypes}) => insuranceTypes.changeInsuranceTypeCategoryPackOrderData);
    const singleInsuranceTypeData = useSelector(({insuranceTypes}) => insuranceTypes.singleInsuranceTypeData);


    const uploadImageData = useSelector(({upload}) => upload.uploadImageData);
 
    const {selectedCategoryEditItem,selectedInsuranceTypeItem} = props;
    
    //HOOKS VARIABLES
    const [insuranceTypeCategoryItem,setInsuranceTypeCategoryItem] = useState(null);
    const [selectedPack,setSelectedPack] = useState(null);
    
    const [title,setTitle] = useState("");
    const [desc,setDesc] = useState("");
    const [status,setStatus] = useState(true);
    const [img,setImg] = useState("");
    const [img_thumb,setImgThumb] = useState("");
    const [icon,setIcon] = useState("");
    const [icon_thumb,setIconThumb] = useState("");
   
    const [currentImage, setCurrentImage] = useState({url: "", file: null});
    const [currentIcon, setCurrentIcon] = useState({url: "", file: null});


    const [categoryPacks, setCategoryPacks] = useState([]); 
    const [selectedLang,setSelectedLang] = useState(settingsData.settings.langs[0]);

    const [uploadedImage,setUploadedImage] = useState(null);
    const [uploadedIcon,setUploadedIcon] = useState(null);



    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;


    const fileImageInputRef=useRef();
    const fileIconInputRef=useRef();
    
  

    const makePostItem = () => {

      let postData = {
        "title": title,
        "desc": desc,
        "status": status,
        "lang": selectedLang.value,
        "categoryId": selectedCategoryEditItem.id,
        "insuranceTypeId": selectedInsuranceTypeItem.id
      };

      if (uploadedImage != null){
        postData.img = uploadedImage.img;
        postData.img_thumb = uploadedImage.img_thumb;
      }

      if (uploadedIcon != null){
        postData.icon = uploadedIcon.img;
        postData.icon_thumb = uploadedIcon.img_thumb;
      }


      return postData;
    }

    const saveCategoryData = () => {

      let postData = makePostItem();
      if (selectedPack == null){
        console.log(postData);
        dispatch(postInsuranceTypeAddCategoryPack(postData))
      } else {
        postData.packId = selectedPack._id;
        console.log(postData);
        dispatch(postInsuranceTypeEditCategoryPack(postData))
      }

    }

    useEffect(() => {
      if (addInsuranceTypeCategoryPackData != null){
        if (addInsuranceTypeCategoryPackData.status) {
          toast.dismiss();
          toast.success(addInsuranceTypeCategoryPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onCategoriesUpdated();

          updateTypeData()

        } else {
          toast.dismiss();
          toast.error(addInsuranceTypeCategoryPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_ADD_CATEGORY_PACK, payload: null});

      } 
    },[addInsuranceTypeCategoryPackData]);

    useEffect(() => {
      if (editInsuranceTypeCategoryPackData != null){
        if (editInsuranceTypeCategoryPackData.status) {
          toast.dismiss();
          toast.success(editInsuranceTypeCategoryPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onCategoriesUpdated();

              updateTypeData()
        } else {
          toast.dismiss();
          toast.error(editInsuranceTypeCategoryPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_EDIT_CATEGORY_PACK, payload: null});

      } 
    },[editInsuranceTypeCategoryPackData]);

    useEffect(() => {
      if (deleteInsuranceTypeCategoryPackData != null){
        if (deleteInsuranceTypeCategoryPackData.status) {
          toast.dismiss();
          toast.success(deleteInsuranceTypeCategoryPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onCategoriesUpdated();

              updateTypeData()
        } else {
          toast.dismiss();
          toast.error(deleteInsuranceTypeCategoryPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_INSURANCE_TYPE_DELETE_CATEGORY_PACK, payload: null});

      } 
    },[deleteInsuranceTypeCategoryPackData]);

    useEffect(() => {
      if (changeInsuranceTypeCategoryPackOrderData != null){
        if (changeInsuranceTypeCategoryPackOrderData.status) {
              props.onCategoriesUpdated();
              updateTypeData()
        } 
        dispatch({type: POST_INSURANCE_TYPE_REORDER_CATEGORY_PACK, payload: null});
      } 
    },[changeInsuranceTypeCategoryPackOrderData]);

    useEffect(() => {
      if (singleInsuranceTypeData != null){
    
        singleInsuranceTypeData.categories.forEach(insuranceTypeCategory => {
                if (insuranceTypeCategory.id == selectedCategoryEditItem.id){
                  setInsuranceTypeCategoryItem(insuranceTypeCategory);
                  setCategoryPacks(insuranceTypeCategory.packs);
                }
        });

        dispatch({type: POST_INSURANCE_TYPE_SINGLE, payload: null});
      } 
    },[singleInsuranceTypeData]);




    useEffect(() => {
      if (selectedCategoryEditItem != null){

          setInsuranceTypeCategoryItem(selectedCategoryEditItem);
          setCategoryPacks(selectedCategoryEditItem.packs);
      } else {
        clearData();
      }
    },[selectedCategoryEditItem]);

    useEffect(() => {
      if (selectedPack != null){

          setTitle(selectedPack.title);
          setDesc(selectedPack.desc);
          setStatus(selectedPack.status);
          setImg(selectedPack.img_url);
          setImgThumb(selectedPack.img_thumb_url);
          setIcon(selectedPack.icon_url);
          setIconThumb(selectedPack.icon_thumb_url);

      } else {
        clearData();
      }
    },[selectedPack]);


    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    useEffect(() => { 
      // console.log("uploadImageData",uploadImageData);
       if (uploadImageData != null){
        if (uploadImageData.path == "insurnacetypecategoriespacks/images" || uploadImageData.path == "insurnacetypecategoriespacks/icons"){
         if (uploadImageData.status) {
           toast.dismiss();
           toast.success(uploadImageData.message, {
               position: toast.POSITION.TOP_RIGHT
               });
             
               if (uploadImageData.path == "insurnacetypecategoriespacks/images"){
                setUploadedImage(uploadImageData);
                if (currentIcon.file != null){
                  console.log("UPLOADING_ICON_FILE");
                  uploadIconFile();
                }
               } else {
                if (uploadImageData.path == "insurnacetypecategoriespacks/icons"){
                  setUploadedIcon(uploadImageData);
                }
               }
               
         } else {
           toast.dismiss();
           toast.error(uploadImageData.message, {
               position: toast.POSITION.TOP_RIGHT
               });
         }
         dispatch({type: POST_UPLOAD_IMAGE, payload: null});
       }
      }
     }, [uploadImageData]);

     useEffect(() => { 

        if (uploadedImage != null){
          if (currentIcon.file == null){
            saveCategoryData();
          }
        }

     }, [uploadedImage]);

     useEffect(() => { 

      if (uploadedIcon != null){
          saveCategoryData();
      }

   }, [uploadedIcon]);

   

  //   useEffect(() => {
  //     if (selectedLang){
  //       setLanguageItems();
  //     }
  // }, [selectedLang]);

    //FUNCTIONS
    const clearData = () => {
        setTitle("");
        setDesc("");
        setStatus(false);
        setImg("");
        setImgThumb("");
        setIcon("");
        setIconThumb("");
        setCurrentIcon({url: "", file: null});
        setCurrentImage({url: "", file: null});
        setUploadedImage(null);
        setUploadedIcon(null);
    }
   
    const changeLanguage = (lang) => {
      setSelectedLang(lang);
    }

    const columnData = [
      {id: "title", align: true, disablePadding: false, label:  trans.table_head_pack_title},
      {id: "status", align: true, disablePadding: false, label: trans.pack_status}
    ];
  

    const onEditRow = (e,item) => {
      e.preventDefault();
      setSelectedPack(item);
    }

    const onClean = (e) => {
      e.preventDefault();
      setSelectedPack(null);
    }

    const onSubmit = (e) => {
      e.preventDefault();

      if (title != "" && desc != ""){
        let postData = makePostItem();

        if (currentImage.file != null){
          uploadImageFile();
        } else {
          if (currentIcon.file != null){
            uploadIconFile();
          } else {
            saveCategoryData(postData);
          }

        }
  
      }
    }


    const uploadImageFile = () => {
      if (currentImage.file != null){
        var formData = new FormData();
        formData.append('path', "insurnacetypecategoriespacks/images");
        formData.append("image_file", currentImage.file);
        dispatch(postUploadImage(formData))
      }
    }


    const uploadIconFile = () => {
      if (currentIcon.file != null){
        var formData = new FormData();
        formData.append('path', "insurnacetypecategoriespacks/icons");
        formData.append("image_file", currentIcon.file);
        dispatch(postUploadImage(formData))
      }
    }


    const updateTypeData = () => {
      dispatch(postInsuranceTypeSingle({
        "itemId": selectedInsuranceTypeItem.id,
        "langLoc": selectedLang.value
      }));
      clearData();
      setSelectedPack(null);
    }

    const onDeleteRow = (e,item) => {
      e.preventDefault();
      SweetAlert.fire({
        theme: "dark",
        title:trans.delete_title,
        text:trans.delete_descr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.delete_title,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            deleteItem(item);
        }
      })
    }


    const deleteItem = (item) => {
      dispatch(postInsuranceTypeDeleteCategoryPack({
        "insuranceTypeId": selectedInsuranceTypeItem.id,
        "categoryId": selectedCategoryEditItem.id,
        "packId": item._id,
        "langLoc": "el"
      }));
    }

    // Handler for traversing completion, helper arrayMove is used
    const onSortEnd  = ({oldIndex, newIndex}) => {
      const updatedItems = arrayMove(categoryPacks, oldIndex, newIndex);
      const updatedItemsWithOrder = updatedItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      setCategoryPacks(updatedItemsWithOrder);
      dispatch(postInsuranceTypeChangePackOrder({"insuranceTypeId": selectedInsuranceTypeItem.id,
      "categoryId": selectedCategoryEditItem.id,orderedItems: updatedItemsWithOrder}))
    };

    const updateImageFile = (event) => {
      if (event.target.files.length != 0 ){
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
        let file = event.target.files[0];
  
        let reader = new FileReader();
        reader.onloadend = () => {
          setCurrentImage({url:reader.result, file: file});
        }
        reader.readAsDataURL(file);
      }
    }


    const updateIconFile = (event) => {
      if (event.target.files.length != 0 ){
        var mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          return;
        }
        let file = event.target.files[0];
  
        let reader = new FileReader();
        reader.onloadend = () => {
          setCurrentIcon({url:reader.result, file: file});
        }
        reader.readAsDataURL(file);
      }
    }

    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

    {settingsData.settings.langs.length > 1 ? 
          <Grid item xs={12}>

            <LangPicker
                onLangSelectedChanged={changeLanguage}
            />

          </Grid> : null }
      

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="pack_title"
                    label={trans.table_head_pack_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={6} >
              <FormControl fullWidth>
                  <InputLabel id="pack_required">{trans.pack_status}</InputLabel>
                  <Select
                          labelId="pack_required"
                          id="visible"
                          value={status}
                          label={trans.pack_status}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`pack_status_true`} value={true}>{trans.pack_status_true}</MenuItem>
                          <MenuItem key={`pack_status_false`} value={false}>{trans.pack_status_false}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="pack_desc"
                    label={trans.table_head_pack_desc}
                    value={desc}
                    type="text"
                    onChange={(e)=> {setDesc(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>


            <Grid item xs={6}>
              <div className='_flex_row_space'>
                <div>
                  <span>{trans.upload_insurance_type_pack_image_field}</span>
                      <div style={{marginTop:'15px'}}>
                        <Button color="success" variant="outlined" onClick={()=>fileImageInputRef.current.click()} >{trans.choose_file}</Button>
                        <input ref={fileImageInputRef} id="imagePackUpload" type="file" name="file" onChange={(e) => updateImageFile(e)}  multiple style={{display:'none'}}/>
                  </div>
                </div>
                <div>
                    {currentImage.url == "" ? 
                  <img style={{maxHeight:'100px',maxWidth:'100%'}} src={img}></img>
                  : <img style={{maxHeight:'100px',maxWidth:'100%'}} src={currentImage.url}></img>}
                      
                </div>
              </div>
                 
            </Grid>
           
            <Grid item xs={6}>
              <div className='_flex_row_space'>
                <div>
                  <span>{trans.upload_insurance_type_pack_icon_field}</span>
                      <div style={{marginTop:'15px'}}>
                        <Button color="success" variant="outlined" onClick={()=>fileIconInputRef.current.click()} >{trans.choose_file}</Button>
                        <input ref={fileIconInputRef} id="iconPackUpload" type="file" name="file" onChange={(e) => updateIconFile(e)}  multiple style={{display:'none'}}/>
                  </div>
                </div>
                <div>
                    {currentIcon.url == "" ? 
                  <img style={{maxHeight:'100px',maxWidth:'100%'}} src={icon}></img>
                  : <img style={{maxHeight:'100px',maxWidth:'100%'}} src={currentIcon.url}></img>}
                      
                </div>
              </div>
                 
            </Grid>
   
            <Grid container justifyContent="center" >
              <Grid item>
                {selectedPack != null ?  
                <Button style={{marginTop:'10px', marginBottom:'10px',backgroundColor: 'green'}}  variant="contained"  disabled={props.onSubmitLoading} onClick={onClean}>{trans.cancel_btn}</Button>
                : null }
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={title == "" || desc == ""} onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
            </Grid>
            
            <Grid item xs={12} >

              <div className='_insurance_categories_container'>
                  {insuranceTypeCategoryItem != null ? 
                  <Table size="small" style={{overflowX:'auto'}}>
                    <TableHead>
                        <TableRow>
                        <TableCell/>
                        <TableCell/>
                        <TableCell/>
                        {columnData.map(column => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric}
                              padding={column.disablePadding ? "none" : "normal"}
                            >
                                {column.label}
                            </TableCell>
                          );
                        })}
                        <TableCell/>
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBodySortable onSortEnd={(sortEnd) => onSortEnd(sortEnd)} useDragHandle>
                      {categoryPacks.map((row,index) => (
                        <SortableRow index={index} key={row._id}>
                            <TableCell><DragHandler /></TableCell> 
                            <TableCell>{<img src={row.img_thumb_url} height="20px"></img>}</TableCell>
                            <TableCell>{<img src={row.icon_thumb_url} height="20px"></img>}</TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.status == true ? trans.pack_status_true : trans.pack_status_false}</TableCell>
                            <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onEditRow(e,row)}  variant="outlined">{trans.edit_item_title}</Button></TableCell>
                            <TableCell><Button startIcon={<DeleteOutlineIcon />} size="small"  onClick={(e) => onDeleteRow(e,row)}  variant="outlined">{trans.table_delete_button}</Button></TableCell>

                            </SortableRow>
                        ))}
                    </TableBodySortable>
                  
                  </Table> : null }

              </div>

            </Grid>

        </Grid>
      </div>
    );
}

export default EditInsuranceCategoryPacksItem;
