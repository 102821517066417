import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PRIMARY_COLOR, editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import EuroIcon from '@mui/icons-material/Euro';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage, postUploadSingleOfferPack } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { postInsuranceOfferAddPack, postInsuranceOfferDeleteOfferPack, postInsuranceOfferEditPack, postInsuranceOfferReorderOfferPacks, postInsuranceOfferSingle } from '../../redux/insuranceOffer/action';
import FormDialog from '../global/formDialog';
import { POST_INSURANCE_OFFER_ADD_FILE, POST_INSURANCE_OFFER_DELETE_FILE, POST_INSURANCE_OFFER_EDIT_FILE, POST_INSURANCE_OFFER_REORDER_FILES, POST_INSURANCE_OFFER_SINGLE, POST_UPLOAD_FILE, POST_UPLOAD_IMAGE } from '../../redux/actionTypes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SweetAlert from 'sweetalert2';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';
import {  arrayMove } from  'react-sortable-hoc'

import { postInsuranceCompaniesAll } from '../../redux/insuranceCompany/action';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();



const EditInsuranceOfferOfferPacks =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const countriesData = useSelector(({settings}) => settings.countriesData);
    const uploadOfferPackData = useSelector(({upload}) => upload.uploadOfferPackData);
    const addInsuranceOfferOfferPackData  = useSelector(({insuranceOffer}) => insuranceOffer.addInsuranceOfferOfferPackData);
    const editInsuranceOfferOfferPackData = useSelector(({insuranceOffer}) => insuranceOffer.editInsuranceOfferOfferPackData);
    const deleteInsuranceOfferPackData = useSelector(({insuranceOffer}) => insuranceOffer.deleteInsuranceOfferPackData);
    const singleInsuranceOfferData = useSelector(({insuranceOffer}) => insuranceOffer.singleInsuranceOfferData);
    const reorderOfferPacksInsuranceOfferData = useSelector(({insuranceOffer}) => insuranceOffer.reorderOfferPacksInsuranceOfferData);
    const insuranceCompaniesData = useSelector(({insuranceCompanies}) => insuranceCompanies.insuranceCompaniesData);


    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [insuranceOfferItem,setInsuranceOfferItem] = useState(null);
    const [title, setTitle] = useState("");
    const [insuranceCompany, setInsuranceCompany] = useState("");
    const [covers, setCovers] = useState("");
    const [exemptions, setExemptions] = useState("");
    const [comment, setComment] = useState("");
    const [durationString, setDurationString] = useState("");
    const [cost, setCost] = useState();
    const [status,setStatus] = useState(true);
    

    const [currentOfferPack, setCurrentOfferPack] = useState({url: "", file: null});
    const [isRequesting,setIsRequesting] = useState(false);

    const [selectedLang,setSelectedLang] = useState(settingsData.settings.langs[0]);
 
    const [selectedOfferPack,setSelectedOfferPack] = useState(null);

    const [selectedOfferPackEditItem,setSelectedOfferPackEditItem] = useState(null);

    const [offerOfferPacks, setOfferOfferPacks] = useState([]);

    const [companiesArr,setCompaniesArr] = useState([]);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
    useEffect(() => { //ONLOAD
      getCompanyData();
    }, []);

    const getCompanyData = () => {
      let postData = {
        fromApp: true
      };
  
      dispatch(postInsuranceCompaniesAll(postData)); 
    }
  
    const makePostItem = () => {

      let postData = {
        "title": title,
        "insuranceCompany": insuranceCompany,
        "covers": covers,
        "exemptions": exemptions,
        "comment": comment,
        "durationString": durationString,
        "cost": cost,
        "status": status
      };

      return postData;
    }

    const saveOfferPackData = (postData) => {
      if (selectedOfferPack == null){
        console.log(postData);
        dispatch(postInsuranceOfferAddPack(postData))
      } else {
        console.log(selectedOfferPack);
        postData.packId = selectedOfferPack.id;
        console.log(postData);
        dispatch(postInsuranceOfferEditPack(postData))
      }

    }

    useEffect(() => {
      if (addInsuranceOfferOfferPackData != null){
        if (addInsuranceOfferOfferPackData.status) {
          toast.dismiss();
          toast.success(addInsuranceOfferOfferPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onOfferPacksUpdated();

          updateTypeData()

          setIsRequesting(false);

        } else {
          toast.dismiss();
          toast.error(addInsuranceOfferOfferPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_INSURANCE_OFFER_ADD_FILE, payload: null});

      } 
    },[addInsuranceOfferOfferPackData]);

    useEffect(() => {
      if (editInsuranceOfferOfferPackData != null){
        if (editInsuranceOfferOfferPackData.status) {
          toast.dismiss();
          toast.success(editInsuranceOfferOfferPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onOfferPacksUpdated();

              updateTypeData()

              setIsRequesting(false);
        } else {
          toast.dismiss();
          toast.error(editInsuranceOfferOfferPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_INSURANCE_OFFER_EDIT_FILE, payload: null});

      } 
    },[editInsuranceOfferOfferPackData]);

    useEffect(() => {
      if (deleteInsuranceOfferPackData != null){
        if (deleteInsuranceOfferPackData.status) {
          toast.dismiss();
          toast.success(deleteInsuranceOfferPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onOfferPacksUpdated();

              updateTypeData()

              setIsRequesting(false);
        } else {
          toast.dismiss();
          toast.error(deleteInsuranceOfferPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_INSURANCE_OFFER_DELETE_FILE, payload: null});

      } 
    },[deleteInsuranceOfferPackData]);

    useEffect(() => {
      if (reorderOfferPacksInsuranceOfferData != null){
        if (reorderOfferPacksInsuranceOfferData.status) {
          toast.dismiss();
          toast.success(reorderOfferPacksInsuranceOfferData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

              props.onOfferPacksUpdated();

              updateTypeData()

              setIsRequesting(false);
        } else {
          toast.dismiss();
          toast.error(reorderOfferPacksInsuranceOfferData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_INSURANCE_OFFER_REORDER_FILES, payload: null});

      } 
    },[reorderOfferPacksInsuranceOfferData]);

    

    useEffect(() => {
      if (singleInsuranceOfferData != null){
    
        setInsuranceOfferItem(singleInsuranceOfferData)
        setOfferOfferPacks(singleInsuranceOfferData.offerPacks);

        dispatch({type: POST_INSURANCE_OFFER_SINGLE, payload: null});
      } 
    },[singleInsuranceOfferData]);



    useEffect(() => {
      if (editItem != null){

          setInsuranceOfferItem(editItem);
          setOfferOfferPacks(editItem.offerPacks);

      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
      if (selectedOfferPack != null){

          setTitle(selectedOfferPack.title);
          setDurationString(selectedOfferPack.durationString);
          setExemptions(selectedOfferPack.exemptions);
          setComment(selectedOfferPack.comment);
          setCost(selectedOfferPack.cost);
          setCovers(selectedOfferPack.covers);
          setInsuranceCompany(selectedOfferPack.insuranceCompany.id);
          setStatus(selectedOfferPack.status);

          // setLanguageItems();

      } else {
        clearData();
      }
    },[selectedOfferPack]);

    useEffect(() => { 
     // console.log("uploadOfferPackData",uploadOfferPackData);
      if (uploadOfferPackData != null){
        if (uploadOfferPackData.status) {
          toast.dismiss();
          toast.success(uploadOfferPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
             
              let postData = makePostItem();

              if (editItem != null){
                postData.itemId = editItem.id;
              }

              saveOfferPackData(postData);
        } else {
          toast.dismiss();
          toast.error(uploadOfferPackData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
        }
        dispatch({type: POST_UPLOAD_FILE, payload: null});
      }
    }, [uploadOfferPackData]);

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);

    useEffect(() => {
      if (selectedLang){
        // setLanguageItems();
      }
  }, [selectedLang]);


  useEffect(() => { 
    if (insuranceCompaniesData != null){
      setCompaniesArr(insuranceCompaniesData.insurance_companies);
    }
  }, [insuranceCompaniesData]);

    //FUNCTIONS
    const clearData = () => {
        setTitle("");
        setDurationString("");
        setExemptions("");
        setComment("");
        setCost(0);
        setCovers("");
        setInsuranceCompany("");
        setStatus(true);
        setCurrentOfferPack({url: "",file: null})
        setSelectedOfferPack(null);
    }

   
    const changeLanguage = (lang) => {
      setSelectedLang(lang);
    }

    const columnData = [
      {id: "title", align: true, disablePadding: false, label:  trans.table_head_title},
      {id: "durationString", align: true, disablePadding: false, label:  trans.table_head_offer_durationString},
      {id: "table_head_offer_cost", align: true, disablePadding: false, label:  trans.table_head_offer_cost},
      {id: "status", align: true, disablePadding: false, label: trans.table_head_status},
    ];
  

    const onEditRow = (e,item) => {
      e.preventDefault();
      setSelectedOfferPack(item);

    }

    const onClean = (e) => {
      e.preventDefault();
      setSelectedOfferPack(null);
    }

    const onSubmit = (e) => {
      e.preventDefault();

      if (isRequesting){
        return;
      }

      setIsRequesting(true);
      
     
        let postData = makePostItem();

        if (editItem != null){
          postData.itemId = editItem.id;
        }

        saveOfferPackData(postData);
        
      
    }


    const updateTypeData = () => {
      dispatch(postInsuranceOfferSingle({
        "itemId": editItem.id,
        "langLoc":  selectedLang.value
      }));
      clearData();
    }

    const onDeleteRow = (e,item) => {
      e.preventDefault();
      SweetAlert.fire({
        theme: "dark",
        title:trans.delete_title,
        text:trans.delete_descr,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText:trans.delete_title,
        cancelButtonText: trans.cancel_btn,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            //OK
            deleteItem(item);
        }
      })
    }

    const deleteItem = (item) => {
      dispatch(postInsuranceOfferDeleteOfferPack({
        "itemId": editItem.id,
        "packId": item.id,
        "langLoc": "el"
      }));
    }
 
    const onSortEnd  = ({oldIndex, newIndex}) => {
      const updatedItems = arrayMove(offerOfferPacks, oldIndex, newIndex);
      const updatedItemsWithOrder = updatedItems.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      setOfferOfferPacks(updatedItemsWithOrder);
      dispatch(postInsuranceOfferReorderOfferPacks({"offerId": editItem.id,orderedItems: updatedItemsWithOrder}))
    };
    
    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

    {settingsData.settings.langs.length > 1 ? 
          <Grid item xs={12}>

            <LangPicker
                onLangSelectedChanged={changeLanguage}
            />

          </Grid> : null }
      

            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="title"
                    label={trans.table_head_title}
                    value={title}
                    type="text"
                    onChange={(e)=> {setTitle(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

          
            <Grid item xs={6}>
              <TextField
                    margin="dense"
                    id="durationString"
                    label={trans.table_head_offer_durationString}
                    value={durationString}
                    type="text"
                    onChange={(e)=> {setDurationString(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={4}>
              <TextField
                    margin="dense"
                    id="exemptions"
                    label={trans.table_head_exemptions}
                    value={exemptions}
                    type="text"
                    onChange={(e)=> {setExemptions(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

          
            <Grid item xs={4}>
              <TextField
                    margin="dense"
                    id="comment"
                    label={trans.table_head_offer_comment}
                    value={comment}
                    type="text"
                    onChange={(e)=> {setComment(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>

            <Grid item xs={4}>
              <TextField
                    margin="dense"
                    id="cost"
                    label={trans.table_head_offer_cost}
                    value={cost}
                    type="number"
                    onChange={(e)=> {setCost(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>


            <Grid item xs={12}>
              <TextField
                    margin="dense"
                    id="covers"
                    label={trans.table_head_insurance_covers}
                    value={covers}
                    type="text"
                    onChange={(e)=> {setCovers(e.target.value)}}
                    fullWidth
                    variant="standard" />
            </Grid>


         
            <Grid item xs={6} >
              <br/>
              <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.table_head_visible}</InputLabel>
                  <Select
                          labelId="visible_state"
                          id="visible"
                          value={status}
                          label={trans.table_head_visible}
                          onChange={(e) => setStatus(e.target.value)}>
                          <MenuItem key={`menu_item_visible`} value={true}>{trans.table_head_visible}</MenuItem>
                          <MenuItem key={`menu_item_hidden`} value={false}>{trans.table_head_hidden}</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
          


         <Grid item xs={6}>
           <br/>
            <FormControl fullWidth>
                    <InputLabel id="table_head_insurance_company">{trans.table_head_insurance_company}</InputLabel>
                    <Select
                            labelId="table_head_insurance_company"
                            id="table_head_insurance_company"
                            value={insuranceCompany}
                            label={trans.insurance_contract_state}
                            onChange={(e) => setInsuranceCompany(e.target.value)}>
                            {companiesArr.map(element => {
                                return  <MenuItem value={element.id}><div className='_flex_row'> 
                                    <img src={element.img_thumb_url} width="40px" height="20px" style={{objectFit: 'contain'}} />
                                    <div style={{width: '10px'}}></div>
                                    {element.title}
                                  </div></MenuItem>
                              })
                              }
                    </Select>
            </FormControl>
          </Grid>
    
           <Grid item xs={12}>
            <div>
              <br/>
              <br/>
            </div>
           </Grid>

            <Grid container justifyContent="center" >
              <Grid item>
                {selectedOfferPack != null ?  
                <Button style={{marginTop:'10px', marginBottom:'10px',backgroundColor: 'green'}}  variant="contained"  disabled={props.onSubmitLoading} onClick={onClean}>{trans.cancel_btn}</Button>
                : null }
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={(title == "" || durationString == "" || insuranceCompany == "") || isRequesting == true}  onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
            </Grid>
            
            <Grid item xs={12} >

              <div className='_insurance_files_container'>
                  {insuranceOfferItem != null ? 
                  <Table size="small" style={{overflowX:'auto'}}>
                    <TableHead>
                  
                        <TableRow>
                        <TableCell/>
                        {columnData.map(column => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.numeric}
                              padding={column.disablePadding ? "none" : "normal"}
                            >
                                {column.label}
                            </TableCell>
                          );
                        })}
                        <TableCell/>
                        <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBodySortable onSortEnd={(sortEnd) => onSortEnd(sortEnd)} useDragHandle>
                        {offerOfferPacks.map((row,index) => (
                          <SortableRow index={index} key={row.id}>
                            <TableCell><DragHandler /></TableCell> 
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.durationString}</TableCell>
                            <TableCell>{row.cost}€</TableCell>
                            <TableCell>{`${trans[`status_${row.status}`]}`}</TableCell>
                            <TableCell><Button startIcon={<HowToRegIcon />} size="small" color="secondary" onClick={(e) => onEditRow(e,row)}  variant="outlined">{trans.edit_item_title}</Button></TableCell>
                            <TableCell>
                              <Button
                                startIcon={<DeleteOutlineIcon />}
                                size="small"
                                onClick={(e) => onDeleteRow(e, row)}
                                variant="outlined"
                              >
                                {trans.table_delete_button}
                              </Button>
                            </TableCell>
                            </SortableRow>
                        ))}
                      </TableBodySortable>
                  
                  </Table> : null }

              </div>

            </Grid>

        </Grid>

        

      </div>
    );
}

export default EditInsuranceOfferOfferPacks;
