import { API_URL } from '../../constants';
import axios from '../../helpers/Api'


export async function getChatRooms(postdata) {
  try {
    const response = await axios.post('/chat/room-list', postdata);
    return response.data;
  } catch (error) {
    throw error;
  }
}


export async function createChatRoom(postdata) {
  try {
    const response = await axios.post('/chat/create-room', postdata);
    return response.data;
  } catch (error) {
    throw error;
  }
}


export async function fetchInitialMessages(postData) {
  try {
    const response = await axios.post('/chat/messages', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function sendChatMessage(postData) {
  try {
    const response = await axios.post('/chat/send', postData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteMessages(postData) {
  try {
    const response = await axios.post('/chat/deleteMessage', 
      postData,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUnreadMessage(postdata) {
  try {
    const response = await axios.post('/chat/getUnreadMessage', postdata);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function changeAllRead(postdata) {
  try {
    const response = await axios.post('/chat/changeAllRead', postdata);
    return response.data;
  } catch (error) {
    throw error;
  }
}