import Cookies from 'js-cookie';


export const storeData = (key,value) => {
  Cookies.set(key, value)
}

export const getData = (key) => {
  try {
  return Cookies.get(key)
} catch (ex) {
  //console.log(ex);
  return null;
}
}
