import React, { useState,useEffect,useRef } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PRIMARY_COLOR, editorConfig, editorConfigSmall } from '../../constants';
import {
  createFilterOptions
} from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import EuroIcon from '@mui/icons-material/Euro';
import QuizIcon from '@mui/icons-material/Quiz';
import { postUploadImage, postUploadSingleFile } from '../../redux/upload/action';
import { toast } from 'react-toastify';
import JoditEditor from "jodit-react";
import LangPicker from '../global/LangPicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { getLocalDate } from '../../helpers/General';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { postInsuranceContractAddFile, postInsuranceContractDeleteFile, postInsuranceContractEditFile, postInsuranceContractReorderFiles, postInsuranceContractSingle } from '../../redux/insuranceContract/action';
import FormDialog from '../global/formDialog';
import { POST_USER_CHANGE_ACCESS, POST_USER_CHANGE_PASSWORD, POST_USER_SINGLE } from '../../redux/actionTypes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SweetAlert from 'sweetalert2';
import TableBodySortable from '../global/TableBodySortable';
import DragHandler from '../global/DragHandler';
import SortableRow from '../global/SortableRow';
import {  arrayMove } from  'react-sortable-hoc'
import { postAddContactOption, postChangeUserAccess, postChangeUserPassword, postDeleteContactOption, postUserSingle } from '../../redux/auth/action';
import { Checkbox } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filter = createFilterOptions();



const EditUserAcccess =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);

    const changeUserAccessData  = useSelector(({auth}) => auth.changeUserAccessData);
    const userSingleData = useSelector(({auth}) => auth.userSingleData);
   
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [isAdmin,setIsAdmin] = useState(false);
    
    const [isRequesting,setIsRequesting] = useState(false);
    const [userItem,setUserItem] = useState(null);

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
    
    const makePostItem = () => {

      let postData = {
        "isAdmin": isAdmin,
        "userId": editItem.id,
      };

      return postData;
    }


    useEffect(() => {
      if (changeUserAccessData != null){
        if (changeUserAccessData.status) {
          toast.dismiss();
          toast.success(changeUserAccessData.message, {
              position: toast.POSITION.TOP_RIGHT
              });

          props.onAccessUpdated();

          updateTypeData()

          setIsRequesting(false);

        } else {
          toast.dismiss();
          toast.error(changeUserAccessData.message, {
              position: toast.POSITION.TOP_RIGHT
              });
              setIsRequesting(false);
        }
        dispatch({type: POST_USER_CHANGE_ACCESS, payload: null});

      } 
    },[changeUserAccessData]);


    useEffect(() => {
      if (userSingleData != null){
    
        setUserItem(userSingleData);

        setIsAdmin(userSingleData.userInfo.isAdmin);

        dispatch({type: POST_USER_SINGLE, payload: null});
      } 
    },[userSingleData]);


    useEffect(() => {
      if (editItem != null){
    
        setUserItem(editItem);
        setIsAdmin(editItem.isAdmin);

      } 
    },[editItem]);
  

    useEffect(() => {
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);


    //FUNCTIONS
    const clearData = () => {
        setIsAdmin(false);
    }




    const onSubmit = (e) => {
      e.preventDefault();

      if (isRequesting){
        return;
      }

      setIsRequesting(true);

      let postData = makePostItem();

      dispatch(postChangeUserAccess(postData));
    }


    const updateTypeData = () => {
      dispatch(postUserSingle({
        "userId": editItem.id,
        "langLoc":  "el"
      }));
      clearData();
    }

   
    //UI
    return (
      <div>
        <br/>
        <Grid container spacing={2} alignItems="center">

        <Grid item xs={12}>
          <div>
          <span>{trans.user_access_desc}</span>
          <br/>
          <br/>
          </div>
              
            </Grid>

          

            <Grid item xs={12}>
              <FormControl fullWidth>
                    <InputLabel id="isAdmin">{trans.table_head_account_status}</InputLabel>
                    <Select
                        labelId="isAdmin"
                        id="isAdmin"
                        value={isAdmin}
                        label={trans.user_access_hint}
                        onChange={(e) => setIsAdmin(e.target.value)}>
                        <MenuItem key={"user_access_admin"} value={true}>{trans.user_access_admin}</MenuItem>
                        <MenuItem key={"user_access_user"} value={false}>{trans.user_access_user}</MenuItem>
                    </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12}>
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={ toSendEmail}
                  onChange={(e,value) => setToSendEmail(value)}
                />
                <span>{trans.to_send_email}</span>
              </div>
             
            </Grid> */}
            
        

            <Grid container justifyContent="center" >
              <Grid item>
               <br/>
                <Button style={{marginLeft: '20px',marginTop:'10px', marginBottom:'10px'}} variant="contained" disabled={isRequesting == true}  onClick={onSubmit}>{trans.submit_btn}</Button>
              </Grid>
             
            </Grid>
         

        </Grid>

        

      </div>
    );
}

export default EditUserAcccess;
