import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {useSelector,useDispatch} from "react-redux";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import slugify from  'slugify';
import { SLUG_OPTIONS } from '../../constants';
import { postTagsAll, postTagsAllPick } from '../../redux/tags/action';
import Autocomplete, {
  createFilterOptions
} from '@mui/material/Autocomplete';

import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();

const AddTag =(props)=> {

    //SELECTORS
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const tagsPickData = useSelector(({tags}) => tags.tagsPickData);
    const {editItem} = props;
    
    //HOOKS VARIABLES
    const [name,setName] = useState("");
    const [slug,setSlug] = useState("");
    const [defaultTags, setDefaultTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTagKeyword,setSerachTagKeyword] = useState("");
    

    //VARIABLES
    const dispatch = useDispatch();
    const trans = settingsData.trans;
 
    //HOOKS
    useEffect(() => {
      getTags();
    },[]);

    useEffect(() => {
      if (editItem != null){
          setName(editItem.name);
          setDefaultTags(editItem.defaultTags);
          setSlug(editItem.singleSlug);
      } else {
        clearData();
      }
    },[editItem]);

    useEffect(() => {
        if (props.toGetData){
          let tagsIds = selectedTags.map((value) => value.id);

            let postData = {name,slug,defaultTags:tagsIds};
            props.onSubmitData(postData)
        }
      }, [props.toGetData]);

    useEffect(() => {
      
        if (props.toClearData){
            clearData();
        }
    }, [props.toClearData]);


    useEffect(() => {
      if (tagsPickData != null){
        var arr = [];
        tagsPickData.tags.forEach(element => {
          arr.push(element);
        });
        setDefaultTags(arr);

        if (editItem != null){ 
          if (selectedTags.length == 0){
            setSelectedTags(editItem.defaultTags);
          }
          
        }
      } 
    },[tagsPickData]);

    useEffect(() => {
      if (searchTagKeyword != null){
        getTags();
      } 
    },[searchTagKeyword]);

    

    //FUNCTIONS
    const clearData = () => {
      setName("");
      setSlug("");
      setDefaultTags([]);
    }

    const onNameChange = (e) => {
      const newNameValue = e.target.value;
      setName(newNameValue);
      const newSlug = slugify(newNameValue,SLUG_OPTIONS)
      setSlug(newSlug);
    }


    const getTags = () => {
      let postData = {hasPaginate: false,searchKeyword: searchTagKeyword};
      dispatch(postTagsAllPick(postData)); 
    }

    const handleTagsChange  = (e,selectedValues) => {
      //console.log(selectedValues);
      setSelectedTags(selectedValues);
    }
  
    const handleTagsInput = (value) => {
      setSerachTagKeyword(value);
    }

  //UI
  return (
    <div>
      <Grid container spacing={5}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="name"
              label={trans.name_field}
              value={name}
              type="text"
              onChange={(e)=> {onNameChange(e)}}
              fullWidth
              variant="standard" />
          </Grid>

          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="slug"
              label={trans.slug_field}
              value={slug}
              type="text"
              onChange={(e)=> {setSlug(e.target.value)}}
              fullWidth
              variant="standard" />
          </Grid>

          <Grid item xs={12} >
            <Autocomplete
                  multiple
                  freeSolo
                  fullWidth
                  id="checkboxes-tags-category"
                  options={defaultTags}
                  disableCloseOnSelect
                  value={selectedTags}
                  onChange={(e,value) => handleTagsChange(e,value)}
                  getOptionLabel={(option) => option.name}
                  onInputChange={(event, newInputValue) => {
                    handleTagsInput(newInputValue);
                  }}
                  // filterOptions={(options, params) => {
                  //   const filtered = filter(options, params);
            
                  //   if (params.inputValue !== "") {
                  //     filtered.push({
                  //       freeSolo: true,
                  //       inputValue: params.inputValue,
                  //       id: `new_${params.inputValue}`,
                  //       name: `Add "${params.inputValue}"`
                  //     });
                  //   }
            
                  //   return filtered;
                  // }}
                  isOptionEqualToValue={(option, value) => option.id == value.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  
                  renderInput={(params) => (
                    <TextField {...params} label={trans.tags_label} placeholder={trans.add_post_tag} />
                  )}
                />
            </Grid>
         
      </Grid>
    </div>
  );
}

export default AddTag;
